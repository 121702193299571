import {useMemo} from 'react'
import PropTypes from 'prop-types'

const FeatureText1 = ({className = '', lightbulb02, hypothesizeYourBusinessMo, consciouslyRealizingOnWhi, second, play, propBackgroundColor, imageClassName, playButtonClass, descriptionClassName, onPlayClick}) => {
	const featureTextStyle = useMemo(() => {
		return {
			backgroundColor: propBackgroundColor
		}
	}, [propBackgroundColor])

	return (
		<div
			className={`self-stretch rounded-2xl bg-primary flex flex-col items-start justify-start py-[54px] px-[55px] gap-[24px] text-left  text-base-white font-plus-jakarta-sans border-[1px] border-solid border-whitesmoke-200 ${className}`}
			style={featureTextStyle}>
			<div className={`w-[72px] h-[72px] rounded-81xl bg-base-white flex flex-col items-start justify-start p-5 box-border ${imageClassName}`}>
				<img className="w-8 h-8 relative overflow-hidden shrink-0" loading="lazy" alt="" src={lightbulb02} />
			</div>
			<div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
				<h3 className="m-0 self-stretch relative text-inherit text-[24px] leading-[34px] font-[600] font-inherit mq450:text-lgi mq450:leading-[27px]">{hypothesizeYourBusinessMo}</h3>
				<div className={`self-stretch relative text-base text-[1rem]  font-[500] leading-[26px] font-medium text-gray1-600 ${descriptionClassName}`}>{consciouslyRealizingOnWhi}</div>
			</div>
			<button className={`cursor-pointer [border:none] py-2.5 px-5 bg-gray1-700 rounded-980xl  flex flex-row items-center justify-center gap-[8px] whitespace-nowrap hover:bg-gainsboro-300 ${playButtonClass}`} onClick={() => onPlayClick()}>
				<div className="relative text-sm leading-[20px] font-semibold font-plus-jakarta-sans text-base-white text-center inline-block min-w-[73px]">{second}</div>
				<img className="h-5 w-5 relative overflow-hidden shrink-0 min-h-[20px]" alt="" src={play} />
			</button>
		</div>
	)
}

FeatureText1.propTypes = {
	className: PropTypes.string,
	lightbulb02: PropTypes.string,
	hypothesizeYourBusinessMo: PropTypes.string,
	consciouslyRealizingOnWhi: PropTypes.string,
	second: PropTypes.string,
	play: PropTypes.string,
	onPlayClick: PropTypes.func,
	/** Style props */
	propBackgroundColor: PropTypes.any
}

export default FeatureText1

/***
*
*   PRICE PLANS
*   Pricing table for your landing/pricing page
*   Update the features array in /config file with your own
*
**********/

import {Card, CheckList, Button, useNavigate, usePlans, Icon} from 'components/lib';
import Style from './pricing.tailwind.js';
import CountDown from "count-down-react";
import React from "react";
import ReactGA from "react-ga4";
import {tagManager} from "../../app/googleTagManager";

function ValueAdded() {
  return (        <div className="grid grid-cols-4 gap-2 mt-12 mb-12">
        {[
          {image: 'trending-up', text: 'Manage ideas like a venture capitalist.'},
          {image: 'dollar-sign', text: 'Invincible: Unlock unlimited resources.'},
          {image: 'briefcase', text: 'Spark creativity & boost your career.'},
          {image: 'mail', text: 'One new lecture every Wednesday.'},
          {image: 'film', text: 'Thoughtful minimal 2D animations.'},
          {image: 'heart', text: 'Mental models by neuroscience.'}
        ].map((item, index) => (
            <div
                key={index}
                className="h-full p-2 text-black shadow-lg"
            >
              <Icon image={item.image} color="derisky-rose" size="30" />
              <h1 className="my-4 text-brand-400 text-sm">{item.text}</h1>
            </div>
        ))}

      </div>
  );
}

export function PricePlans(props){
  const today = new Date();
  const lastDayOfMonth = today.getDay() < 15 ?
      new Date(today.getFullYear(), today.getMonth(), 15):
      new Date(today.getFullYear(), today.getMonth()+1, 1);

  function addZeroWhenOneDigit (number) {
    let formattedNumber = (number < 10 ? '0' : '') + number;
    return formattedNumber;
  }
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return "test";
    } else {
      // Render a Countdown

      return (<div className="flex-1 ">
            <div className="flex justify-end">
              <div className='text-md text-brand-400'>Sale until {lastDayOfMonth.toLocaleDateString("us-en")} </div>
            </div>
            <ul className="flex justify-end">

              <li className="shadow-xl  mr-1 p-2 text-sm text-center">
                <div className="text-3xl align-middle font-bold" id="days">{addZeroWhenOneDigit(days)}</div>days
              </li>
              <li className="shadow-xl  mr-1 p-2 text-sm text-center">
                <div className="text-3xl font-bold" id="hours">{addZeroWhenOneDigit(hours)}</div>hours
              </li>
              <li className="shadow-xl  mr-1 p-2 text-sm text-center">
                <div className="text-3xl font-bold" id="minutes">{addZeroWhenOneDigit(minutes)}</div>mins
              </li>
              <li className="shadow-xl  mr-1 p-2 text-sm text-center">
                <div className="text-3xl font-bold" id="seconds">{addZeroWhenOneDigit(seconds)}</div>secs
              </li>
            </ul>
          </div>

      );
    }
  };




  const navigate = useNavigate();
  const plans = usePlans();

  if (!plans.data)
    return false;

  return(
      <div>

        <div className='grid grid-cols-5 gap-16'>
          <div className='col-span-4 align-middle text-lg'>
            Explore our straightforward pricing plans tailored to meet your derisking journey needs. We believe in providing value that aligns with your
            goals. Choose the plan that suits you and your goals best.


          </div>
          <CountDown className='col-span-1' date={lastDayOfMonth} renderer={renderer} />
        </div>
    <section cols={plans?.data?.raw?.plans.length} className={ Style.plans }>
      { plans?.data?.raw?.plans.map((plan, index) => {

        return (
          <Card shadow rounded key={ plan.id } className={ Style.plan  }>

              <div className={ Style.name }>
                { plan.name }
              </div>

              <div className={ Style.price }>
                { plan?.currency?.symbol }{ plan.price }
                <small>{ plan.interval && '/' }{ plan.interval }</small>
              </div>

            <div className="mt-3 mb-3 line-through">
              { plan?.price && plan?.currency?.symbol }{ plan.price*2 }
              { plan.interval && '/' }{ plan.interval }
            </div>

              <CheckList items={ plan.features } className={ Style.features }/>
              <Button text='Sign Up' color='rose' fullWidth className='mt-5 bg-derisky-rose uppercase text-brand-400'  action={ e => {
                navigate('/signup');
                ReactGA.event('money', 'pricing click', plan.name);
                tagManager.track('money', {
                  plan: plan.name
                });

              }}/>

          </Card>
        )
      })}
    </section>
        <h1 className='text-brand-400'>What you will get.</h1>
        <ValueAdded />
      </div>
  );
}
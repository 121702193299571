import React, { Fragment, useEffect, useState } from 'react'
import { Accordion, AccordionBody } from '@material-tailwind/react'
import { Table, DropdownMenu } from 'components/lib'
function Icon({ id, open, onClick }) {
	return <img src="/icons/arrow_down.svg" alt="" className={`${id === open ? 'rotate-[360deg]' : 'rotate-[270deg]'}  transition-transform  mr-2`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={onClick} />
}

export function Accordions(props) {
	const [open, setOpen] = useState(0)
	const [openRisk, setOpenRisk] = useState(0)

	const handleOpen = (value) => {
		setOpen(open === value ? 0 : value)
	}
	const [users, setUsers] = useState([
		{ Experiment: 'Landing Page', Cost: ' 3.000€', status: 'FINSIHED', Insight: <img src="/icons/green_icon.svg" className="w-[1.35rem]" alt="" />, Confidence: '80 %', 'Risk Reduction': ' 14%' },
		{ Experiment: 'Customer Interview', Cost: ' 3.000€', status: 'FINSIHED', Insight: <img src="/icons/red_icon.svg" className="w-[1.35rem]" alt="" />, Confidence: '80 %', Riskreduction: ' 14%' }
	])

	// onEdit={() => caseModal(e)} onDelete={() => deleteBusinessCaseModal(e.id)} , , Actions: <DropdownMenu editTitle={'Edit'} deleteTitle={'Delete'} parent={'cases'} />
	useEffect(() => {
		let arr = []
		setOpenRisk(0)
		if (props?.experiments?.length) {
			props.experiments?.map((experiment, index) => {
				arr.push({
					Experiment: `${experiment?.name}`,
					Cost: `${experiment?.cost}€`,
					status: `${experiment?.status}`,
					Insight: experiment?.insight && experiment?.status == 'Finished' ? <img src="/icons/green_icon.svg" className="w-[1.35rem]" alt="" /> : experiment?.insight == false && experiment?.status == 'Finished' ? <div className="w-[1.35rem] h-[1.35rem] rounded-[100%] bg-red-500 rounded" alt="" /> : experiment?.status != 'Finished' && <div className="w-[1.35rem] h-[1.35rem] rounded-[100%] bg-[#EBECF0] rounded" alt="" />,
					Confidence: experiment?.status == 'Finished' ? `${experiment?.confidence ? parseFloat(experiment?.confidence).toFixed(2) : 0} %` : '',
					'Risk Reduction': experiment?.status == 'Finished' ? `${experiment?.risk_reduction} %` : '',
					Actions: (
						<DropdownMenu
							finishTitle={'Finish Experiment'}
							onFinish={() => props?.onFinish(experiment)}
							onEdit={() => props.onEdit(experiment)}
							onDelete={() => props.onDelete(experiment.id)}
							editTitle={'Edit'}
							deleteTitle={'Delete'}
							parent={'cases'}
							last={index == props?.experiments?.length - 1 ? true : false}
						/>
					)
				})
			})

			let sum = props?.experiments.reduce((accumulator, curValue) => {
				if (curValue?.status === 'Finished') {
					return accumulator + curValue.risk_reduction
				}
			}, 0)

			setOpenRisk(parseFloat(sum))
		}
		setUsers(arr)
	}, [props?.experiments])

	return (
		<Fragment>
			<div className="bg-white p-[1.5rem] hover:shadow-[5px_5px_5px_5px_rgba(0,0.3,0,0.3)] hover:ease-in duration-300 rounded-md">
				<Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
					<div className="flex items-center place-content-between justify-between">
						<div className="flex ">
							<Icon id={1} open={open} onClick={() => handleOpen(1)} />
							<h1 className="text-[1rem] w-96 font-normal leading-4">{props?.text}</h1>
						</div>

						{props?.isTeamLead && (
							<div className="flex  ">
								<img src="/icons/pencil.svg" className=" w-[0.875] h-[1.125rem] cursor-pointer mr-2" alt="" onClick={props.edit} />
								<img src="/icons/basket.svg" className=" w-[0.875] h-[1.125rem] cursor-pointer" alt="" onClick={props.delete} />
							</div>
						)}

						<div className="flex items-center ">
							<h1 className=' mr-6'>{props.risk}%</h1>
							<h1 className="text-[1rem]  font-semibold leading-4  mr-2">{!Number.isNaN(openRisk) ? openRisk.toFixed(1) : '0'}%</h1>
						</div>
					</div>

					<AccordionBody>
						<Table
							className="restrict-width"
							data={users}
							loading={users?.loading}
							badge={{
								col: 'status',
								color: 'blue',
								condition: [
									{ value: 'FINSIHED', color: 'green' },
									{ value: 'invited', color: 'blue' }
								]
							}}
						/>

						{props?.isTeamLead && (
							<h1 className="text-[1rem] font-normal leading-4 p-4 cursor-pointer" onClick={props.newExperience}>
								🧪 New Experiment
							</h1>
						)}
					</AccordionBody>
				</Accordion>
			</div>
		</Fragment>
	)
}

/***
 *
 *   FEATURES
 *   Feature list for use on home/landing pages
 *
 **********/

import { Grid, Icon, ClassHelper, TabView, Animate } from 'components/lib'
import Style from './challenges.tailwind.js'
import { Card } from "reactstrap";
import React from "react";
import Spacer from '../../../../components/spacer/spacer.js';

export function Challenges(props) {
	return(<Animate>
	</Animate>)
	function retursn () {
		return (<Animate>
			<TabView name='Problems.' labels={['👩🏻‍💻 Product Manager', '👨‍💼 Portfolio Manager', '👑 CEO']}>
				<Card className='digital team'>
					<Spacer height="h-[2rem]" />
					<Challenge icon="cloud" title="RISK IS COUNTER-INTUITIVE." desc="You feel overwhelmed when thinking about risks. It's counterintuitive to focus on risks instead of tasks, milestones." />
					<Challenge icon="clock" title="unappreciated overtime." desc="You work overtime, but are unappreciated and undervalued. The management board doesn't see your impact and the importance of your work." />
					<Challenge icon="tag" title="OVERSELL PRODUCT." desc="You need to oversell your product without appropriate risk management, since you compete with the other projects - the worst enemy of innovation." />
				</Card>
				<Card className='CDO'>
					<Spacer height="h-[2rem]" />
					<Challenge icon="trending-up" title="success." desc="You don’t know how to measure and evaluate the success of innovations in explore phase." />
					<Challenge icon="bar-chart" title="COMPARISON & INVEST." desc="Which innovation initiative should be prioritized? Which product deserves more funding?" />
					<Challenge icon="user-x" title="TEAM PERFORMANCE." desc="How to track, evaluate and incentivize the performance of your employees." />
					<Challenge icon="activity" title="PROJECT PROGRESS." desc="How to track & evaluate the progress of innovations over time." />
				</Card>
				<Card className='MB'>
					<Spacer height="h-[2rem]" />
					<Challenge icon="trending-up" title="OVERSOLD AND EXPENSIVE." desc="feel that the digital team oversell their ideas and are not honest about risks." />
					<Challenge icon="bar-chart" title="LACK OF PROCESS." desc="Cannot track, compare and optimize the performance of the digital team." />
					<Challenge icon="activity" title="MISTRUST." desc="Lack of comparison, measurement and optimization leads to mistrust. Do they even work?" />
				</Card>
			</TabView>
		</Animate>)
	}
}

function Challenge(props) {
	const featureStyle = ClassHelper(Style, {
		feature: true,
		className: props.className
	})

	return (
		<div className={featureStyle}>
			<Icon packs={props.packs} image={props.icon} size={16} className={Style.icon} />

			<h3 className={Style.title}>{props.title}</h3>

			<p className={Style.description}>{props.desc}</p>
		</div>
	)
}

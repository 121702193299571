const Style = {

  plans: 'mt-12 mb-12 block grid grid-cols-3 gap-4',
  plan: 'shadow-lg align-top mb-5 md:inline-block md:mr-4 md:px-8 pr-4 last:mr-0 col-span-1 h-full w-full',
  name: 'absolute font-semibold text-center font-sm text-brand-500 uppercase md:relative md:text-slate-500',
  price: 'absolute text-brand-500 font-bold text-lg right-4 top-5 md:relative md:text-3xl md:top-auto md:right-auto md:mt-4',
  features: 'columns-2 ml-0 mt-12 mb-5 md:columns-auto md:mt-4'
  
}

export default Style;
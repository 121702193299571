import {useNavigate} from 'react-router-dom'
import ButtonPrimary from './ButtonPrimary'
import PropTypes from 'prop-types'

const Decisions = ({className = ''}) => {
	const navigate = useNavigate()
	return (
		<section
			className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[40.7px] lg:pb-[140.7px] box-border max-w-full shrink-0 text-left text-37xl text-gray1-100 font-plus-jakarta-sans mq450:pb-[59px] mq450:box-border mq1150:pb-[91px] mq1150:box-border ${className}`}>
			<div className="w-[1160px] flex flex-col items-start justify-start gap-[56px] max-w-full mq800:gap-[28px]">
				<div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[20px] mq1150:flex-wrap flex-wrap lg:flex-nowrap">
					<h1 className="m-0 w-[710px] text-[2rem] lg:text-[3.5rem] relative text-start text-inherit leading-[38.4px] lg:leading-[67.2px] font-medium font-inherit inline-block shrink-0 max-w-full mq450:text-15xl mq450:leading-[40px] mq800:text-26xl mq800:leading-[54px]">
						Make data-driven portofolio decisions
					</h1>
					<div className="flex flex-col items-start justify-start pt-[42px] lg:pt-[82px] px-0 pb-0">
						<ButtonPrimary className="rounded-3xl  bg-[#6145F7] hover:!bg-[#422db6] text-white" onClick={() => navigate('/signup')} />
					</div>
				</div>
				<div className="flex flex-row justify-center flex-wrap gap-[0.5rem] lg:flex-nowrap w-full">
					<div className="w-[387.3px] bg-base-white box-border flex flex-col  items-start justify-start py-[22px] px-[23px] gap-[32px] shrink-0 max-w-full border-[1px] border-solid border-gray-200 mq450:gap-[16px] mq800:pt-5 mq800:pb-5 mq800:box-border">
						<img className="self-stretch h-[339.3px] relative rounded-2xl max-w-full overflow-hidden shrink-0" loading="lazy" alt="" src="/frame-1171275435.svg" />
						<div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
							<h3 className="m-0 self-stretch relative text-inherit text-[24px] leading-[34px] font-[600] text-[#1D2939] font-inherit mq450:text-lgi mq450:leading-[27px] ">Hypothesize with AI</h3>
							<div className="self-stretch relative text-base text-[16px] leading-[26px] font-medium text-[#475467]">
								Derisky's optimized corrective RAG system extracts text from your product documentation, stores it in our vector databases, and supplies it to our large language models.
							</div>
						</div>
					</div>
					<div className="self-stretch flex flex-row items-start justify-start py-0 border-r-0  box-border max-w-full text-xs text-base-white ">
						<div className="w-[387.3px] flex-1 bg-base-white box-border flex flex-col items-start  justify-start py-[22px] px-[23px] gap-[32px] shrink-0 max-w-full z-[1] border-[1px] border-solid border-gray-200 mq450:gap-[16px] mq800:pt-[619.3px] mq800:pb-5 mq800:box-border">
							<div className="self-stretch h-[339.3px] rounded-2xl  overflow-hidden shrink-0">
								<img src="/Frame 1171275435.svg" />
							</div>

							<div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-5xl text-gray-800">
								<h3 className="m-0 self-stretch relative text-inherit text-[24px] leading-[34px] font-[600] text-[#1D2939] font-inherit mq450:text-lgi mq450:leading-[27px]">Innovation Metrics</h3>
								<div className="self-stretch text-base leading-[26px] text-[16px] leading-[26px] font-medium text-[#475467] inline-block min-h-[130px]">
									<p className="m-0">To improve, you must measure. Boost your innovation performance by continuously tracking and visualizing your derisking activities.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="self-stretch flex flex-row items-star max-w-full text-3xs text-base-black">
						<div className="w-[387.3px] bg-base-white box-border flex flex-col items-start justify-start pt-[22px] px-[23px] pb-[74px] gap-[32px] shrink-0 max-w-full z-[2] border-[1px] border-solid border-gray-200 mq450:gap-[16px] mq800:pt-[619.3px] mq800:pb-12 mq800:box-border">
							<div className="self-stretch rounded-2xl  overflow-hidden flex flex-col items-start justify-start ">
								<img src="/Frame 1171275436.svg" />
							</div>
							<div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-5xl text-gray-800">
								<h3 className="m-0 self-stretch relative text-inherit text-[24px] leading-[34px] font-[600] text-[#1D2939] font-inherit mq450:text-lgi mq450:leading-[27px]">Portfolio Decision</h3>
								<div className="self-stretch relative text-base text-[16px] leading-[26px] font-medium text-[#475467]">
									With the data from each product, you can derive a portfolio view and enables to make data-driven portfolio decisions like a VC.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

Decisions.propTypes = {
	className: PropTypes.string
}

export default Decisions

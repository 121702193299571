import Board from 'components/draganddrop/board/Board'
import { Loader, SubNavbar, useLocation } from 'components/lib'
import { Fragment, useEffect, useState } from 'react'
import { generateQuote, generateQuoteMap } from 'components/draganddrop/mockData'
import { LogoCard } from 'components/card/projectcard'
import Spacer from 'components/spacer/spacer'
import axios from 'axios'
import FocusComponent from './megaWizard'

export function DragAndDrop() {
	const router = useLocation()
	let id = router.pathname.replace('/draganddrop/', '')
	const [product, setProduct] = useState()
	const [loading, setLoading] = useState(false)
	const [productExperiments, setProductExperiments] = useState([])
	const [dragData, setDragData] = useState([])
	const [qoutsData, setQoutsData] = useState([])
	const [onGoing, setOnGoing] = useState([])
	const [scheduled, setScheduled] = useState([])
	const [openWizard, setOpenWizard] = useState(false)

	useEffect(() => {
		getProduct()
		getAllExperiments()
	}, [])

	const getProduct = async () => {
		if (id) {
			const res = await axios({
				method: 'get',
				url: `/api/product/${id}`
			})

			if (res.data) {
				setProduct(res.data.data)
			}
		}
	}

	const getAllExperiments = async () => {
		if (id) {
			setLoading(true)
			await axios({
				method: 'get',
				url: `/api/all_product_experiment/${id}`
			})
				.then((res) => {
					setLoading(false)
					if (res.data) {
						setProductExperiments(res.data?.data)
					}
				})
				.catch(() => {
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		let finished = []
		let onGoing = []
		let scheduled = []
		let quotesData = []
		if (productExperiments?.length) {
			productExperiments?.map((e) => {
				if (e?.status == 'Finished') {
					finished.push({
						id: e?.id,
						name: e?.status,
						title: e?.name,
						content: e?.name,
						experiment_type: e?.experiment_type,
						risk: e?.hypothesis_id?.risk,
						author: {
							name: e?.status,
							...e
						}
					})
				} else if (e?.status == 'Scheduled') {
					scheduled.push({
						id: e?.id,
						name: e?.status,
						title: e?.name,
						content: e?.name,
						experiment_type: e?.experiment_type,
						risk: e?.hypothesis_id?.risk,
						author: {
							...e,
							name: e?.status
						}
					})
				} else if (e?.status == 'Ongoing') {
					onGoing.push({
						id: e?.id,
						name: e?.status,
						title: e?.name,
						content: e?.name,
						experiment_type: e?.experiment_type,
						risk: e?.hypothesis_id?.risk,
						author: {
							...e,
							name: e?.status
						}
					})
				}
			})
		}
		setDragData([...finished])
		setScheduled(scheduled)
		setOnGoing(onGoing)
		setQoutsData(quotesData)
	}, [productExperiments])

	let data = {
		medium: generateQuote(20, [...dragData, { name: 'onGoing' }], qoutsData),
		large: generateQuoteMap(50)
	}

	return (
		<Fragment>
			{loading && <Loader fullScreen />}
			<div className="">{product ? <LogoCard title={product?.name} subtitle={product?.description} logo={product?.logo} wizardClick={() => setOpenWizard(!openWizard)} /> : ''}</div>
			<Spacer height="h-[1.125rem]" />
			<SubNavbar active="experiments" id={id} />
			<Spacer height="h-[1.125rem]" />
			{openWizard ? <FocusComponent /> : <>

				{dragData?.length || scheduled.length || onGoing.length ? (
					<Board
						initial={{ Planned: scheduled, OnGoing: onGoing, Finished: dragData }}
						withScrollableColumns
						callBack={() => {
							setDragData([])
							getAllExperiments()
							setQoutsData([])
						}}
					/>
				) : (
					loading == false && 'No Experiment Found'
				)}
			</>}
		</Fragment>
	)
}

import {Grid, Row} from "../lib";
import Spacer from "../spacer/spacer";
import React from "react";

export const Funding = () => {
    return(<div className='m-8'>
        <Row title='Heartfelt thanks to all supporters.' align='center'>
            <Spacer height="h-[1.125rem]" />

            <Grid cols={2}>
                    <a href='http://fi.co' target='_blank'>
                        <img alt='Founder Institute' src='/Add/fi.webp' className='w-1/2' />
                    </a>
                    <a href='https://aws.amazon.com/de/activate/activate-landing/' target='_blank'>
                        <img alt='Amazon AWS Activate' src='/Add/aws-activate.png' className='w-1/2' />
                    </a>
                </Grid>
            </Row>
        </div>)
}

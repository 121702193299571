import { createRoot } from 'react-dom/client';
import App from './app/app';
import './global.css';
import { BrowserRouter } from 'react-router-dom';
// import App from 'views/home/App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <App />
);

const welcome = () => console.log('Welcome to Gravity 🚀');
welcome('de529c70-eb80-4dfb-9540-5075db7545bf');

import { Home as HERO } from 'views/metrix/home'
import { Home } from '../views/commit/home'

import { AllProjects } from 'views/metrix/allprojects'
import App from 'views/home/App'
import { SingleProject } from 'views/metrix/singleproject'
import { Cases } from 'views/metrix/cases'
import { Hypothesis } from 'views/metrix/hypothesis'
import { UpdateHypothesis } from 'views/metrix/updatehypothesis'
import { UpdateExperiment } from 'views/metrix/updateexperiment'
import { Insight } from 'views/metrix/insight'
import { Documents } from 'views/metrix/documents';
import { DragAndDrop } from 'views/metrix/dragdrop'
import { BusinessModels } from 'views/metrix/businessModels'
import { RAGBoilerplate } from 'views/metrix/RAGBoilerplate'
const user = JSON.parse(localStorage.getItem('user'))
const Routes = [
	{
		path: '/',
		view: user ? HERO : App,
		layout: user ? 'metrix' : 'landing',
		permission: user ? 'user' || 'admin' : undefined,
	},
	{
		path: '/landing',
		view: user ? HERO : App,
		layout: user ? 'metrix' : 'landing',
		permission: user ? 'user' || 'admin' : undefined,
	},

	{
		path: '/rag-boilerplate',
		view: user ? HERO : RAGBoilerplate,
		layout: user ? 'metrix' : 'auth',
		permission: user ? 'user' || 'admin' : undefined,
	},
	{
		path: '/business-model/*',
		view: BusinessModels,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},

	{
		path: '/allprojects/*',
		view: AllProjects,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/singleproject/*',
		view: SingleProject,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/cases/*',
		view: Cases,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/hypothesis/*',
		view: Hypothesis,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/updatehypothesis/*',
		view: UpdateHypothesis,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/experiment/*',
		view: UpdateExperiment,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/insight/*',
		view: Insight,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/documents/*',
		view: Documents,
		layout: 'metrix',
		permission: 'user' || 'admin',
	},
	{
		path: '/draganddrop/*',
		view: DragAndDrop,
		layout: 'metrix',
		permission: 'user' || 'admin',
	}
]

export default Routes

import React, { useEffect } from 'react';
import Axios from 'axios';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';
import { initTwitter } from './twitterTracking';
import initializeTagManager, { logPageView, tagManager} from "./googleTagManager";
// settings
const Settings = require('settings.json');
const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

const routes = [ ...require('routes/account').default, ...require('routes/app').default, ...require('routes/auth').default, ...require('routes/website').default, ...require('routes/metrix').default];

ReactGA.initialize('G-5QQ8M6R29H');
initializeTagManager();
hotjar.initialize({
	//Stands for 'Hotjar ID' - Your site's ID. This is the ID which tells Hotjar which site settings it should load and where it should save the data collected.
	id: 3288016,
	// Stands for 'Hotjar Snippet Version' - The version of the Tracking Code you are using. This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones. Knowing which version your site includes allows hotjar team to contact you and inform you accordingly.
	//sv:,
	//Stands for 'Debug' - This is a boolean value that tells Hotjar whether to enable the debug mode for the Tracking Code. When set to true, the debug mode will send data to Hotjar regardless of any privacy settings. This is useful when you want to test the Tracking Code and see if it's working as expected. Optional.
	//debug:,
	//Stands for 'Nonce' - This is a string value that allows you to control the nonce attribute of the Hotjar script tag. This is useful when you want to implement a Content Security Policy (CSP) on your site. Optional.
	//nonce: ,

});


function TrackingListener() {
	const location = useLocation();

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

		hotjar.stateChange(location.pathname + location.search);
		logPageView(location.pathname + location.search)
	}, [location]);

	return (<>
		<script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/144892153.js"></script>
			</>);
}

export default function App(props) {
	const user = JSON.parse(localStorage.getItem('user'));
	Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;
	initTwitter();


	if (user?.token) {
		// add auth token to api header calls
		Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
	}

	// render the routes
	return (
		<Elements stripe={StripePromise}>
			<AuthProvider>
				<BrowserRouter>
					<TrackingListener />
					<Routes>
						{routes.map((route) => (
							<Route
								key={route.path}
								path={route.path}
								element={
									route.permission ? (
										<PrivateRoute permission={route.permission}>
											<View display={route.view} layout={route.layout} title={route.title} />
										</PrivateRoute>
									) : (
										<View display={route.view} layout={route.layout} title={route.title} />
									)
								}
							/>
						))}
						{/* 404 */}
						<Route path="*" element={<View display={NotFound} layout="home" title="404 Not Found" />} />
					</Routes>
				</BrowserRouter>
			</AuthProvider>
		</Elements>
	);
}

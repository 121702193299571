/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useState } from 'react'

export const DropdownMenu = (props) => {
	const [onClicked, setOnClicked] = useState(false)
	setTimeout(() => setOnClicked(false), 300)
	return (
		<div className="relative inline-block text-left group ">
			<svg className={` ${props?.parent ? '' : 'mt-2'} cursor-pointer w-6 h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
			</svg>

			<div
				className={`${props?.parent ? 'absolute' : 'mt-[-.175em] absolute'}  right-0 z-10  whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 hidden ${onClicked ? '' : ' group-hover:block'} ${props?.className
					}`}
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="menu-button"
				tabindex="-1"
				style={{ top: props?.last && '-4.5rem' }}>
				<div className="py-1" role="none">
					{props?.finishTitle && (
						<div
							className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-[#D3D3D3]"
							role="menuitem"
							tabindex="-1"
							id="menu-item-0"
							onClick={() => {
								props.onFinish()
								setOnClicked(true)
							}}>
							{props?.finishTitle}
						</div>
					)}

					{props?.businessModelTitle && (
						<div
							className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-[#D3D3D3]"
							role="menuitem"
							tabindex="-1"
							id="menu-item-0"
							onClick={() => {
								props.addNewBusinessModel()
								setOnClicked(true)
							}}>
							{props?.businessModelTitle}
						</div>
					)}
					{props?.addNew && (
						<div
							className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-[#D3D3D3]"
							role="menuitem"
							tabindex="-1"
							id="menu-item-0"
							onClick={() => {
								props.addNewEntry()
								setOnClicked(true)
							}}>
							{props?.addNew}
						</div>
					)}
					{props?.editTitle && (
						<div
							className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-[#D3D3D3]"
							role="menuitem"
							tabindex="-1"
							id="menu-item-0"
							onClick={() => {
								props.onEdit()
								setOnClicked(true)
							}}>
							{props?.editTitle}
						</div>
					)}
					{props?.editTitle && (
						<div
							className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-[#D3D3D3]"
							role="menuitem"
							tabindex="-1"
							id="menu-item-1"
							onClick={() => {
								props.onDelete()
								setOnClicked(true)
							}}>
							{props?.deleteTitle}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

import { Badge, Button, useLocation } from 'components/lib'
import TableActions from './actions'
import Style from './table.tailwind.js'

export function Body(props) {
	if (props.data?.length) {
		return (
			<tbody className={Style.body}>
				{props.data.map((row, index) => {
					return <Row {...props} data={row} key={index} index={index} />
				})}
			</tbody>
		)
	}

	return (
		<tbody className={Style.body}>
			<tr>
				<td colSpan="10" className={window.location.pathname.includes('/hypothesis') ? Style.empty + ' flex gap-2 items-center pb-5 py-3 h-24' : Style.empty + ' pb-5'}>
					<span>{window.location.pathname.includes('/hypothesis') ? 'Accelerate your innovation' : 'No results found'}</span>
					{window.location.pathname.includes('/hypothesis') ? <Button
						className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
						medium
						text="Hire an Innovation Expert"
						color="blue"
						rounded
						action={() => window.location.href = 'http://lab.derisky.ai'}
					/> : ''}
				</td>
			</tr>
		</tbody>
	)
}

export function Row(props) {
	let row = { ...props.data }
	row.actions = row.actions || props.actions
	const hasActions = Object.values(row.actions).some((x) => x !== undefined)
	const router = useLocation()
	let pathname = router?.pathname
	return (
		<tr data-id={props.data.id} className={pathname?.includes('business-model') ? `${props?.index % 2 === 0 ? 'bg-gray text-[0.938rem]' : 'bg-white text-[0.938rem]'}` : ""}>
			{Object.keys(row).map((cell, index) => {
				// actions
				if (cell === 'actions') return hasActions ? <TableActions row={row} index={index} key={index} callback={props.callback} /> : false

				// hide
				if (props.hide?.includes(cell)) return false

				// show
				if (props.show && !props.show.includes(cell)) return false

				let value = row[cell]

				// is date/time
				if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)) {
					const date = new Date(value).toISOString().split('T')
					value = `${date[0]} ${date[1].split('.')[0]}`
				}

				// has badge
				if (value !== undefined && props.badge && cell === props.badge.col) {
					// default color
					let color = props.badge.color

					// check each condition
					if (props.badge.condition) {
						props.badge.condition.forEach((cond) => {
							typeof cond.value === 'string' && typeof value === 'string' ? (color = cond.value.toLowerCase() === value.toLowerCase() ? cond.color : color) : (color = cond.value === value ? cond.color : color)
						})
					}

					return (
						<td key={index} className={Style.cell}>
							<Badge text={value === true ? 'Yes' : value === false ? 'No' : value} color={color} className={Style.badge} />
						</td>
					)
				}

				// standard cell
				return (
					<td key={index} className={props?.parent && props?.selectedValues['Experiment Type'] == row['Experiment Type'] ? 'bg-blue-100' : '' + ' ' + Style.cell + `${pathname?.includes('business-model') ? `  !p-1 cursor-pointer` : ''}`} onClick={() => props.onSelect(row)}>
						<div className={`${pathname?.includes('business-model') ? ' w-64' : ''}`}>
							{cell == 'Business Model' && row['Business Model']?.length
								? row['Business Model']?.map((re, index) => {
									return (
										<div
											className={`${re.includes('Desirability') ? 'bg-[#D8BFD8] opacity-50' : re.includes('Viability') ? 'bg-[#F5DEB3] opacity-70' : re.includes('feasibility') ? 'bg-emerald-100' : ''
												} flex gap-2 mb-1 rounded text-center p-1 font-medium  `}>
											{re}
										</div>
									)
								})
								: value === true
									? 'Yes'
									: value === false
										? 'No'
										: value}
						</div>
					</td>
				)
			})}
		</tr>
	)
}

import { useState } from 'react';
import { Label, Error, Icon, ClassHelper, Tooltip } from 'components/lib';
import Style from './input.tailwind.js';

export function FloatNumberInput(props) {
    const [error, setError] = useState(props.errorMessage || 'Please enter a number');

    function validate(e) {
        const min = props.min;
        const max = props.max;
        let value = e ? e.target.value : '';
        let valid = undefined;

        if (props.required && value === '') {
            valid = false;
        } else if (!props.required && value) {
            valid = true;
        } else if (isNaN(value)) {
            valid = false;
            setError('Please enter a valid number');
        } else if (props.type === 'float' && !/^-?\d*\.?\d*$/.test(value)) {
            valid = false;
            setError('Please enter a valid floating-point number');
        } else if (min && max) {
            value = parseFloat(value);
            if (value >= min && value <= max) {
                valid = true;
            } else {
                valid = false;
                setError(`Please enter a number between ${min} and ${max}`);
            }
        } else if (min) {
            value = parseFloat(value);
            if (value >= min) {
                valid = true;
            } else {
                valid = false;
                setError(`Please enter a number equal to or above ${min}`);
            }
        } else if (max) {
            value = parseFloat(value);
            if (value <= max) {
                valid = true;
            } else {
                valid = false;
                setError(`Please enter a number equal to or below ${max}`);
            }
        } else {
            valid = true;
        }

        props.onChange?.(props.name, value, valid);
    }

    const numberStyle = ClassHelper(Style, {
        textbox: true,
        className: props.className,
        error: props.valid === false,
        success: props.valid === true,
        warning: props.warning,
    });

    return (
        <div className={Style.input}>
            {props.label && (
                <Label text={props.label} required={props.required} for={props.name} />
            )}

            {props?.tooltipText ? <Tooltip text={props?.tooltipText}>
                <input
                    type='text'
                    id={props.name}
                    name={props.name}
                    className={numberStyle}
                    value={props.value || ''}
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        const numericValue = e.target.value.replace(/[^\d.-]/g, '');

                        // Enforce maximum length of 10 characters
                        const truncatedValue = numericValue.slice(0, 10);

                        // Update the state or call the onChange handler with the sanitized value
                        props.onChange?.(props.name, truncatedValue, undefined);
                    }}
                    onBlur={(e) => validate(e)}
                /></Tooltip> : <input
                type='text'
                id={props.name}
                name={props.name}
                className={numberStyle}
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^\d.-]/g, '');

                    // Enforce maximum length of 10 characters
                    const truncatedValue = numericValue.slice(0, 10);

                    // Update the state or call the onChange handler with the sanitized value
                    props.onChange?.(props.name, truncatedValue, undefined);
                }}
                onBlur={(e) => validate(e)}
            />}

            {props.valid === false && <Error message={error} />}

            {props.valid === true && (
                <Icon image='check' color='#8CC57D' className={Style.successIcon} size={16} />
            )}
        </div>
    );
}

import React from 'react'
import { CSSTransition } from 'react-transition-group'

const BusinessDetailsModel = ({ onClose, businessDetails }) => {
	return (
		<div>
			<CSSTransition in appear timeout={0} classNames="modal p-8">
				<div className="modal p-8" onClick={(e) => e.target === e.currentTarget && onClose()}>

					<div className="modal-content-business-model overflow-auto !open">

						<div className="flex justify-between">
							<div className="flex justify-end font-bold text-2xl">Business Model Details</div>
							<div className="font-bold text-center text-[1.2rem] flex justify-end mb-2 cursor-pointer" onClick={onClose}>
								x
							</div>
						</div>
						{/* Render business details */}
						<div className="mt-4">
							{Object.entries(businessDetails).map(([key, value]) => (
								(key != 'product_id' && key != 'case_id') && <div key={key} className="mb-4">
									<div className="font-bold">{key}</div>
									<div>{value}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</CSSTransition>
		</div>
	);
};

export default BusinessDetailsModel;

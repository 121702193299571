import React, { useContext, useEffect, useState } from 'react'
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib'
import { sendTwitterConversion } from 'app/twitterTracking'
export function Signup(props) {
	const context = useContext(AuthContext)
	const [step, setStep] = useState(1)

	useEffect(() => {
		localStorage.clear()
	}, [])

	const handleSignupSuccess = (response) => {
		// Handle the response from the signup API if needed
		console.log('Signup successful:', response)

		// Proceed to the next step (select plan and make payment)
		setStep(2)

		// Call the signin function from the context
		context.signin(response)
		sendTwitterConversion(response.email);
	}

	return (
		<Animate type="pop">
			{step === 1 && (
				<Row title="Sign up to Derisk" padding='py-0'>
					<Card loading={false} restrictWidth center>
						<SocialSignin network={['linkedin']} showOr signup />

						<Form
							inputs={{
								name: {
									label: 'First Name',
									type: 'text',
									required: true,
									errorMessage: 'Please enter your first name'
								},
								company: {
									label: 'Company Name',
									type: 'text',
									required: true,
									errorMessage: 'Please enter the name of your company.'
								},
								job: {
									label: 'Position',
									type: 'text',
									required: true,
									errorMessage: 'Please enter your Position.'
								},
								email: {
									label: 'Email',
									type: 'email',
									required: true
								},
								password: {
									label: 'Password',
									type: 'password',
									required: true,
									complexPassword: true
								},
								confirm_password: {
									type: 'hidden',
									value: null
								}
							}}
							url="/api/account"
							method="POST"
							buttonText="Create Account"
							callback={handleSignupSuccess}
						/>

						<div className="mt-4">
							Already registered? <Link url="/" text="Sign In" />
						</div>
					</Card>
				</Row>
			)}
			{step === 2 && (
				<Row title="Select Plan and Make Payment" padding='py-0'>
					<Card loading={false} restrictWidth center>
						{/* Payment and plan selection form */}
						<div>Plan selection and payment form goes here.</div>
					</Card>
				</Row>
			)}
		</Animate>
	)
}

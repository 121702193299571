import { Loader, ClassHelper } from 'components/lib'
import Style from './confidenceslider.tailwind.js'
import { MdOutlineDone } from 'react-icons/md'
import { BsQuestionLg } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'
import { Fragment } from 'react'
import { useState, useEffect } from 'react'

export function ConfidenceSlider(props) {
	const [support, setSupport] = useState(false)
	const [unClear, setUnclear] = useState(false)
	const [refuse, setRefuse] = useState(false)

	const cardStyle = ClassHelper(Style, {
		shadow: props.shadow,
		center: props.center,
		noPadding: props.noPadding,
		loading: props.loading,
		className: props.className,
		last: props.last,
		restrictWidth: props.restrictWidth
	})

	useEffect(() => {
		if (props?.values) {
			setRefuse(props?.values.refute)
			setSupport(props?.values.support)
			setUnclear(props?.values.unclear)
		}
	}, [props?.values])

	// ${props?.value == e ? 'bg-slate-300' : ''}

	return (
		<section className="flex gap-[2rem] bg-white flex-wrap p-3">
			{!props?.insight ? (
				''
			) : (
				<div
					className="flex flex-col items-center gap-[1rem] cursor-pointer"
					onClick={() => {
						setSupport(true)
						setUnclear(false)
						setRefuse(false)
						props.support(true)
						props.refute(false)
						props.unclear(false)
					}}>
					<div className={`rounded-full h-[4rem] w-[4rem]  border-4 border-[#f9c713] flex items-center justify-center p-2 ${support && 'bg-slate-300'}`}>
						<MdOutlineDone className="text-[3rem] text-black" />
					</div>
					<h1>Support</h1>
				</div>
			)}
			{!props?.insight ? (
				<>
					<div
						className="flex flex-col items-center gap-[1rem] cursor-pointer"
						onClick={() => {
							setSupport(false)
							props.support(false)
							props.refute(false)
							props.unclear(true)
							setUnclear(true)
							setRefuse(false)
						}}>
						<div className={`rounded-full h-[4rem] w-[4rem]  border-4 border-[#f9c713] flex items-center justify-center p-2 ${unClear && 'bg-slate-300'}`}>
							<BsQuestionLg className="text-[2rem] text-black" />
						</div>
						<h1>Unclear</h1>
					</div>
					<div
						className="flex flex-col items-center gap-[1rem] cursor-pointer"
						onClick={() => {
							setSupport(false)
							setUnclear(false)
							setRefuse(true)
							props.support(false)
							props.refute(true)
							props.unclear(false)
						}}>
						<div className={`rounded-full h-[4rem] w-[4rem]  border-4 border-[#f9c713] flex items-center justify-center p-2 ${refuse && 'bg-slate-300'}`}>
							<IoMdClose className="text-[3rem] text-black" />
						</div>
						<h1>Refute</h1>
					</div>
				</>
			) : (
				''
			)}
			{props.loading ? <Loader /> : props.children}
		</section>
	)
}
export function ConfidenceSliderList(props) {
	const cardStyle = ClassHelper(Style, {
		shadow: props.shadow,
		center: props.center,
		noPadding: props.noPadding,
		loading: props.loading,
		className: props.className,
		last: props.last,
		restrictWidth: props.restrictWidth
	})

	let values = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]

	return (
		<Fragment>
			<div className="flex bg-white p-3 flex-wrap align-center" style={{ alignItems: 'baseline' }}>
				{props?.title}
				<div className="flex flex-col bg-white p-3">
					<div className="flex gap-[0.8rem] flex-wrap">
						{values?.map((e, i) => {
							return (
								<div id={i} className={`flex flex-col items-center gap-[1rem] cursor-pointer`} onClick={() => props?.onClick(e)}>
									<div className={` ${props?.value == e ? 'bg-slate-300' : ''} rounded-full h-[3rem] w-[3rem]  border-4 border-[#f9c713] flex items-center justify-center p-2`}>
										<h1 className="text-[1.5rem] font-bold text-black">{e}</h1>
									</div>
								</div>
							)
						})}
					</div>
					<div className="flex justify-between p-4 ">
						<h1 className="text-[#c5b0a8]">Not Confident At All</h1>
						<h1 className="text-[#c5b0a8]">Very Confident</h1>
					</div>
				</div>
			</div>
			{props.loading ? <Loader /> : props.children}
		</Fragment>
	)
}

/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment } from 'react';
import {Button, Loader, Row} from 'components/lib';
import Quiz from 'react-quiz-component';
import Spacer from "../../components/spacer/spacer";

export const quiz = {
    "quizTitle": "Innovation and Derisking Quiz",
    "quizSynopsis": "Test your knowledge about derisking, assumptions, process, critical thinking, and test methods in innovation.",
    "nrOfQuestions": "20",
    "questions": [
        {
            "question": "What is the purpose of derisking in innovation?",
            "questionType": "text",
            "answerSelectionType": "single",
            "answers": [
                "To eliminate all risks",
                "To reduce or mitigate risks",
                "To increase risks",
                "To ignore risks"
            ],
            "correctAnswer": "2",
            "messageForCorrectAnswer": "Correct! Derisking aims to reduce or mitigate risks, not eliminate them entirely.",
            "messageForIncorrectAnswer": "Incorrect! Derisking focuses on reducing or mitigating risks to enhance the chances of success.",
            "explanation": "Derisking involves identifying and addressing potential risks associated with an innovation project to increase its likelihood of success.",
            "point": "20"
        },
        {
            "question": "What are the key components of an assumption in innovation?",
            "questionType": "text",
            "answerSelectionType": "single",
            "answers": [
                "Certainty and absolute truth",
                "Beliefs and guesses",
                "Facts and evidence",
                "Opinions and biases"
            ],
            "correctAnswer": "2",
            "messageForCorrectAnswer": "Correct! Assumptions in innovation are based on beliefs and guesses rather than certainty or absolute truth.",
            "messageForIncorrectAnswer": "Incorrect! Assumptions are typically based on beliefs and guesses rather than facts or evidence.",
            "explanation": "Assumptions are hypotheses or beliefs about the unknown aspects of an innovation project that guide decision-making and planning.",
            "point": "20"
        },
        {
            "question": "Which of the following best describes the innovation process?",
            "questionType": "text",
            "answerSelectionType": "single",
            "answers": [
                "A linear and predictable sequence of steps",
                "A chaotic and random series of events",
                "A systematic and iterative approach",
                "A static and unchanging framework"
            ],
            "correctAnswer": "3",
            "messageForCorrectAnswer": "Correct! The innovation process typically follows a systematic and iterative approach.",
            "messageForIncorrectAnswer": "Incorrect! The innovation process is often characterized by a systematic and iterative approach rather than being linear or chaotic.",
            "explanation": "The innovation process involves various stages, including ideation, prototyping, testing, and implementation, which are often iterative and flexible.",
            "point": "20"
        },
        {
            "question": "What role does critical thinking play in the innovation process?",
            "questionType": "text",
            "answerSelectionType": "single",
            "answers": [
                "It stifles creativity and innovation",
                "It encourages conformity and complacency",
                "It promotes questioning and analysis",
                "It discourages collaboration and teamwork"
            ],
            "correctAnswer": "3",
            "messageForCorrectAnswer": "Correct! Critical thinking is essential in the innovation process as it promotes questioning and analysis.",
            "messageForIncorrectAnswer": "Incorrect! Critical thinking is crucial in innovation as it fosters questioning, analysis, and evaluation.",
            "explanation": "Critical thinking involves objectively analyzing information, evaluating evidence, and questioning assumptions to make informed decisions and solve problems effectively.",
            "point": "20"
        },
        {
            "question": "Which test methods are commonly used in innovation to validate assumptions?",
            "questionType": "text",
            "answerSelectionType": "single",
            "answers": [
                "Surveys and interviews",
                "Prototype testing",
                "Market research",
                "All of the above"
            ],
            "correctAnswer": "4",
            "messageForCorrectAnswer": "Correct! Various test methods, including surveys, prototype testing, and market research, are used to validate assumptions in innovation.",
            "messageForIncorrectAnswer": "Incorrect! Multiple test methods, including surveys, prototype testing, and market research, are commonly used to validate assumptions in innovation.",
            "explanation": "Innovation projects often rely on diverse test methods to gather feedback, validate assumptions, and refine solutions before full-scale implementation.",
            "point": "20"
        },
        {
            "question": "Which of the following statements are true about the implications of the described processes in innovation?",
            "questionType": "text",
            "answerSelectionType": "multiple",
            "answers": [
                "Derisking increases the likelihood of success by mitigating risks",
                "Critical thinking promotes conformity and stifles creativity",
                "Test methods help validate assumptions and refine solutions",
                "The innovation process follows a linear and predictable sequence of steps"
            ],
            "correctAnswer": [1, 3],
            "messageForCorrectAnswer": "Correct! Derisking reduces risks, and test methods validate assumptions and refine solutions.",
            "messageForIncorrectAnswer": "Incorrect! Derisking aims to reduce risks, and test methods help validate assumptions and refine solutions.",
            "explanation": "Derisking, critical thinking, and test methods play essential roles in innovation by reducing risks, fostering analysis, and validating assumptions to enhance project outcomes.",
            "point": "30"
        }
    ]
}
;

export function Exam(props) {
    const user = JSON.parse(localStorage.getItem('user'));


    return (
        props.loading ? <Loader /> :
            <>{user ? <Fragment>
                {
                    <Row>
                        {user}
                        <Spacer height="h-[5rem]" />
                        <Quiz
                            quiz={quiz}
                            shuffle={true}
                            showInstantFeedback={true}
                            timer={60*60}
                            allowPauseTimer={true}
                        />
                    </Row>
                }
            </Fragment> : <Fragment><Row>
                <Spacer height="h-[5rem]" />
                <Button url='/signin' text="Login"/>

                Login to perform the exam.
                <Spacer height="h-[5rem]" />
            </Row></Fragment>}</>
    );
}

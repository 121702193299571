import PropTypes from 'prop-types'

const ButtonSecoundary = ({className = '', onClick}) => {
	return (
		<button
			className={`cursor-pointer py-3 px-[23px] bg-base-white w-[91px] rounded-980xl box-border flex flex-row items-center justify-center border-[1px] border-solid border-gray-800 hover:bg-gainsboro-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-dimgray ${className}`}
			onClick={onClick}>
			<a className="[text-decoration:none] relative text-base leading-[24px] font-semibold font-plus-jakarta-sans text-center inline-block min-w-[43px]">Login</a>
		</button>
	)
}

ButtonSecoundary.propTypes = {
	className: PropTypes.string
}

export default ButtonSecoundary

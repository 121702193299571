/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ProjectCard, Button, Grid, ViewContext, Loader, usePermissions, Event, Icon, useNavigate } from 'components/lib'
import Spacer from 'components/spacer/spacer'
import axios from 'axios'
import { DropdownMenu } from 'components/lib'

export function Home(props) {
	const [teams, setTeams] = useState([])
	const [users, setUsers] = useState([])
	const [dropDownOptions, setDropDownOptions] = useState([])
	const [multiSelect, setMultiSelect] = useState([])
	let user = JSON.parse(localStorage.getItem('user'))
	const [loading, setLoading] = useState(false)
	const [isTeamLead, setIsTeamLead] = useState(false)
	const permissions = usePermissions()
	const navigate = useNavigate()

	const elementRef = React.useRef(null);

	useEffect(() => {
		getTeams()
	}, [])

	useEffect(() => {
		let options = []
		let checkBox = []

		if (users.length) {
			console.log('users', users?.length)
			users.map((res) => {
				options.push({ label: res.name, value: res.id })
				checkBox.push({
					label: (
						<div className="grid">
							<div className="flex items-center">
								<img className="h-6 w-6 rounded-md" src={res?.photo ? process.env.REACT_APP_IMAGE_URL + res?.photo : '/dance.gif'} alt="" /> <span>{res.name}</span>{' '}
							</div>
							<span className="text-[0.875rem] text-slate-500">{res.email}</span>{' '}
						</div>
					),
					value: res,
					search: res.name
				})
			})
		}

		setMultiSelect(checkBox)
		setDropDownOptions(checkBox)
	}, [users])

	const getTeams = async () => {
		setLoading(true)
		await axios({
			method: 'get',
			url: '/api/team'
		})
			.then((res) => {
				setLoading(false)
				setTeams(res.data.data.team)
				res.data.data.team?.map((e) => {
					if (e?.isTeamLead) {
						setIsTeamLead(e?.isTeamLead)
					}
				})
				setUsers(res.data.data.allUsers)
			})
			.catch(() => {
				setLoading(false)
			})
	}

	const deleteTeam = async (id) => {
		try {
			setLoading(true)
			const res = await axios({
				method: 'Delete',
				url: `/api/delete-team/${id}`
			})
			setLoading(false)
			if (res.data) {
				if (res.data.message) context.notification.show(res.data.message, 'success', true)
				context.modal.hide(true)
				getTeams()
			}
		} catch (error) {
			setLoading(false)
			context.handleError(error)
		}
	}

	const deleteProduct = async (id) => {
		try {
			setLoading(true)
			const res = await axios({
				method: 'delete',
				url: `/api/delete-product/${id}`
			})
			setLoading(false)
			if (res.data) {
				if (res.data.message) context.notification.show(res.data.message, 'success', true)
				context.modal.hide(true)
				getTeams()
			}
		} catch (error) {
			setLoading(false)
			context.handleError(error)
		}
	}

	const context = useContext(ViewContext)
	function TeamModal(id, value) {
		context.modal.show(
			{
				title: value?.id ? 'Edit Tribe' : 'Build tribe',
				form: {
					name: {
						label: 'Name',
						type: 'text',
						max: 256,
						value: value && value?.name,
						required: true
					},
					description: {
						label: 'Description',
						type: 'text',
						value: value && value?.description,
						required: true
					},

					members: {
						// required: value ? false : true,
						label: 'Add Members',
						options: multiSelect,
						type: 'multiSelect',
						value: value && value?.teamMembers,
						isMulti: true,
						isSearchable: true
					},
					user_id: {
						label: 'Team Lead',
						options: dropDownOptions,
						type: 'multiSelect',
						value: value && value?.teamLead?.id,
						isSearchable: true,
						isMulti: false,
						readonly: false
						// value: value?.user_id?.id
					},
					// user_id: {
					// 	// required: true,
					// 	label: 'Team Lead',
					// 	type: 'select',
					// 	options: dropDownOptions,
					// 	default: value?.user_id?.id
					// },
					logo: {
						required: value?.image ? false : true,
						label: 'Image',
						type: 'file',
						max: 1,
						images: value && value?.image,
					}
				},

				buttonText: 'Save',
				color: 'white',
				url: value ? `/api/team/${id}` : '/api/team',
				method: value ? 'PUT' : 'POST',
				parent: true
			},
			(form, res) => {
				getTeams()
			}
		)
	}

	function projectModal(teamId, value) {
		console.log('value', value)
		context.modal.show(
			{
				title: value ? 'Update Product' : 'Add Product',
				form: {
					name: {
						label: 'Name',
						type: 'text',
						required: true,
						max: 256,
						value: value && value?.name
					},
					description: {
						label: 'Description',
						type: 'textarea',
						required: true,
						value: value && value?.description
					},
					logo: {
						required: value?.logo ? false : true,
						label: 'Image',
						type: 'file',
						images: value && value?.logo
					}
				},

				parent: true,
				buttonText: 'Save',
				url: value ? `/api/product/${value?.id}` : '/api/product',
				method: value ? 'PUT' : 'POST',
				team_id: value ? '' : teamId
			},
			(form, res) => {
				getTeams()
			}
		)
	}

	function deleteTeamModal(teamId) {
		context.modal.show({
			title: 'Delete Team?',
			body: 'Are you sure to delete the Team?',
			delete: true,
			buttonText: 'Delete?',
			cancel: 'Cancel',
			submit: () => deleteTeam(teamId),
			loading: loading,
			destructive: true
		})
	}

	function deleteProductModal(id) {
		context.modal.show({
			title: 'Delete Product?',
			body: 'Are you sure to delete the Product?',
			delete: true,
			buttonText: 'Delete?',
			cancel: 'Cancel',
			submit: () => deleteProduct(id),
			loading: loading,
			destructive: true
		})
	}

	const addStart = async (productId) => {
		const res = await axios({
			method: 'post',
			url: '/api/star-product',
			data: { product_id: productId }
		})

		if (res) {
			context.notification.show(res.data.message, 'success', true)
			getTeams()
		}
	}

	const removeStar = async (id) => {
		const res = await axios({
			method: 'delete',
			url: `/api/start-product/${id}`
		})

		if (res) {
			context.notification.show(res.data.message, 'success', true)
			getTeams()
		}
	}

	function invite(teamId) {
		context.modal.show(
			{
				title: 'Add User',
				form: {
					email: {
						label: 'Email',
						type: 'text',
						required: true
					},

				},
				buttonText: 'Send Invite',
				text: 'To invite more than one user, seperate the emails with a comma',
				url: `/api/invite-member/${teamId}`,
				method: 'POST'
			},
			(form, res) => {
				// add the invited user to the
				if (res.length) {
					const state = [...users]
					console.log(state)

					res.forEach((invite) => {
						if (!state.find((x) => x.id === invite.id)) {
							state.push({
								id: invite.id,
								name: '',
								email: invite.email,
								date_created: invite.date_sent,
								permission: invite.permission || 'user',
								status: 'Invited',
								actions: {
									// invite: resendInvite,
									// delete: deleteInvite
								}
							})
						}
					})

					Event('invited_user')
					setUsers(state)
				}
			}
		)
	}

	return (<>

		<Fragment>
			{loading && <Loader fullScreen />}
			{/* Starred Products */}
			{
				!!teams.map(team => team.favourite).flat().length + 1 &&
				<div className='flex gap-2 my-4 self-center items-center '>
					<Icon image='star' size={18} />
					<h2>Favorites</h2>
				</div>
			}
			<Grid cols="3">

				<a href={'https://derisky.ai/mba'} contentRef={elementRef}
					className={'rounded-md '} target='_blank'>
					<img ref={elementRef} className={'mbaImage rounded-md w-full hover:brightness-75 hover:shadow-inner bg-brand-400 rounded-md text-white font- shadow-lg rounded'} src='/Add/popup.png' />
				</a>
				{teams.map((e) => {
					// eslint-disable-next-line no-unused-expressions
					return (
						<div className='h-full'>
							{e?.favourite?.length
								? e?.favourite?.map((fav) => {
									return (
										<ProjectCard
											style={{ height: elementRef.current.height }}
											title={fav?.name}
											image={fav?.logo}
											favourite={fav?.is_stared}
											addStart={(event) => {
												event.stopPropagation();
												addStart(fav.id)
											}}
											removeStar={() => removeStar(fav?.stared_product_id)}
											id={fav?.id}
											onEdit={() => {
												projectModal(fav.id, {
													...fav
												})
											}}
											onDelete={() => deleteProductModal(fav.id)}
										/>
									)
								})
								: ''}
						</div>
					)
				})}

			</Grid>

			<div className="flex items-center justify-between">
				<div className='flex gap-2 my-4 self-center items-center '>
					<Icon image='users' size={18} />
					<h2>My tribes</h2>
				</div>
				<Button
					className="inline-block rounded-sm text-brand-400 bg-derisky-rose bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal  shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] mb-2"
					text="+ &nbsp; Build tribe"
					action={TeamModal}
					rounded
				/>
			</div>

			<Spacer height="h-2" />
			{!teams.length && <div>🗿 Build or invite your tribe!</div>}

			{teams.map((e) => {
				console.log(e);
				return (
					<>
						<div className="flex justify-between gap-[4rem]">
							<div className='flex cursor-pointer' onClick={() => navigate(`/dashboard/${e?.id}`)}>
								<img
									src={process.env.REACT_APP_IMAGE_URL + e.image}
									className='rounded-md h-20 border border-brand-400 shadow-md border-opacity-50'
								/>
								<div className=' h-full ml-4 items-center  w-full'>
									<h2 className="text-[1.125rem]">{e.name}</h2>
									<p className="text-xs self-center hidden xl:block">{e.description}</p>

								</div>

								<div className='md:hidden'
								><DropdownMenu
										onEdit={() =>
											TeamModal(e.id, {
												...e,
												teamMembers: e?.teamMembers?.map((member) => {
													return member?.user
												})
											})
										}
										editTitle={'Edit Team'}
										addNewEntry={() => invite(e.id)}
										addNew='Add User to Team.'
										onDelete={() => deleteTeamModal(e.id)}
										deleteTitle={'Delete Team'}
										onFinish={() => projectModal(e.id)}
									/></div>

							</div>
							<div>
								{(e?.isTeamLead || e?.isAdmin) && (
									<div className="flex gap-[1rem] cursor-pointer">
										<div className="mt-2 border border-brand-400 text-brand-400 rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 text-xs self-center content-center" onClick={() => navigate(`/dashboard/${e?.id}`)}>
											<Icon className="icon mr-2 ml-3 mt-[-.175em] " image={'target'} size={18} color={'brand-400'} />
											Dashboard
										</div>
										<div
											onClick={() =>
												TeamModal(e.id, {
													...e,
													teamMembers: e?.teamMembers?.map((member) => {
														return member?.user
													})
												})
											}
											className="mt-2 hidden md:flex border border-brand-400  text-brand-400 rounded hover:shadow-inner shadow-lg pt-2 p-2 pr-5 text-xs self-center content-center">
											<Icon className="icon mr-2 ml-3 mt-[-.175em] " image={'edit'} size={18} color={'brand-400'} />
											Team
										</div>

										{
											user?.permission === 'admin' && <div className="hidden sm:flex mt-2 border border-brand-400  text-brand-400 rounded hover:shadow-inner shadow-lg pt-2 p-2 pr-5 text-xs self-center content-center" onClick={(e) => invite(e)}>
												<Icon className="icon mr-2 ml-3 mt-[-.175em] " image={'target'} size={18} color={'brand-400'} />
												Add Member
											</div>
										}
										{(e?.isTeamLead || e?.isAdmin) && (<div className="hidden md:flex mt-2 border border-brand-400 border-opacity-30 text-opacity-30 text-brand-400 rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 text-xs self-center content-center"
											onClick={() => deleteTeamModal(e.id)}
										>
											<Icon className="icon mr-2 ml-3 mt-[-.175em] " image={'x'} size={18} color={'brand-400'} />
											Team
										</div>)}

										<div className="mt-2 border border-brand-400 hidden md:flex text-brand-400 rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 text-xs self-center content-center" onClick={() => projectModal(e.id)}>
											<Icon className="icon mr-2 ml-3 mt-[-.175em]" image={'plus-circle'} size={18} color={'brand-400'} />
											Product
										</div>
									</div>
								)}
							</div>
						</div>

						<Spacer height="h-5" />
						<Grid cols="4" className='flex'>
							{e?.products?.length ? (
								e?.products?.map((product) => {
									return (
										<ProjectCard
											title={product?.name}
											classname='flex-1'
											image={product?.logo}
											favourite={product?.is_stared}
											addStart={(event) => {
												event.stopPropagation();
												addStart(product.id);
											}}
											removeStar={() => removeStar(product?.stared_product_id)}
											id={product?.id}
											onEdit={() => {
												projectModal(product.id, {
													...product
												})
											}}
											teamLead={e?.isTeamLead || e?.isAdmin}
											onDelete={(re) => deleteProductModal(re.id)}
										/>
									)
								})
							) : (
								<div className="opacity-80 text-[0.875rem] mt-2">💡 Add your team's first product idea.</div>
							)}
						</Grid>
					</>
				)
			})}
		</Fragment></>
	)
}

import { useState } from 'react'
import { Label, ClassHelper } from 'components/lib'
import Style from './switch.tailwind.js'
import { useEffect } from 'react'

export function Switch(props) {
	// state
	const [on, setOn] = useState(props.default)

	function toggle() {
		setOn(!on)
		props.onChange(props.name, !on, true)
	}

	useEffect(() => {
		if (props.default) {
			setOn(props.default)
		}
	}, [props.default])

	const trackStyle = ClassHelper(Style, {
		track: true,
		trackOn: on,
		trackOff: !on
	})

	const handleStyle = ClassHelper(Style, {
		handle: true,
		handleOn: on,
		handleOff: !on
	})

	return (
		<div className={props?.hypothesis ? Style.switch.replace('w-full mb-6', '') + ' mb-0 w-auto' : Style.switch}>
			<Label text={props.label} required={props.required} className={props?.hypothesis ? Style.label + ' w-64' : Style.label} />

			<div className={trackStyle} onClick={toggle}>
				<div className={handleStyle}></div>
			</div>
		</div>
	)
}

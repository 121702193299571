export const features = [{
    title: "Hypothesize your business models.",
    description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
    video: "video/Hypothesize your business model.mp4",
    badge: "🧠 AI Suggestions",
    type: "video",
    icon: '/lightbulb02.svg'
}, {
    title: "Assess risks of your assumptions.",
    description: "Based on the importance for the venture and the vagueness the risk is calculated. ",
    video: "video/Assess Risks.mp4",
    badge: "🧠 AI recommendation",
    type: "video",
    icon: "/scales01.svg"
}, {
    title: "Derisk your innovation & Save Millions.",
    description: "Run smart experiments to test your hypotheses. Innovation is risky. Our tool shows the progress and the risks you attacked.",
    video: "video/experiments.mp4",
    badge: "🧠 AI Assistance",
    type: "video",
    icon: "/target-04.svg"
}, {
    title: "Build data-driven arguments for investments.",
    description: "No more discussions: Your product deserves another cash infusion and you can proof it with data.",
    video: "video/Assess Risks.mp4",
    type: "video",
    icon: "/currencydollar.svg"
}, {
    title: "Make data-driven portfolio decision.",
    description: "Based on the innovation risk, risk reduction and the importance for the enterprise, the portfolio can be rebalanced.",
    video: "video/Make data driven portfolio.mp4",
    badge: "🧠 Machine learning forecast",
    type: "video",
    icon: "/barchart10.svg"
}
];

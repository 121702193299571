const Style = {

  footer: 'bg-white border-t border-solid border-slate-100',
  nav: 'mb-5 mt-5 ',
  link: 'text-sm mr-5 no-underline hover:underline hover:text-blue-500 float',
  textline: '',
  copyright: 'opacity-70 text-sm mb-3',
  address: 'opacity-70 text-sm not-italic flex gap-2',

}

export default Style;
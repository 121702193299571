import axios from 'axios'
import RangeSlider, { HypothesisCard } from 'components/card/projectcard'
import { Button, Card, DateInput, Grid, Icon, Select, Switch, TextInput, useLocation, ViewContext } from 'components/lib'
import Spacer from 'components/spacer/spacer'
import { useContext, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import '../../components/modal/modal.scss'
import moment from 'moment'
import AssumptionMapping from "../../components/AssumptionMapping";

export const UpdateHypothesis = (props) => {
	console.log('====props', props)
	const context = useContext(ViewContext)
	const router = useLocation()
	const [statement, setStatement] = useState('')
	const [description, setDescription] = useState('')
	const [phase, setPhase] = useState('')
	const [caseId, setCaseId] = useState('')
	const [businessCase, setBusinessCase] = useState([])
	const [importance, setImportance] = useState(50)
	const [unknown, setUnknown] = useState(50)
	const [risk, setRisk] = useState(15)
	const [validStatementField, setValidStatementField] = useState(true)
	const [validatePhase, setValidatePhase] = useState(true)
	const [validateDescription, setValidateDescription] = useState(true)
	const [isToggle, setIsToggle] = useState(false)
	const [start_date, setStart_date] = useState('')

	const [loading, setLoading] = useState(false)

	let id = router.pathname.replace('/hypothesis/', '')

	const divRef = useRef(null);
	const [divHeight, setDivHeight] = useState(0);

	useEffect(() => {
		if (divRef.current) {
			setDivHeight(divRef.current.getBoundingClientRect().height);
		}
	}, []);


	useEffect(() => {
		if (props?.isUpdate) {
			setIsToggle(props?.isUpdate?.case_id ? true : false)
			setStatement(props?.isUpdate?.statement)
			setDescription(props?.isUpdate?.description)
			setUnknown(props?.isUpdate?.unknown)
			setImportance(props?.isUpdate?.importance)
			setRisk(props?.isUpdate?.risk)
			setPhase(props?.isUpdate?.phase)
			setCaseId(props?.isUpdate?.case_id)
			setStart_date(props?.isUpdate?.date_created)


		}
	}, [])

	useEffect(() => {
		if (isToggle == false && !props?.isUpdate?.case_id) {
			setCaseId('')
		}

	}, [isToggle])

	useEffect(() => {
		getBusinessCase()
	}, [])

	const getBusinessCase = async () => {
		const res = await axios({
			method: 'get',
			url: `/api/all_business_case/${id}`
		})

		if (res.data) {
			let arr = []
			if (res.data.data?.length) {
				res.data.data?.map((e) => {
					arr.push({ label: e?.name, value: e?.id })
				})
			}
			setBusinessCase(arr)
		}
	}

	const addHypothesis = async () => {
		if (handleValidation()) {
			setLoading(true)
			await axios({
				method: props?.isUpdate ? 'put' : 'post',
				url: props?.isUpdate ? `/api/product_hypothesis/${props?.isUpdate?.id}` : `/api/product_hypothesis`,
				data: {
					risk: risk,
					phase: phase,
					id: props?.isUpdate ? undefined : id,
					case_id: caseId ? caseId : undefined,
					statement: statement,
					importance,
					unknown,
					description,
					date_created: moment(start_date).format('YYYY-MM-DD')
				}
			})
				.then((res) => {
					if (res.data) {
						context.notification.show(res.data.message, 'success', true)
						props?.callBack()
						context.modal.hide(true)
						setLoading(false)
					}
				})
				.catch((e) => {
					if (e?.response?.data?.message) {
						// context.modal.hide(true)
						context.notification.show(e?.response?.data?.message, 'error', true)
					}
					setLoading(false)
				})
		}
	}

	const handleValidation = () => {
		let isValid = true
		if (statement?.trim().length === 0) {
			isValid = false
			setValidStatementField(false)
		} else {
			setValidStatementField(true)
		}

		if (description?.trim().length === 0) {
			isValid = false
			setValidateDescription(false)
		} else {
			setValidateDescription(true)
		}

		if (phase?.trim().length === 0) {
			isValid = false
			setValidatePhase(false)
		} else {
			setValidatePhase(true)
		}

		return isValid
	}

	return (
		<CSSTransition in appear timeout={0} classNames="modal">
			<div className="modal" onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>
				<div className="modal-content-hypothesis overflow-auto">
					<h1 className="text-[1.125rem] leading-[1.375rem] flex gap-2">
						<Icon image='alert-circle' />
						Hypothesize
					</h1>
					<Spacer height="h-[1.125rem]" />
					<h1 className="text-[1.125rem] leading-[1.375rem]"> A good hypothesis in business should be:</h1>

					<Spacer height="h-[1.125rem]" />
					<Grid cols="3">

						<HypothesisCard image="/Add/hypo_1.png" title="Testable" subtitle="Can be proven with evidence as true (validated) or false (invalidated)." />
						<HypothesisCard image="/Add/hypo_2.png" title="Precise" subtitle="You know exactly what success looks like for this experiment." />
						<HypothesisCard image="/Add/hypo_3.png" title="Discrete" subtitle="Describes only one testable and precise thing you want to investigate." />
					</Grid>

					<div className="h-[0.125rem]"></div>

					<Card>
						<Grid cols="1">
							<DateInput
								label="Start date"
								type="date"
								value={start_date != 'Invalid date' ? start_date : ''}
								min={new Date()}
								// required="true"
								// valid={start_date}
								onChange={(name, value, valid) => {
									setStart_date(value)
								}}
							/>

							<TextInput
								label=" Statement"
								type="text"
								placeholder="We blieve, that"
								name={'statement'}
								value={statement}
								max={256}
								required="true"
								valid={statement ? true : validStatementField}
								onChange={(name, value, valid) => {
									setStatement(value)
								}}
							/>

							<TextInput
								label=" Description"
								type="textarea"
								value={description}
								name={'description'}
								required="true"
								valid={description ? true : validateDescription}
								onChange={(name, value, valid) => {
									setDescription(value)
								}}
							/>
							<h1 className="text-[1.125rem] leading-[1.375rem]">Classify</h1>
							<div className="flex justify-end ">
								<Switch
									label="Add hypothesis for case"
									hypothesis={true}
									default={isToggle}
									onChange={(name, value, valid) => {
										if (value == false) {
											setCaseId('')
										}
										setIsToggle(value)
									}}
								/>
							</div>

							<Select
								label="CASE"
								options={businessCase}
								default={caseId}
								disabled={isToggle ? false : true}
								name={'case'}
								// valid={caseId !== '' && isToggle ? true : isToggle == false ? true : false}
								onChange={(name, value, valid) => {
									setCaseId(value)
								}}
							/>

							<Select
								label="Business model"
								required
								default={phase}
								name={'model'}
								valid={phase ? true : validatePhase}
								options={[
									{ label: 'Feasibility', value: 'Feasibility' },
									{ label: 'Viability', value: 'Viability' },
									{ label: 'Desirability', value: 'Desirability' },
									{ label: 'Adoptability', value: 'Adoptability' },
									{ label: 'Compliance & Regulatory', value: 'Compliance & Regulatory' }
								]}
								onChange={(name, value, valid) => {
									setPhase(value)
									console.log('=======', valid)
								}}
							/>
						</Grid>
						<div className="h-[2rem]"></div>
						<h1 className="text-[1.125rem] leading-[1.375rem]">Quantify</h1>
						<Grid cols={'2'} className=''>
							<AssumptionMapping
								setImportance={setImportance}
								setUnknown={setUnknown}
								ref={divRef} unknown={unknown} importance={importance} />

							<div className='flex flex-col gap-6 items-center justify-center' style={{ minHeight: `500px` }}>
								<p className='text-sm'>
									<h3>X-Axis: Evidence: "Have Evidence" and "No Evidence"</h3><br />
									Key Point: Through working with numerous teams, we've learned that success depends on evidence, not on loudest voices or highest salaries. Observable evidence, whether qualitative or quantitative, is crucial for supporting your hypothesis.
								</p>

								<RangeSlider min={1} max={100} className='w-full' values={[importance ? parseFloat(importance) : 0]} onChange={(e) => setImportance(e[0])} label={'IMPORTANCE'} />
								<p className='text-sm'>
									<h3>Y-Axis: Importance: "Important" and "Unimportant"</h3><br />
									Key Point: While you might think all your hypotheses are important, experience shows not everything is critical. Determine importance by asking, "Which hypothesis, if proven wrong, will cause our business idea to fail?" This helps identify the most crucial hypotheses.
								</p>

								<RangeSlider min={1} max={100} className='w-full' values={[unknown ? parseFloat(unknown) : 0]} onChange={(e) => setUnknown(e[0])} label={'UNKNOWN'} />





							</div>

						</Grid>
						<p className='text-md mt-3'>
							<h1 className='flex align-center '><Icon image='activity' /> Assumption Risk</h1><br />
							Now the most important part. Take the <strong>unknown</strong> and <strong>Importance</strong> into account to evaluate the overall risk for the product.
							Now comes the crucial step: consider both the unknown factors and their importance to assess the overall risk for the product. ⚠️ Keep in mind: The overall risk of all assumptions shouldn't exceed 100%.
						</p>

						<RangeSlider min={1} max={30} className='w-full mt-10' values={[risk ? parseFloat(risk) : 0]} onChange={(e) => setRisk(e[0])} label={'Risk'} />

					</Card>


					<Spacer height="h-[1.125rem]" />
					<div className="flex justify-between">
						<div></div>
						<div className="flex gap-[0.5rem]">
							<Button className="inline-block rounded " text={'Close'} color="red" width="true" action={() => context.modal.hide(true)} outline />
							<Button
								className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-[0.875]"
								text={props?.isUpdate ? 'Update' : 'Save'}
								color="blue"
								width="true"
								action={loading == false && addHypothesis}
								rounded
							/>
						</div>
					</div>
					<Spacer height="h-[1.125rem]" />
				</div>
			</div>
		</CSSTransition>
	)
}

import React from 'react';
import PropTypes from 'prop-types';
import './Tooltip.css'; // Example CSS for Tooltip styles

export const Tooltip = ({ text, children }) => {
    return (
        <div className="tooltip-container w-full">
            {children}
            <span className="tooltip-text">{text}</span>
        </div>
    );
};

Tooltip.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
};


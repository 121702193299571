/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, {useContext, useRef, useState} from 'react';
import {Row, Button, Loader, Grid, Icon, Badge, Card, Animate} from 'components/lib';
import {Hero} from '../commit/components/hero/hero';
import {SocialRow} from "../../components/socialrow/socialrow";
import {Testimonialto} from "../../components/testimonialto/testimonialto";
import Style from "../commit/components/hero/hero.tailwind";
import {RWebShare} from "react-web-share";
import { AuthContext } from '../../app/auth';
import ButtonPrimary from "../home/components/ButtonPrimary";

import {useNavigate} from 'react-router-dom'

export function MBA(props) {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const cardRefs = useRef([]);


    const features = [{
        title: "Hypothesize your business models.",
        description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
        video: "video/Hypothesize your business model.mp4",
        badge: "🧠 AI Suggestions",
        type: "video",
        poster: 'video/course/hypothesize.png'
    }, {
        title: "Assess risks of your assumptions.",
        description: "Based on the importance for the venture and the vagueness the risk is calculated. ",
        video: "video/Assess Risks.mp4",
        badge: "🧠 AI recommendation",
        type: "video",
        poster: 'video/course/actions.png'
    }, {
        title: "Derisk your innovation & Save Millions.",
        description: "Run smart experiments to test your hypotheses. Innovation is risky. Our tool shows the progress and the risks you attacked.",
        video: "video/experiments.mp4",
        badge: "🧠 AI Assistance",
        type: "video",
        poster: 'video/course/risky.png'
    }, {
        title: "Make data-driven portfolio decision.",
        description: "Based on the innovation risk, risk reduction and the importance for the enterprise, the portfolio can be rebalanced.",
        video: "video/Make data driven portfolio.mp4",
        badge: "🧠 Machine learning forecast",
        type: "video",
        poster: 'video/course/portfolio.png'
    }
    ];


    const [videos
        , changeVideos] = useState([
        {
            title: "The Big Picture Of Derisking",
            description: "Derisking refers to the discovery and exploration of new ideas, with a willingness to make mistakes and take risks to reach the goal. \n" +
                "\n" +
                "Conversely, Building focuses on exploiting existing ideas with the aim of maximizing efficiency and minimizing errors.",
            isOpen: true,
            icon: "table",
            videos: [{
                title: "Fearless derisking.",
                description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
                video: "/video/course/deriskingvsbuilding.mp4",
                poster: 'video/course/fearlessderisking.png',
                free: true
            }, {
                title: "Derisking vs. Building",
                description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
                image: "/video/course/deriskingvsbuilding.png",
                free: true
            }, {
                title: 'Collaborate!',
                description: 'MBA students who study in groups have a three times higher successful completion rate than those who do it alone.',
                free: false,
                image: '/video/course/collaborate.png'
            }],
        },
        {
            title: "Hypothesis Development",
            "description": "Developing strong hypotheses for business models and experiments.",
            "isOpen": true,
            "icon": "table",
            "videos": [
                {
                    "title": "Hypothesize!",
                    "description": "Derisky.ai uses Kanban, a lean scheduling system from Japan, to manage experiments. This system uses visual cues to guide production and imposes limits on the number of experiments in any workflow step at a time, known as Work in Progress (WIP) Limits, to ensure a steady flow of work. Kanban aids in managing daily development with minimum overhead and provides project visibility through customizable dashboards.",
                    video: '/video/course/Hypothesis.mp4',
                    free: true,
                    poster: 'video/course/hypothesize.png'
                },
                {
                    "title": "The secrets of a Strong Hypothesis",
                    "description": "A strong hypothesis should be precise, discrete, and verifiable. It should be formulated as specifically as possible and be testable. Multifaceted hypotheses can be broken down into multiple smaller ones. It's important to formulate hypotheses that address all uncertainties across all risk categories.",
                    free: false, video: '',
                    poster: 'video/course/comingsoon.png'
                },
                {
                    "title": "Prioritize Hypotheses",
                    "description": "Determining the order of importance for testing hypotheses.",
                    video: '', free: false,
                    poster: 'video/course/comingsoon.png'
                }
            ]
        },
        {
            title: "Experiments & Testing Business Models",
            description: "asdasd asd asd ",
            isOpen: false,
            icon: "table",
            videos: [{
                title: "Experiments.",
                description: "Derisky advocates for intelligent experiments to challenge risks and untested assumptions. It assists in selecting a meaningful experiment, whether it's building a prototype for technical feasibility or other options. Understanding the experiment's epistemology, identifying the goal, sample size, time required, and success criteria is crucial. Real-world experiments are superior to lab experiments and can unlock unlimited resources.",
                video: '/video/course/intelligentexperience.mp4',
                free: true,
                poster: 'video/course/intelligentexperimence.png'
            }, {
                title: "Performing Experiments.",
                description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }, {
                title: "Tracking & delegate Experiments.",
                description: "Derisky.ai uses Kanban, a lean scheduling system from Japan, to manage experiments. This system uses visual cues to guide production and imposes limits on the number of experiments in any workflow step at a time, known as Work in Progress (WIP) Limits, to ensure a steady flow of work. Kanban aids in managing daily development with minimum overhead and provides project visibility through customizable dashboards.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }]
        }, {
            title: "Project metrics",
            description: "asdasd asd asd ",
            isOpen: true,
            videos: [{
                title: "Innovation metrics.",
                description: "When building innovative projects, it's more valuable to track assumptions and learnings rather than traditional metrics. Best practices include reducing uncertainty before building, opting for cheaper and faster methods in early stages, choosing experiments that provide the most evidence, and conducting multiple experiments for the same hypothesis to increase confidence.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }, {
                title: "Project metrics.",
                description: "Innovation metrics for a project are divided into input metrics (resources invested) and output metrics (impact and value of resources). Derisking innovation involves measuring assumptions, risks, and experiment costs as inputs, and success rate, remaining risk, and reduced risk as outputs. The potential derisked revenue can be calculated by multiplying the impact with the expected revenue. Tracking these metrics over time can help forecast future innovation performance.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }]
        }, {
            title: "Portfolio Management",
            description: " ",
            isOpen: true,
            videos: [{
                title: "Portfolio Metrics.",
                description: "Portfolio metrics categorize products into four types based on their financial potential and evidence of success. Promising Concepts have large financial potential but weak evidence of success, Rising Stars have large financial potential and strong evidence of success, Niche Opportunities have small financial potential and weak evidence of success, and Safe Play options offer small financial potential but strong evidence of success.",
                video: '',
                free: false,
                poster: 'video/course/comingsoon.png'
            }, {
                title: "Portfolio Actions",
                description: "The Explore portfolio's journey involves a process of discovery, validation, reality checks, change of direction, and acceleration. It begins with understanding the customer and their willingness to pay, followed by validation through mock sales. If evidence suggests the idea may not succeed, a re-evaluation of the business model is carried out, possibly leading to a change of direction. The final phase involves creating a working prototype to test the value proposition in a limited market, justifying larger investments for scaling.",
                free: false,
                video: '',
                poster: 'video/course/comingsoon.png'
            }]
        }, {
            title: 'Leveraging AI',
            isOpen: true,
            videos: [{
                title: "Leveraging Generative AI.",
                description: "Theory on its own holds no value. it is only when combined with derisking that its true potential is unlocked.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }]
        },
        {
            title: 'Last chapter',
            isOpen: true,
            videos: [{
                title: "Certify your knowledge.",
                description: 'Certifying your knowledge proves expertise, boosts credibility, and enhances job market competitiveness. It opens doors to better opportunities & salaries, while continuous learning. It’s an investment in your future.',
                image: "/video/course/certify.png",
                free: false
            }, {
                title: "Show the world what you learned.",
                description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
                image: "/video/course/shareit.png",
                free: true,
            }, {
                title: "One week only to bring your idea to the market.",
                description: "Derisky Confront Week: Startup speed, enterprise power.\n" +
                    "Many companies overanalyze and produce lengthy reports without validated info. Derisky Confront Week solves this by quickly developing, testing, and refining tangible solutions. We help you create and launch concepts, prototypes, or products rapidly, ensuring progress and customer focus.",
                video: "/video/course/corporateinnovation.mp4",
                free: true
            }, {
                title: "Something is not clear?.",
                description: "The Derisky MBA is a growing curriculum. If you need further clarification or have any questions, please feel free to contact me. I may also release a video to assist you.\n.",
                image: "/video/course/questions.png",
                free: true
            }]
        }
    ]);

    /*const onSelectItem = function (index) {
        selectItem(features[index]);
    }*/
    function changeOpen(i) {
        console.log(i);
        const newVideos = [
            ...videos,
        ]
        newVideos[i].isOpen = !videos[i].isOpen

        changeVideos(newVideos);
    }

    function closeAllExcept(i) {
        const newVideos = videos.map((vid, number) => {
            return {
                ...vid,
                isOpen: number === i
            };
        })
        changeVideos(newVideos);
    }

    function onClick(chapter, index) {
        const id = chapter.title.toString();
        document.getElementById(id).scrollIntoView({behavior: 'smooth'});
        closeAllExcept(index);
    }

    function renderContent() {
        return (<div>
            {!user && <>
                <section
                    className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-12 box-border max-w-full shrink-0 text-left text-63xl text-gray-600 font-plus-jakarta-sans mq450:pb-5 mq450:box-border mq1150:pb-[31px] mq1150:box-border`}>
                    <div className="h-[78rem] lg:h-[800px] flex-1 [background:linear-gradient(180deg,_#f8f9fe,_#f8f8fb)] overflow-hidden flex flex-col lg:flex-row items-start justify-start py-[94.5px] pr-0 pl-[2rem] lg:pl-[140px] md:pl-[110px] box-border gap-[107px] max-w-full z-[0] mq450:gap-[27px] mq450:pl-5 mq450:box-border mq800:gap-[53px] mq800:pl-[70px] mq800:pt-[26px] mq800:pb-[26px] mq800:box-border mq1150:pt-10 mq1150:pb-10 mq1150:box-border mq1350:pt-[61px] mq1350:pb-[61px] mq1350:box-border">
                        <div className="w-full lg:w-[568px] flex flex-col items-start justify-start gap-[24px]  shrink-0 mq1150:hidden mq1150:max-w-full">
                            <h1 className="m-0 text-[3em] lg:text-[5.12em] self-stretch  relative text-inherit tracking-[-1px] leading-[3.25rem] lg:leading-[5.5rem] capitalize font-medium font-inherit text-base-black mq450:text-6xl mq450:leading-[36px] mq800:text-22xl mq800:leading-[54px]">
                                <span>{`Learn to think like a `}</span>
                                <span className="text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">Venture Capitalist</span>
                                <span>.</span>
                            </h1>
                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-4 box-border max-w-full text-lg">
                                <div className="flex-1 relative leading-[150%] font-medium inline-block max-w-full text-[#475467]">{`Transform your vague business ideas into a portfolio of worth-building & investable products. Learn how to explore business concepts, validate your product, manage risks, and derisk through smart experiments. Build a diverse portfolio of ideas and make data-driven decisions. Save millions and decades by mastering effective derisking strategies.`}</div>
                            </div>
                            <div className="flex flex-row items-start justify-start pt-0 pb-8 pr-5 pl-0">
                                <ButtonPrimary className="text-white bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl" onClick={() => navigate('/signup')} />
                            </div>

                            <div className="relative inline-block">
                                <iframe
                                    src="https://embed-v2.testimonial.to/badge/derisky-ai?starColor=825bf8&fontColor=000000&fontFamily=Plus+Jakarta+Sans&reviewTerm=review&fontSize=16&reviewTermPlural=reviews&alignment=left&customTemplate=%7Baverage%7D+stars+from+8+%7BreviewTerm%7D"
                                    className="border-0 w-full h-auto pointer-events-none"
                                    scrolling="no"
                                ></iframe>
                                <a
                                    href="http://testimonial.to/derisky/all"
                                    target="_blank"
                                    className="absolute top-0 left-0 w-full h-full z-10 block"
                                ></a>
                            </div>








                                </div>
                                {/* <div className="h-auto lg:h-[624px] w-auto lg:w-[624px] relative rounded-[50%] [background:linear-gradient(155.83deg,_rgba(69,_191,_247,_0.1)_17.41%,_rgba(255,_255,_255,_0))] hidden max-w-full" />
                        <div className="h-auto lg:h-[561.6px] w-[561.6px] relative rounded-[50%] [background:linear-gradient(155.83deg,_rgba(69,_158,_247,_0.05)_17.41%,_rgba(255,_255,_255,_0))] hidden max-w-full" /> */}
                        <div className="mt-[2.4px] lg:mt-[-202.4px] h-auto lg:h-[1798.5px] flex-1 relative w-full lg:max-w-[calc(100%_-_675px)] text-21xl text-gray-800 mq1150:hidden mq1150:max-w-full">
                            <img className="relative lg:absolute top-[1rem] h-auto lg:h-[80rem] bottom-[0px] left-[0px] max-h-full w-[964.9px]" alt="" src="/Union3.svg" />
                            <div className="relative lg:absolute top-[-25.9rem] lg:top-[450.9px] left-[30px] lg:left-[90px] w-[311px] flex flex-col items-start justify-start gap-[2rem] z-[1]">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                    <div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">5X</div>
                                    <div className="self-stretch relative text-[1.02rem]  leading-[34px] font-semibold text-gray-600">Faster Time-to-Market</div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                    <div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">15X</div>
                                    <div className="self-stretch relativetext-[1.02rem] leading-[34px] font-semibold text-gray-600">ROI Over 3 Years</div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                    <div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">80%</div>
                                    <div className="self-stretch relativetext-[1.02rem] leading-[34px] font-semibold text-gray-600">Immediate Cost Savings</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Animate>
                    <Testimonialto/>
                </Animate>

                <Row color='white'>

                    <p className='text-lg font-bold'>
                        Unlock the secrets to testing and validating your business ideas with real customers. Learn
                        practical strategies to minimize risks, unify your team's communication, and seamlessly
                        integrate de-risking into your business model. Shift from executing blind plans to
                        systematically identifying and mitigating risks. Start your journey towards successful,
                        risk-free business innovation today!
                    </p>
                </Row>
                <div
                    className="bg-brand-400 font-['myFirstFont'] pt-4 pb-4 text-white shadow-lg mt-10 mb-10">
                    <div className='grid md:grid-cols-6 xs:grid-cols-2 h-full'>
                        <div
                            className='p-5 md:col-span-2 xs:col-span-2  uppercase text-xl flex items-center justify-center h-full'>
                            All videos in the Derisky mba curriculum are thoughtfully conceptualized, designed and
                            animated.
                        </div>
                        {
                            features && features.map((f, index) => {
                                return (
                                    <div className='md:col-span-1 xs:col-span-1 p-5' key={index}>
                                        <video id={f.title} className='rounded-md' src={f.video}
                                               poster={f.poster} noremoteplayback='true'
                                               controlsList="nodownload" playsInline controls/>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <Row>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="text-lg mt-5 md:col-span-2 md:order-1">

                            {
                                renderText()
                            }
                        </div>
                        <div className="order-2">
                            <Animate>
                                <div className="bg-white text-brand-400 p-10 shadow-xl ">
                                    <div className="flex">
                                        <Icon size='24' color='derisky-rose' image='award'/>
                                        <h1 className="text-2xl mb-3 ml-4"> Derisky MBA </h1>
                                    </div>
                                    <dl className="max-w-md text-gray-900  divide-gray-200 dark:text-white dark:divide-gray-700">
                                        <div className="flex-col pb-3">
                                            <dt className="mb-1 text-black md:text-lg dark:text-gray-400 flex items-center">
                                                <Icon image='briefcase' color={"dark"} size="16"/>

                                                <h3 className='ml-2'>Who can benefit</h3>

                                            </dt>
                                            <dd className="text-sm text-gray-600">
                                                Entrepreneurs, Product managers, Program Managers, Portfolio
                                                Managers, Analysts, Innovation Managers, and other specialists
                                                interested in the Innovation and AI
                                                Solopreneurs, Venture Capitalists

                                            </dd>
                                            <br/>
                                            <dt className="mb-1 text-black md:text-lg dark:text-gray-400 flex items-center">
                                                <Icon image='briefcase' color={"dark"} size="16"/>

                                                <h3 className='ml-2'>Content</h3>
                                            </dt>
                                            <dd className="text-sm text-gray-600">Mental models & deep dives on
                                                Innovation, Hypotheses, Experiments, Innovation Metrics, Derisking
                                            </dd>
                                            <br/>

                                        </div>
                                        <div className="flex flex-col py-3">
                                            <dt className="text-black md:text-lg dark:text-gray-400 flex items-center">
                                                <Icon image='dollar-sign' color={"dark"} size="16"/>
                                                <h3 className='ml-2'>Price</h3>
                                            </dt>

                                            <dd className="text-sm text-gray-600">0$ (No credit card required)
                                            </dd>
                                        </div>
                                        <div className="flex flex-col pt-3">
                                            <dt className="mb-1 text-black md:text-lg dark:text-gray-400 flex items-center">
                                                <Icon image='award' color={"dark"} size="16"/>

                                                <h3 className='ml-2'>Degree</h3>
                                            </dt>
                                            <dd className="text-lg text-gray-600 text-sm">Derisky MBA Certificate
                                                for Linkedin.
                                            </dd>
                                        </div>

                                    </dl>
                                    <Button goto='/signup' text='Register to course.' color={"derisky-rose"} big
                                            className={Style.button}/>

                                    <br/><br/>
                                    <Badge color={'green'} text="Innovation"/>
                                    <Badge color='green' text="Product Management"/>
                                    <Badge color='green' text="AI"/>

                                    <Badge color='green' text="Derisking"/>

                                    <Badge color='green' text="Business"/>
                                    <Badge color='green' text="Self actualization"/>
                                    <br/>

                                </div>
                            </Animate>

                        </div>
                    </div>

                </Row>
            </>}
        <div className='bg-brand-400 text-white'>
            {!user && <>

                <Row>
                    <div className="grid md:grid-cols-5 sm:grid-cols-1">
                        <div className="md:col-span-3 sm:col-span-1">
                            <h1 className="text-2xl my-10">MBA Curriculum.</h1>
                            <div>
                                <section>
                                    <strong>The Importance of De-risking.</strong> Avoid failure from unchecked
                                    assumptions by systematically identifying and mitigating risks.
                                </section>

                                <section>
                                    <strong>Understanding De-risking.</strong> Key concepts, steps, and terminology to
                                    get started confidently.
                                </section>

                                <section>
                                    <strong>Identifying and Managing Risks.</strong> Turn uncertainties into testable
                                    hypotheses for successful de-risking.
                                </section>

                                <section>
                                    <strong>Learn from the Experts.</strong> Gain insights and master techniques to
                                    effectively de-risk your business ideas.
                                </section>

                                <section>
                                    <strong>Start your journey towards successful, risk-free business innovation
                                        today!</strong>
                                </section>
                            </div>
                        </div>
                        <div className="md:col-span-2 sm:col-span-1 justify-items-center align-middle p-2">
                            <img src="/Add/mba.png" alt="Derisky MBA"/>
                        </div>
                    </div>
                    <Grid cols={3}>
                        <Card className="p-2 text-black shadow-lg">
                            <Icon image='triangle' color={"derisky-rose"} size="30"/>
                            <h1 className="my-4 text-brand-400 text-lg">1. Business Idea Testing</h1>
                            <p>It's easy to come up with business ideas. The first step is to extract appropriate and
                                testable hypotheses from your business model.</p>
                        </Card>
                        <Card className="p-2 text-black shadow-lg">
                            <Icon image='hexagon' color={"derisky-rose"} size="30"/>
                            <h1 className="my-4 text-brand-400 text-lg">2. Smart Experiments</h1>
                            <p>You conduct smart experiments to challenge assumptions, emphasizing participant
                                selection, appropriate metrics for stronger evidence.
                            </p>
                        </Card>
                        <Card className="p-2 text-black shadow-lg">
                            <Icon image='bar-chart' color={"derisky-rose"} der size="30"/>
                            <h1 className="my-4 text-brand-400 text-lg">3. Portfolio Management</h1>
                            <p>Ideation, Discovery, Validation & Confirmation, involving idea generation, market
                                testing, feasibility confirmation & scalability decision.</p>
                        </Card>
                    </Grid>
                </Row></>
            }
            <Row>
                {!user && <h1 className='my-4'>MBA Curriculum.</h1>}
                <span>
                <span
                    id='navigation'
                    className='float-left m-5 font-bold'
                >
                        Chapters:
                </span>
                {videos.map((chapter, index) => {
                    return (<a
                        id={chapter.title.toString()}
                        key={chapter.title + Math.random()}
                        style={{
                            float: "left",
                            margin: 20
                        }}
                        onClick={() => onClick(chapter, index)}
                    >
                        {index + 1 + ". " + chapter.title.toString()}
                    </a>)
                })}
            </span>
                <div className='mt-4'>
                    {videos.map((chapter, index) => {
                        return (<div id={chapter.title}
                                     style={{
                                         marginTop: 50,
                                         width: "100%"
                                     }}
                                     key={chapter.title + Math.random()}
                        >
                            <div
                                style={{
                                    padding: 20,
                                    width: "100%",
                                }}
                                className="bg-white bg-opacity-50 flex-1 flex flex-wrap justify-between rounded-md"
                                onClick={() => changeOpen(index)}>
                                <h1 style={{fontSize: 20, fontWeight: "bold"}}>
                                    <Icon icon={chapter.icon} size={20}/>
                                    {chapter.title}
                                </h1>

                                <Icon className="color-derisky-lila"
                                      image={!chapter.isOpen ? 'chevron-down' : 'chevron-up'}/>


                            </div>


                            {!chapter.isOpen ? null : <div>
                                <p
                                    className="mt-2 mb-2 text-sm"
                                >{chapter.description}</p>
                                <Grid cols={3}>

                                    {chapter.videos.map(video =>
                                        <div
                                            className=" min-w-[200px] bg-white bg-opacity-20 rounded-md h-full  flex-col min-w-[200px] bg-gray-200 p-3"
                                            id={chapter.title.toString()}
                                            key={chapter.title + " " + Math.random()}
                                        >
                                            <div className="h-full ">
                                                {
                                                    !user ? (
                                                        video.free && video.hasOwnProperty("video") ? (
                                                            <video controls poster={video.poster}
                                                                   className="rounded-md">
                                                                <source src={video.video} type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            <a href='/signup'>
                                                                <img className="rounded-md"
                                                                     src='/video/course/signup.png'/>
                                                            </a>
                                                        )
                                                    ) : (
                                                        video.hasOwnProperty("video") ? (
                                                            <video controls poster={video.poster}
                                                                   className="rounded-md">
                                                                <source src={video.video} type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : video.hasOwnProperty("image") ? (
                                                            <img
                                                                alt="Sample"
                                                                src={video.image}
                                                                className={"rounded-md block block" + !user && "opacity-80"}
                                                                style={{float: "left"}}
                                                            />
                                                        ) : (
                                                            <img src='/video/course/released.png'/>
                                                        )
                                                    )
                                                }

                                                {
                                                    !user && !video.hasOwnProperty("video") && !video.hasOwnProperty("image") && !video.video && !video.image &&
                                                    <img src='/video/course/signup.png'/>
                                                }

                                            </div>
                                            <div
                                                style={{float: "right", padding: 10}}
                                            >
                                                <h3
                                                    className='font-bold text-lg'
                                                >
                                                    {video.title}
                                                </h3>
                                                <p
                                                    className='text-md mb-2'
                                                >
                                                    {video.description}
                                                </p>
                                                <RWebShare
                                                    className='text-right mt-1'
                                                    data={{
                                                        text: `${video.description}`,
                                                        url: "https://derisky.ai/mba",
                                                        title: `${video.title}`
                                                    }}
                                                    onClick={() => console.log("shared successfully!")}
                                                >
                                                    <div className='text-xs bottom inline float-right'>
                                                        <p className='inline color-derisky-rose mr-2'>Share</p>
                                                        <Icon image='share-2' color='derisky-rose'
                                                              className='inline '/>
                                                    </div>
                                                </RWebShare>
                                            </div>
                                        </div>
                                    )}
                                </Grid></div>
                            }

                        </div>);

                    })}
                </div>
            </Row>

            <SocialRow/>


        </div>
        <script async type="text/javascript" src="https://testimonial.to/js/widget-embed.js"></script>
        <div className="testimonial-to-embed"
             data-url="https://embed-v2.testimonial.to/c/derisky-ai?theme=light" data-allow="camera;microphone"
             data-resize="true"></div>

    </div>)
        ;
    }

    function renderText() {
        return (<div className='space-y-4 mr-1 mb-8'>
            <section>
                In a market where 8 of 10 new products fail, <b>Derisking</b> is here to turn that statistic around.
                This hands-on course offers a treasure trove of techniques for swiftly derisking new business ideas.

                "Learn to think like a venture capitalist and master the art of derisking." reveals how systematic
                testing can dramatically reduce risks and boost the chances of success for any new venture.

                Boost the success rate of your ventures while minimizing wasted time, money, and resources on flawed
                ideas.
                Bridge the gap between strategic planning and practical experimentation/validation.
                Identify and test crucial business assumptions using the Business Model Canvas and Value Proposition
                Canvas.
                This definitive field guide to business model testing is packed with practical advice for making
                informed decisions based on solid evidence rather than intuition or guesses. "Testing Business Ideas"
                empowers leaders to cultivate an experimentation mindset within their organizations, making
                experimentation a continuous and repeatable process.
                <h1 className="text-2xl my-10">Derisking: Make better business decisions.</h1>
                <div className="grid md:grid-cols-3 gap-4 grid-cols-2">
                    {[
                        {image: 'trending-up', text: 'Manage ideas like a venture capitalist.'},
                        {image: 'dollar-sign', text: 'Unlock unlimited resources.'},
                        {image: 'briefcase', text: 'Spark creativity & boost your career.'},
                        {image: 'mail', text: 'One new lecture every Wednesday.'},
                        {image: 'film', text: 'Thoughtful minimal 2D animations.'},
                        {image: 'heart', text: 'Mental models by neuroscience.'}
                    ].map((item, index) => (
                        <Card
                            key={index + " " + item.text}
                            className="h-full p-2 text-black shadow-lg"
                        >
                            <Icon image={item.image} color="derisky-rose" size="30" />
                            <h1 className="my-4 text-brand-400 text-lg">{item.text}</h1>
                        </Card>
                    ))}

                </div>


            </section>

        </div>);
    }

    return (
        <>
            {props.loading ? <Loader/> : renderContent()}</>
    );
}

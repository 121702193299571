const Style = {
	newsimage: 'p-8 w-[200px] h-auto',  // Adjusted width for larger news images
	newslink: 'opacity-60 hover:opacity-100 flex items-center justify-center h-full',
	grid: 'grid grid-cols-3 gap-6',
	hero: 'relative text-white mb-10 md:text-left pt-8 md:pt-12 bg-brand-400 pb-2',
	overtitle: 'uppercase bg-derisky-rose my-3 px-2 text-brand-400 shadow-lg',
	blurb: 'mb-10 mx-auto mt-8 ',
	title: 'block text-3xl md:text-3xl font-bold mb-4',
	tagline: 'block text-xl font-light m0 mb-12 mt-10',
	image: '-mb-12 shadow-lg',
	button: 'shadow-2xl uppercase bg-derisky-rose h-16 px-2 text-brand-400 hover:bg-derisky-rosehover',
	containerr: 'text-center mx-auto my-0 p-2',
	countDown2: 'text-sm font-bold',
	globall: 'inline-block box-border m-0 p-0',
	listItemm: 'text-[1.5em] list-none uppercase p-[1em]',
	listItemSpann: 'block text-xl',
	marquee: 'w-full flex justify-center items-center',  // Updated marquee styles
	marqueeContent: 'flex justify-center items-center w-full',  // Updated marqueeContent styles
	marqueeItem: ' w-full',  // Updated marqueeItem styles

};


			export default Style

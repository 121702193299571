/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment } from 'react'
import { Card, AccountNav, Button, Animate, useAPI } from 'components/lib'
import Style from '../../components/form/form.tailwind'
export function LinkSocial(props) {

    // fetch
    const user = useAPI('/api/user')

    return (
        <Fragment>
            <AccountNav />
            <Animate>
                <Card title="Link Social account" loading={user.loading} restrictWidth>
                    Under development. <br/>
                    <div className='flex gap-6'>
                        <Button color={props.destructive ? 'red' : 'green'} text={'Link Instagram'} className={Style.button} fullWidth={!props.cancel} />
                        {/* <Button color={props.destructive ? 'red' : 'green'} text={'Link Twitter'} className={Style.button} fullWidth={!props.cancel} /> */}
                    </div>

                </Card>
            </Animate>
        </Fragment>
    )
}

const Style = {

  hero: `relative text-white md:text-center my-12 pt-8 md:pt-12 bg-gradient-to-b
    bg-brand-400 to-brand-600 py-4`,

  blurb: 'mb-10  mx-auto',
  title: 'block text-3xl md:text-4xl font-bold mb-4',
  tagline: 'block text-lg m0 mb-4',
  image: '-mb-12 shadow-lg',
  button: 'md:mx-auto',

}

export default Style;
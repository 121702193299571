import axios from 'axios'
import { Accordions } from 'components/accordion/accordion'
import { LogoCard } from 'components/card/projectcard'
import {
    Card,
    Icon,
    Loader,
    MultiSelect,
    SubNavbar,
    useLocation,
    ViewContext
} from 'components/lib'
import Spacer from 'components/spacer/spacer'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import ConfidenceListModal from './confidencelistmodal'
import Wrapper from "../../components/MyStepwizard";
import FocusComponent from './megaWizard'

export const Hypothesis = () => {
    const context = useContext(ViewContext)
    const router = useLocation()
    const [product, setProduct] = useState()
    const [hypothesis, setBusinessHypothesis] = useState([])
    const [dropdownValues, setDropdownValues] = useState([])
    let id = router.pathname.replace('/hypothesis/', '')
    const [loading, setLoading] = useState(false)
    const [confidenceModel, setConfidenceModel] = useState(false)
    const [confidenceData, setConfidenceData] = useState('')
    const [hypothesisData, setHypothesisData] = useState('')
    const [finishModelData, setFinishModelData] = useState()
    const user = JSON.parse(localStorage.getItem('user'))
    const [modal, setModal] = useState(false);
    const [openWizard, setOpenWizard] = useState(false)

    useEffect(() => {
        getProductHypothesis()
    }, [])

    const getProductHypothesis = async () => {
        await getProduct()
        await getAllHypothesis()
        await getDropdownHypothesis()
    }
    const getProduct = async () => {
        if (id) {
            setLoading(true)
            await axios({
                method: 'get',
                url: `/api/product/${id}`
            })
                .then((res) => {
                    setLoading(false)
                    if (res.data) {
                        setProduct(res.data.data)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    console.log('err', err)
                })
        }
    }

    // all_filter_hypothesis
    const getAllHypothesis = async () => {
        setLoading(true)
        await axios({
            method: 'get',
            url: `/api/all_product_hypothesis/${id}`
        })
            .then((res) => {
                setLoading(false)
                if (res.data) {
                    setBusinessHypothesis(res.data.data)
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    // all_filter_hypothesis
    const getDropdownHypothesis = async () => {
        setLoading(true)
        await axios({
            method: 'get',
            url: `/api/dropdown_product_hypothesis/${id}`
        })
            .then((res) => {
                setLoading(false)
                if (res.data) {
                    let arr = []

                    console.log('res.data.data===', res.data.data)
                    res.data.data?.map((e) => {
                        arr.push({
                            label: (
                                <div className="grid">
                                    <div
                                        className={`flex items-center ${e?.label?.includes('Most Promising') && 'font-bold'}`}>{e?.label}</div>
                                </div>
                            ),
                            value: e?.value
                        })
                    })
                    setDropdownValues(arr)
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    //get all filter hypothesis
    const getAllFilterHypothesis = async (value) => {
        setLoading(true)
        await axios({
            method: 'post',
            url: `/api/all_filter_hypothesis/${id}`,
            data: { query: value }
        })
            .then((res) => {
                setLoading(false)
                if (res.data) {
                    setBusinessHypothesis(res.data.data)
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    function hypothesisModal(value) {
        context.modal.show(
            {
                isFullPage: true,
                parent: 'hypothesis',
                callBack: () => {
                    getAllHypothesis()
                },
                update: value?.id ? value : undefined
            },
            () => {
                getAllHypothesis()
            }
        )
    }

    function experienceModal(hypothesis, value) {
        context.modal.show(
            {
                isFullPage: true,
                parent: 'experiment',
                hypothesis: hypothesis,
                update: value ? value : undefined,
                callBack: () => getAllHypothesis()
            },
            () => {
            }
        )
    }

    const deleteHypothesis = async (id) => {
        try {
            setLoading(true)
            const res = await axios({
                method: 'Delete',
                url: `/api/product_hypothesis/${id}`
            })
            if (res.data) {
                setLoading(false)
                if (res.data.message) context.notification.show(res.data.message, 'success', true)
                context.modal.hide(true)
                getAllHypothesis()
            }
        } catch (error) {
            setLoading(false)
            context.handleError(error)
        }
    }

    function deleteHypothesisModal(id) {
        context.modal.show({
            title: 'Delete Hypothesis?',
            body: 'Are you sure to delete the hypotheses?',
            delete: true,
            buttonText: 'Delete?',
            cancel: 'Cancel',
            submit: () => deleteHypothesis(id),
            loading: loading,
            destructive: true
        })
    }

    const deleteExperiment = async (id) => {
        try {
            setLoading(true)
            const res = await axios({
                method: 'Delete',
                url: `/api/product_experiment/${id}`
            })
            if (res.data) {
                setLoading(false)
                if (res.data.message) context.notification.show(res.data.message, 'success', true)
                context.modal.hide(true)
                getAllHypothesis()
            }
        } catch (error) {
            setLoading(false)
            context.handleError(error)
        }
    }

    function deleteExperimentModal(id) {
        context.modal.show({
            title: 'Delete Experiment?',
            body: 'Are you sure to delete the Experiment?',
            delete: true,
            buttonText: 'Delete?',
            cancel: 'Cancel',
            submit: () => deleteExperiment(id),
            loading: false,
            destructive: true
        })
    }

    const finishExperiment = async (isFinished, value) => {
        if (hypothesisData && confidenceData) {
            await axios({
                method: 'put',
                url: `/api/product_experiment/${confidenceData?.id}`,
                data: {
                    status: 'Finished',
                    risk_reduction: value ? (parseFloat(hypothesisData) * value?.confidence).toFixed(2) : 0,
                    confidence: value ? value?.confidence : 0,
                    real_end_date: value?.realEndDate ? value?.realEndDate : undefined,
                    insight: value?.insight,
                    support: value.support,
                    refute: value.refuse,
                    unclear: value.unClear
                    // draggedItems: draggedItems
                }
            })
                .then(async (res) => {
                    if (res.data) {
                        if (value?.learning) {
                            await axios({
                                method: 'post',
                                url: `/api/experiment_learning`,
                                data: { product_id: id, experiment_id: confidenceData?.id, text: value?.learning }
                            })
                        }

                        context.notification.show(res.data.message, 'success', true)
                        context.modal.hide(true)
                        getProductHypothesis()
                        setConfidenceModel(false)
                    }
                })
                .catch((e) => {
                    if (e?.response?.data?.message) {
                        setConfidenceModel(false)
                    }
                })
        }
    }


    function BusinessCanvasModel(value) {
        context.modal.show(
            {
                isFullPage: true,
                parent: 'kickStart',
                callBack: () => {
                    context.modal.hide(true)
                    context.notification.show('Hypothesis added successfully', 'success', true)
                    getAllHypothesis()
                },
                update: value ? value : undefined
            },
            () => {
                context.modal.hide(true)
                getAllHypothesis()
            }
        )
    }

    const categories = [
        { label: 'Feasibility', value: 'Feasibility' },
        { label: 'Viability', value: 'Viability' },
        { label: 'Desirability', value: 'Desirability' },
        { label: 'Adoptability', value: 'Adoptability' },
        { label: 'Compliance & Regulatory', value: 'Compliance & Regulatory' }
    ]

    function toggleModal() {
        setModal(!modal);
    }
    function toggleLoading() {
        setLoading(!loading);
    }
    function unLoading() {
        setLoading(false);
    }

    return (
        <Fragment>
            <Wrapper modal={modal} unLoading={unLoading} toggleLoading={toggleLoading} close={() => toggleModal()} id={product?.id} />
            {loading && <Loader fullScreen />}
            {product ? <LogoCard title={product?.name} subtitle={product?.description} logo={product?.logo} wizardClick={() => setOpenWizard(!openWizard)} /> : ''}
            <Spacer height="h-[1.188rem]" />
            <SubNavbar active="hypotheses" id={id} />
            <Spacer height="h-[1.125rem]" />
            {openWizard ? <FocusComponent /> : <>

                {product?.isTeamLead && (
                    <div className="flex gap-3 mt-2">
                        <div
                            className="bg-derisky-rose text-brand-400 text-md hover:shadow-inner shadow-md flex p-2 pl-6 pr-6  cursor-pointer gap-3 self-center content-center"
                            onClick={hypothesisModal}>
                            <Icon image='alert-circle' size={18} color='brand-400' />
                            Add Hypothesis
                        </div>
                        <div
                            className={`border border-brand-400 text-brand-500 text-md rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center ${user?.plan === 'free' && 'opacity-50'}`}
                            onClick={() => {
                                if (user?.plan != 'free') window.location.href = 'http://derisky.ai/pricing'
                                else { }
                            }}
                        >
                            🧠 AI Hypothesis
                        </div>

                    </div>

                )}
                <Spacer height="h-[1.125rem]" />
                <Card>
                    <MultiSelect
                        label="Cases"
                        options={dropdownValues}
                        type="multiSelect"
                        // value= value && value?.teamLead?.id,
                        isMulti={false}
                        parent={'hypo'}
                        onChange={(e) => getAllFilterHypothesis(e?.value)}
                    />
                    {/* <Select
					label="Cases"
					type="select"
					options={dropdownValues}
					onChange={(name, value, valid) => {
						getAllFilterHypothesis(value)
					}}
				/> */}
                </Card>

                <Spacer height="h-[1.125rem]" />
                <div className="flex flex-col gap-[0.5rem]">
                    {hypothesis?.map((e) => {
                        return (
                            <>
                                <Spacer height="h-[1.125rem]" />
                                <h1 className="text-[1.325rem]  font-normal leading-5">{e?.label}</h1>
                                <Spacer height="h-[1.125rem]" />
                                <div className="flex items-center justify-between ">
                                    <div>
                                        <h1 className="text-[1rem]  font-semibold leading-4">Hypothesis</h1>
                                    </div>
                                    <div className="flex items-center">
                                        <h1 className="text-[1rem]  font-semibold leading-4 mr-2">Risk</h1>
                                        <h1 className="text-[1rem]  font-semibold leading-4 mr-2">Open Risk</h1>
                                        <img src="/icons/arrow_down.svg" alt="" />
                                    </div>
                                </div>
                                <Spacer height="h-[1.125rem]" />
                                <div className="flex flex-col gap-[0.5rem]">
                                    {e?.hypothesis?.map((hypo) => {
                                        return (
                                            <Accordions
                                                title={e?.phase}
                                                text={hypo?.statement}
                                                risk={hypo?.risk}
                                                edit={() => hypothesisModal(hypo)}
                                                delete={() => deleteHypothesisModal(hypo?.id)}
                                                newExperience={() => experienceModal(hypo, '')}
                                                experiments={hypo?.experiments}
                                                isTeamLead={product?.isTeamLead}
                                                onEdit={(e) => {
                                                    experienceModal(hypo, e)
                                                }}
                                                onDelete={(e) => deleteExperimentModal(e)}
                                                onFinish={(data) => {
                                                    setFinishModelData()
                                                    setConfidenceModel(true)
                                                    setConfidenceData(data)
                                                    setHypothesisData(hypo?.risk)
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                            </>
                        )
                    })}

                    {confidenceModel && (
                        <ConfidenceListModal
                            onClose={() => {
                                setConfidenceModel(false)
                            }}
                            data={confidenceData}
                            confidence={(e) => {
                                finishExperiment(true, e)
                            }}
                        />
                    )}
                </div>
            </>}
        </Fragment>
    )
}

/***
 *
 *   FILE UPLOADER
 *   Drag & drop file upload component
 *   Can upload multiple files
 *   Includes fallback for older browser
 *
 *   PROPS
 *   name: input name
 *   max: max number of files
 *   maxFileSize: limit size of file in mb§
 *   accept: array of filetypes to accept, eg. ['jpg', 'gif']
 *
 **********/

import { useState, useRef, useEffect } from 'react'
import { Icon, Button, ClassHelper } from 'components/lib'
import { Label } from '../label/label'
import { Error } from '../error/error'
import Style from './file.tailwind.js'

export function FileInput(props) {
	const fileInputRef = useRef()
	const [dragging, setDragging] = useState(false)
	const [profileUpdate, setProfileUpdate] = useState(false)
	const [valid, setValid] = useState(props.valid)
	const [error, setError] = useState(props.errorMessage || 'Please select a file')
	const [filesData, setFile] = useState([])
	const [imageDeleted, setImageDeleted] = useState(false)

	useEffect(() => {
		if (props?.value?.length == 0 && props.valid) {
			setValid(false)
		}
		else if (props?.value == undefined || props?.value?.length == 0)
			setValid(props?.valid)

	}, [props])


	function validate(files) {
		const fileStore = []
		// check for max files
		if (files.length > props.max) {
			setValid(false)
			setError(`Maximum of ${props.max} file`)
			return false
		} else {
			// check files exist
			if (files.length) {
				for (let i = 0; i < files.length; i++) {
					const file = files[i]
					const type = file.type.substring(file.type.indexOf('/') + 1).toString()

					// validate file type
					if (props.accept?.length && !props.accept.includes(type)) {
						setValid(false)
						setError('.' + file.type.substring(file.type.indexOf('/') + 1) + ' files are not allowed')
					}

					// validate file size (in mb)
					else if (props.maxFileSize && file.size > 1048576 * props.maxFileSize) {
						setValid(false)
						setError(`Max filesize: ${props.maxFileSize}mb`)
					} else {
						// store the file in form store
						setValid(true)
						fileStore.push({
							name: file.name,
							data: file,
							url: URL.createObjectURL(file),
							size: file.size,
							type: file.type
						})
					}
				}
				setFile(fileStore)
				props.onChange(props.name, fileStore, valid)
			}
		}
	}

	function deleteFile(file) {
		setFile([])
		setImageDeleted(true)
		setValid(false)
		fileInputRef.current.value = ''

		props.onChange(
			props.name,
			[
				{
					name: file.name,
					size: file.size,
					type: file.type
				}
			],
			true
		)
		// console.log('setFile', file, fileInputRef.current.value)
	}

	function onDrag(e, state) {
		e.preventDefault()
		e.stopPropagation()
		setDragging(state)
	}

	const fileStyle = ClassHelper(Style, {
		dropzone: true,
		dragging: dragging,
		error: valid === false ? true : false,
		success: props.value?.length && valid === true
	})

	return (
		<div className={Style.file}>
			{props.parent == 'profile' ? (
				''
			) : (
				<>
					{props.label && <Label text={props.label} required={props.required} for={props.name} />}

					<div
						className={fileStyle}
						onClick={(e) => fileInputRef.current.click()}
						onDragOver={(e) => onDrag(e, true)}
						onDragEnter={(e) => onDrag(e, true)}
						onDragLeave={(e) => onDrag(e, false)}
						onDrop={(e) => {
							onDrag(e, false)
							validate(e.dataTransfer.files)
						}}>
						{/* fallback for old browsers */}
						<input
							type="file"
							className={Style.legacyInput}
							files={props.value}
							ref={fileInputRef}
							multiple={props?.max == 1 ? false : true}
							accept=".jpg, .png, .jpeg, .webp"
							onChange={(e) => {
								validate(fileInputRef.current.files)
							}}
						/>

						<div className={Style.label}>
							<Icon image={'upload'} className={Style.labelIcon} />

							{/* { props.value?.length ?
            <FileList files={ props.value } delete={ deleteFile }/> : */}
							<div className={Style.labelText}>Drop file here</div>
							{/* } */}
						</div>
					</div>

					{valid == false && <Error message={error} />}
				</>
			)}
			<div className={`flex gap-[0.8rem] mb-3 mt-3 flex-wrap ${props.parent == 'profile' ? 'justify-center' : ''}`}>
				{profileUpdate == false && filesData?.length
					? filesData?.map((e) => {
						return (
							<>
								<img className="h-24" src={e?.url} alt={e?.name} />
								{props.parent == 'profile' ? (
									''
								) : (
									<Button icon="x" color="#d95565" width="auto !important" background={'bg-gray-300 rounded-[100%]'} size={17} className={Style.fileListButton + ' ' + 'right-6 '} action={() => deleteFile(e)} />
								)}
							</>
						)
					})
					: props?.images && props.parent != 'profile' && !imageDeleted && (
						<>
							<img className="h-24" src={process.env.REACT_APP_IMAGE_URL + props?.images} alt={process.env.REACT_APP_IMAGE_URL + props?.images} />
							{props.parent == 'profile' ? (
								''
							) : (
								<Button icon="x" color="#d95565" width="auto !important" background={'bg-gray-300 rounded-[100%]'} size={17} className={Style.fileListButton + ' ' + 'right-6 '} action={() => deleteFile(props?.images)} />
							)}
						</>
					)}
				{props.parent == 'profile' && (
					<div className="flex justify-center mb-3">
						<label
							style={{
								display: 'inline-block',
								flexShrink: '0',
								position: 'relative',
								borderRadius: '0.42rem'
							}}
							htmlFor="faceImage"
							aria-label="upload picture">
							<img className="h-24 rounded-2xl shadow-md" src={filesData.length ? filesData[0]?.url : props?.images ? process.env.REACT_APP_IMAGE_URL + props?.images : '/dance.gif'} alt={''} />
						</label>
						<input
							style={{ display: 'none' }}
							accept=".jpg, .png, .jpeg, .webp"
							id="faceImage"
							type="file"
							files={props.value}
							ref={fileInputRef}
							onChange={(e) => {
								validate(fileInputRef.current.files)
								setProfileUpdate(true)
							}}
						// onChange={handleCapture}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

function FileList(props) {
	if (!props.files?.length) return false

	return (
		<div>
			{props.files.map((file) => {
				return (
					<div key={file.name} className={Style.fileListItem}>
						<span>{file.name}</span>

						<Button icon="x" color="#d95565" size={13} className={Style.fileListButton} action={(e) => props.delete(file)} />
					</div>
				)
			})}
		</div>
	)
}

import PropTypes from 'prop-types'
import {SocialRow} from "../../../components/socialrow/socialrow";

const FrameComponent = ({className = ''}) => {
	return (
		<div
			className={`self-stretch bg-whitesmoke-100 overflow-hidden shrink-0 flex flex-col-reverse lg:flex-row items-center lg:items-start justify-start pt-[76px] px-[2rem] lg:px-[140px] box-border gap-[2rem] lg:gap-[74px] max-w-full z-[1] text-left text-9xl text-gray-900 font-plus-jakarta-sans mq450:gap-[18px] mq450:pl-5 mq450:pr-5 mq450:box-border mq800:gap-[37px] mq800:pt-[49px] mq800:px-[70px] mq800:pb-[61px] mq800:box-border ${className}`}>
			<div className="w-[223px] lg:w-[423px] md:w-[323px] justify-center relative mq1150:hidden mq1350:max-w-full">
				<img className=" " alt="" src="/Vector.svg" />
				<img className="absolute top-[8px] left-[16px] w-[407px] h-full lg:h-[560px] object-contain z-[1]" loading="lazy" alt="" src="/Mask group.svg" />
			</div>
			<div className="flex-1 flex flex-col items-start justify-start pt-[1rem] lg:pt-[126px] px-0 pb-0 box-border  shrink-0 mq450:pt-[82px] mq450:box-border mq1150:max-w-full mq1350:hidden">
				<div className="self-stretch flex flex-col items-start justify-start gap-[48px] max-w-full mq800:gap-[24px]">
					<blockquote className="m-0 self-stretch relative  text-[20px] leading-[30px] lg:leading-[42px] lg:text-[28px] font-[500] text-[#101828] mq450:text-3xl mq450:leading-[34px]">
						"My vision is to turn ideas into secure investments in order to conserve global resources and focus humanity's attention where real and pressing issues can be effectively addressed."
					</blockquote>
					<div className="self-stretch flex flex-row items-center justify-start gap-[24px] max-w-full text-lg text-gray-800">
						<img className="h-[88px] relative rounded-9980xl overflow-hidden shrink-0 object-cover hidden" alt="" src="/frame-11712754591@2x.png" />
						<div className="flex-1 flex flex-col items-start justify-center gap-[8px] max-w-full">
							<b className="self-stretch relative text-[1.02rem] text-[#1D2939] font-[700] leading-[28px]">Laurens Lang</b>
							<div className="self-stretch relative text-base text-[1rem] text-[#475467] leading-[20px] font-[500]">Founder, Derisky.ai</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

FrameComponent.propTypes = {
	className: PropTypes.string
}

export default FrameComponent

import axios from 'axios'
import Items from 'components/items/items'
import { Card, Form, Loader, Select, SubNavbar, useLocation } from 'components/lib'
import Spacer from 'components/spacer/spacer'
import React, { Fragment, useEffect, useState } from 'react'
import { LogoCard } from "../../components/card/projectcard";
import FocusComponent from './megaWizard'

export const Insight = () => {
	const [product, setProduct] = useState([]);
	const [openWizard, setOpenWizard] = useState(false)

	const getProduct = async () => {
		if (id) {
			setLoading(true)
			await axios({
				method: 'get',
				url: `/api/product/${id}`
			})
				.then((res) => {
					setLoading(false)
					if (res.data) {
						setProduct(res.data.data)
					}
				})
				.catch((err) => {
					setLoading(false)
					console.log('err', err)
				})
		}
	}

	useEffect(() => {
		getProduct()
	}, [])

	const [learnings, setLearnings] = useState([])
	const router = useLocation()
	const [loading, setLoading] = useState(false)
	let id = router.pathname.replace('/insight/', '')

	useEffect(() => {
		getAllLearnings()
	}, [])

	const getAllLearnings = async () => {
		setLoading(true)
		await axios({
			method: 'get',
			url: `/api/all_experiment_learning/${id}`,
		}).then(res => {
			setLoading(false)

			if (res.data) {
				let arr = []
				let Arr = []
				res.data.data?.length && res.data.data?.map(e => {
					// arr.push(e)
					if (e?.children?.length) {
						e?.children?.map(res => {
							if (res?.children?.length) {
								res?.children?.map(child => {
									// arr.push(...child.children)
								})
							}
							arr.push(...res.children)

						})
						Arr.push(...e?.children)
					} else {
						arr.push(e)
					}
				})

				let data = arr.concat(Arr)
				let newArr = arr.concat(Arr)

				for (let h = 0; h < data.length; h++) {
					var curItem = data[h].id;
					var foundCount = 0;
					// search array for item
					for (let i = 0; i < data.length; i++) {
						if (data[i].id == data[h].id)
							foundCount++;
					}
					if (foundCount > 1) {
						// remove repeated item from new array
						for (let j = 0; j < newArr.length; j++) {
							if (newArr[j].id == curItem) {
								newArr.splice(j, 1);
								j--;
							}
						}
					}
				}

				setLearnings(newArr)
			}
		}).catch(err => {
			setLoading(false)
		})


	}

	//get all filter hypothesis
	const getAllFilterHypothesis = async (value) => {
		setLoading(true)
		await axios({
			method: 'post',
			url: `/api/all_filter_learning/${id}`,
			data: { query: value }
		}).then(res => {
			setLoading(false)
			if (res.data) {
				if (res.data) {
					let arr = []
					let Arr = []
					console.log('======res.data.data?.length', res.data.data?.length)
					res.data.data?.length && res.data.data?.map(e => {
						// arr.push(e)
						if (e?.children?.length) {
							e?.children?.map(res => {
								if (res?.children?.length) {
									res?.children?.map(child => {
										// arr.push(...child.children)
									})
								}
								arr.push(...res.children)

							})
							Arr.push(...e?.children)
						} else {
							arr.push(e)
						}
					})

					let data = arr.concat(Arr)
					let newArr = arr.concat(Arr)

					for (let h = 0; h < data.length; h++) {
						var curItem = data[h].id;
						var foundCount = 0;
						// search array for item
						for (let i = 0; i < data.length; i++) {
							if (data[i].id == data[h].id)
								foundCount++;
						}
						if (foundCount > 1) {
							// remove repeated item from new array
							for (let j = 0; j < newArr.length; j++) {
								if (newArr[j].id == curItem) {
									newArr.splice(j, 1);
									j--;
								}
							}
						}
					}

					setLearnings(newArr)
				}
			}
		}).catch(err => {
			setLoading(false)
		})
	}

	return (
		<Fragment>
			{product ? <LogoCard title={product?.name} subtitle={product?.description} logo={product?.logo} wizardClick={() => setOpenWizard(!openWizard)} /> : ''}
			{loading && <Loader fullScreen />}
			<Spacer height="h-[1.188rem]" />
			<SubNavbar active={'learnings'} id={id} />
			<Spacer height="h-[1.125rem]" />
			{openWizard ? <FocusComponent /> : <>

				<Card>
					<Select label='Cases'
						type='select'
						options={[
							{ label: 'Product', value: 'Product' },
							{ label: 'Product & Cases', value: 'Product & Cases' },
							{ label: 'Cases', value: 'Cases' }
						]}
						onChange={(name, value, valid) => { getAllFilterHypothesis(value) }}
					/>
				</Card>
				<Card>
					{learnings?.length ? <Items items={learnings} parent={'view'} /> : 'No Learning Found'}
				</Card>
			</>}
		</Fragment>
	)
}

import {useMemo} from 'react'
import PropTypes from 'prop-types'

const ButtonPrimary = ({className = '', propBackgroundColor, onClick}) => {
	const buttonPrimaryStyle = useMemo(() => {
		return {
			backgroundColor: propBackgroundColor
		}
	}, [propBackgroundColor])

	return (
		<button className={`cursor-pointer [border:none] py-3.5 px-6 bg-primary rounded-980xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-blue-600 ${className}`} style={buttonPrimaryStyle} onClick={onClick}>
			<a className="[text-decoration:none] relative text-base leading-[24px] font-semibold font-plus-jakarta-sans text-base-white text-center inline-block min-w-[91px]">Get Started</a>
		</button>
	)
}

ButtonPrimary.propTypes = {
	className: PropTypes.string,

	/** Style props */
	propBackgroundColor: PropTypes.any
}

export default ButtonPrimary

import React from 'react';
import './mvp.module.css'
import './canves.scss'
import { Button, MultiSelect, ViewContext, useLocation } from 'components/lib';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useContext } from 'react';


const Canvas = (props, data) => {
  const [editedData, setEditedData] = useState([])
  const [loading, setLoading] = useState(false)
  const router = useLocation()

  let id = router.pathname.replace('/hypothesis/', '')
  const context = useContext(ViewContext)
  const categories = ['Feasibility', 'Viability', 'Desirability', 'Adoptability', 'Compliance & Regulatory'

  ];

  const addHypothesis = async () => {
    if (editedData?.length) {
      for (let i = 0; i < editedData.length; i++) {
        setLoading(true)
        await axios({
          method: 'post',
          url: `/api/product_hypothesis`,
          data: {
            risk: editedData[i]?.risk ? editedData[i]?.risk : 0,
            phase: editedData[i]?.category && categories.includes(editedData[i]?.category) ? editedData[i]?.category : "Desirability",
            statement: editedData[i]?.hypothesis,
            date_created: moment().format('YYYY-MM-DD'),
            id: id
          }
        })
          .then((res) => {
            if (res.data) {

              setLoading(false)
            }
          })
          .catch((e) => {
            if (e?.response?.data?.message) {
              context.notification.show(e?.response?.data?.message, 'error', true)
            }
            setLoading(false)
          })

        if (i == editedData?.length - 1) {
          props?.callBack()
        }
      }


    }
  }

  return (
    <div>
      <h3 className='font-bold'>Hypothesis</h3>
      <div>
        {/* {props?.data?.AIResponse && <div dangerouslySetInnerHTML={{ __html: props?.data?.AIResponse }} />} */}
        {props?.data?.AIResponse && <HypothesisGrid hypothesesData={props?.data?.AIResponse} data={setEditedData} />}
      </div>
      <div className='flex justify-between gap-2 mb-10'>
        <Button
          className={`inline-block rounded bg-primary px-6 w-[100%]  pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
          medium
          text={'Cancel'}
          color="white"
          rounded
          action={() => props?.cancell()}
        />
        <Button
          className={`inline-block rounded bg-primary px-6 w-[100%]  pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
          medium
          text={'Save'}
          color="blue"
          rounded
          action={() => addHypothesis()}
        />
      </div>
    </div>
  );
};

const HypothesisGrid = ({ hypothesesData, data }) => {
  const [editableData, setEditableData] = useState([]);

  const handleEdit = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index][field] = value;
    data && data(updatedData)
    setEditableData(updatedData);
  };

  useEffect(() => {
    try {
      const parsedData = JSON.parse(hypothesesData);
      if (parsedData) {
        // setEditableData(parsedData);
        validateRiskTotal(parsedData);
      }

      if (JSON.parse(hypothesesData)) {
        data && data(JSON.parse(hypothesesData))
      }

    } catch (error) {
      console.log('=======error', error)

    }

  }, [hypothesesData])

  const validateRiskTotal = (data) => {
    const totalRisk = data.reduce((acc, hypothesis) => acc + hypothesis.risk, 0);

    if (totalRisk > 100) {
      console.warn('Total risk exceeds 100. Reducing risk values proportionally.');
      const reductionFactor = 100 / totalRisk;
      const adjustedData = data.map((hypothesis) => ({
        ...hypothesis,
        risk: Math.floor(hypothesis.risk * reductionFactor),
      }));

      setEditableData(adjustedData);
    } else {
      setEditableData(data);
    }
  };

  let totalRisk = editableData.reduce((p, c) => {
    const currentRisk = parseInt(c?.risk) || 0;  // Ensure that risk is a number, default to 0 if it's not present or not a valid number
    return {
      risk: parseInt(p?.risk) + currentRisk
    };
  }, { risk: 0 }).risk;


  const categories = [
    { label: 'Feasibility', value: 'Feasibility' },
    { label: 'Viability', value: 'Viability' },
    { label: 'Desirability', value: 'Desirability' },
    { label: 'Adoptability', value: 'Adoptability' },
    { label: 'Compliance & Regulatory', value: 'Compliance & Regulatory' }
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2">Risk</th>
            <th className="border border-gray-300 p-2">Category</th>
            <th className="border border-gray-300 p-2">Hypothesis</th>
          </tr>
        </thead>
        <tbody>
          {editableData.map((hypothesis, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-2">
                <input
                  // type="number"
                  value={hypothesis.risk}
                  onChange={(e) => handleEdit(index, 'risk', e.target.value)}
                  className="w-full"
                />
              </td>
              <td className="border border-gray-300 p-2">

                <MultiSelect
                  // label="category"
                  options={categories}
                  type="multiSelect"
                  value={hypothesis.category}
                  isMulti={false}
                  onChange={(e) => handleEdit(index, 'category', e)}
                  parent={'canvas'}
                />
              </td>
              <td className="border border-gray-300 p-2">
                <textarea
                  type="text"
                  value={hypothesis.hypothesis}
                  onChange={(e) => handleEdit(index, 'hypothesis', e.target.value)}
                  className="w-full"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {totalRisk ? <div className='p-3'>Total Risk: {totalRisk}</div> : ""}
    </div>
  );
};

export default Canvas;

import ButtonPrimary from './ButtonPrimary'
import FeatureText1 from './FeatureText1'
import FeatureText from './FeatureText'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import {features} from 'features'
import {useState} from 'react'
import {Badge, Button, Grid} from 'components/lib'

const FrameComponent1 = ({className = ''}) => {
	const navigate = useNavigate()
	const [selectedItem, selectItem] = useState(null)
	const onSelectItem = function (index) {
		selectItem(features[index])
	}
	console.log(selectedItem)

	return (
		<section
			className={`self-stretch flex flex-row items-start justify-start pt-0 px-0  bg-[#F7F7F7] box-border max-w-full text-left text-37xl text-gray1-100 font-plus-jakarta-sans mq800:pb-[59px] mq800:box-border mq1150:pb-[91px] mq1150:box-border ${className}`}>
			<div className={`h-full  flex-1 relative bg-whitesmoke-100 overflow-hidden max-w-full mq1150:h-auto mq1150:min-h-[980] ${selectedItem?.video ? 'lg:h-[87rem]' : 'lg:h-[980px]'}`}>
				<div className="flex hidden lg:flex absolute top-[-17rem] right-[-9rem]">
					<img className="relative w-[868.4px] h-[3353.6px]" alt="" src="/union-1.svg" />
				</div>

				<div className="relative top-[2rem] lg:top-[140px] left-[0rem] lg:left-[140px] w-auto lg:w-[1161px] flex flex-col lg:flex-row items-start justify-start gap-[2rem] lg:gap-[132px] max-w-full z-[1] flex-nowrap lg:flex-wrap mq1150:flex-wrap">
					<div className="w-auto lg:w-[464px] p-5 lg:p-0 flex flex-col items-start justify-start gap-[48px] mq800:min-w-full mq1150:flex-1">
						<div className="self-stretch flex flex-col items-start justify:center lg:justify-start gap-[32px]">
							<h1 className="m-0 self-stretch relative text-inherit text-[32px] lg:text-[56px] leading-[38.4px] lg:leading-[67.2px] font-[500] font-medium font-inherit mq450:text-15xl mq450:leading-[40px] mq800:text-26xl mq800:leading-[54px]">
								How Does Derisky.ai Work
							</h1>
							<div className="w-[95%] self-stretch relative text-[1rem] lg:text-[1.02rem] text-[#475467] leading-[24px] lg:leading-[27px] break-words font-[500] text-gray-600">{`Implement actionable innovation metrics. Measure & visualize your impact, derisk with smart experiments, make data-driven portfolio decisions.`}</div>
						</div>
						<ButtonPrimary className="rounded-3xl  bg-[#6145F7] hover:!bg-[#422db6] text-white" onClick={() => navigate('/signup')} />
						<div className="h-full w-full flex flex-col items-center justify-center overflow-scroll">
							{selectedItem?.video && (
								<>
									<video autoPlay src={selectedItem.video} aria-description={selectedItem.title + ': ' + selectedItem.description} />
									<p className="mt-2 align-center">{selectedItem.title}</p>
								</>
							)}
						</div>
					</div>

					<div className={`flex-1 w-full pb-[4rem] flex flex-col items-start p-5 lg:p-0 justify-start gap-[24px]  text-base-white overflow-scroll ${selectedItem?.video ? 'h-[1239px]' : 'h-[839px]'}`}>
						{features &&
							features.map((res, i) => (
								<FeatureText1
									imageClassName={`rounded-[50%] ${selectedItem?.video == res?.video ? 'bg-[white]' : 'bg-[white] lg:bg-[#F2F4F7]'} hover:bg-[#F2F4F7]  `}
									lightbulb02={res.icon}
									hypothesizeYourBusinessMo={res.title}
									consciouslyRealizingOnWhi={res.description}
									second="23 Second"
									className={`${
										selectedItem?.video == res?.video ? 'bg-[#6145F7] lg:bg-[#6145F7] text-[white] flex-grow' : 'bg-[#6145F7] lg:bg-[#fff] text-[white] lg:text-[#98A2B3] hover:text-[white] hover:bg-[#6145F7] flex-grow'
									}  `}
									playButtonClass={`${selectedItem?.video == res?.video ? 'bg-[#FFFFFF1A]' : 'bg-[#FFFFFF1A] lg:bg-[#EAECF0] '}  hover:bg-[#FFFFFF1A] rounded-[999px]`}
									descriptionClassName={`${selectedItem?.video == res?.video ? 'text-[#FFFFFFCC]' : 'text-[#D0D5DD]'}  hover:text-[#FFFFFFCC] `}
									play="/play.svg"
									onPlayClick={() => onSelectItem(i)}
								/>
							))}

						{/* <FeatureText1
							imageClassName={'rounded-[50%] bg-[white]'}
							lightbulb02="/lightbulb02.svg"
							hypothesizeYourBusinessMo="Hypothesize your business models."
							consciouslyRealizingOnWhi="Consciously realizing on which assumptions your business idea relying on is crucial."
							second="23 Second"
							className="bg-[#6145F7] lg:bg-[#6145F7] text-[white] flex-grow"
							playButtonClass={'bg-[#FFFFFF1A] rounded-[999px]'}
							descriptionClassName={`text-[#FFFFFFCC]`}
							play="/play.svg"
						/>
						<FeatureText1
							lightbulb02="/scales01.svg"
							hypothesizeYourBusinessMo="Assess risks of your assumptions"
							consciouslyRealizingOnWhi="Based on the importance for the venture and the vagueness the risk is calculated."
							second="20 Second"
							play="/play-1.svg"
							imageClassName={'rounded-[50%] bg-[#F2F4F7]'}
							descriptionClassName={'text-[#D0D5DD]'}
							className="bg-[#6145F7] lg:bg-[#fff] text-[white] lg:text-[#98A2B3] flex-grow"
							playButtonClass={'bg-[#FFFFFF1A] lg:bg-[#EAECF0] rounded-[999px]'}
						/>

						<FeatureText1
							lightbulb02="/target-04.svg"
							hypothesizeYourBusinessMo="Assess risks of your assumptions"
							consciouslyRealizingOnWhi="Run smart experiments to test your hypotheses. Innovation is risky. Our tool shows the progress and the risks you attacked."
							second="40 Second"
							imageClassName={'rounded-[50%] bg-[#F2F4F7]'}
							descriptionClassName={'text-[#D0D5DD]'}
							className="bg-[#6145F7] lg:bg-[#fff] text-[white] lg:text-[#98A2B3] flex-grow"
							playButtonClass={'bg-[#FFFFFF1A] lg:bg-[#EAECF0]  rounded-[999px]'}
							play="/play-1.svg"
						/>
						<FeatureText1
							lightbulb02="/currencydollar.svg"
							hypothesizeYourBusinessMo="Build data-driven arguments for investments."
							consciouslyRealizingOnWhi="No more discussions: Your product deserves another cash infusion and you can proof it with data."
							second="18 Second"
							imageClassName={'rounded-[50%] bg-[#F2F4F7]'}
							descriptionClassName={'text-[#D0D5DD]'}
							className="bg-[#6145F7] lg:bg-[#fff] text-[white] lg:text-[#98A2B3] flex-grow"
							playButtonClass={'bg-[#FFFFFF1A] lg:bg-[#EAECF0]  rounded-[999px]'}
							play="/play-1.svg"
						/>
						<FeatureText1
							imageClassName={'rounded-[50%] bg-[#F2F4F7]'}
							descriptionClassName={'text-[#D0D5DD]'}
							className="bg-[#6145F7] lg:bg-[#fff] text-[white] lg:text-[#98A2B3] flex-grow"
							playButtonClass={'bg-[#FFFFFF1A] lg:bg-[#EAECF0] rounded-[999px]'}
							lightbulb02="/barchart10.svg"
							hypothesizeYourBusinessMo="Make data-driven portfolio decision."
							consciouslyRealizingOnWhi="Based on the innovation risk, risk reduction and the importance for the enterprise, the portfolio can be rebalanced"
							second="20 Second"
							play="/play-1.svg"
						/> */}
					</div>
				</div>
			</div>
		</section>
	)
}

FrameComponent1.propTypes = {
	className: PropTypes.string
}

export default FrameComponent1

import ButtonSecoundary from './ButtonSecoundary'
import ButtonPrimary from './ButtonPrimary'
import PropTypes from 'prop-types'
import {useLocation, useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {useEffect} from 'react'

const Header = ({className = ''}) => {
	const navigate = useNavigate()
	const pathneme = useLocation()

	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleToggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}
	useEffect(() => {
		if (window.innerWidth > 400) {
			setIsMenuOpen(false)
		}
	}, [window.innerWidth])

	return (
		<section className={`self-stretch flex flex-col items-start justify-start relative max-w-full shrink-0 ${className}`}>
			<div className="h-[454px] w-[454px] absolute !m-[0] bottom-[-181px] left-[-126px] [filter:blur(162.5px)] rounded-[50%] bg-mediumpurple" />
			<header className="w-full flex flex-col items-start justify-start max-w-full z-[1] text-left text-base text-base-white font-plus-jakarta-sans">
				<div className="w-full bg-[#6145F7] flex items-center justify-center py-4 px-5">
					<div className="relative leading-[24px] capitalize font-medium text-white text-center">
						{`✨ New: Claim your free micro MBA derisking intelligence.   `}
						<span className="[text-decoration:underline] cursor-pointer" onClick={() => navigate('/mba')}>
							High Quality Innovation for Free!
						</span>
					</div>
				</div>
				<nav
					className={`w-full flex items-center justify-between py-6 px-4 lg:px-[140px] box-border whitespace-nowrap text-center text-xl text-gray1-100 font-plus-jakarta-sans ${
						pathneme.pathname == '/mba' || pathneme.pathname == '/innovation-metrics' ? 'bg-brand-400' : ''
					}`}>
					<div className="w-[122px] flex items-center justify-start gap-[12px]">
						<img className="h-12 w-6 relative overflow-hidden shrink-0 cursor-pointer" loading="lazy" alt="Logo" src="/frame.svg" onClick={() => navigate('/')} />
						<div
							className={`relative tracking-[-1px] leading-[120%] font-semibold inline-block min-w-[86px] text-base lg:text-xl cursor-pointer ${
								pathneme.pathname == '/mba' || pathneme.pathname == '/innovation-metrics' ? 'text-white' : ''
							}`}
							onClick={() => navigate('/')}>
							Derisky.ai
						</div>
					</div>
					<button className="lg:hidden text-gray-800 focus:outline-none" onClick={handleToggleMenu}>
						<img className="h-8 w-8" src="/Icon.svg" alt="Menu" />
					</button>
					<div className={`lg:flex items-center justify-between flex-1 ${isMenuOpen ? 'absolute bg-white border-2 p-5 top-[6rem] right-[4rem] rounded-2xl z-[1] flex flex-col text-start items-start' : 'hidden'} lg:flex`}>
						<div className={`flex flex-col lg:flex-row items-start lg:items-center justify-between gap-6 lg:gap-[48px] lg:justify-center text-base text-gray1-800 ${isMenuOpen ? '' : ''}`}>
							<div
								className={`relative cursor-pointer ml-0 lg:ml-5 ${
									pathneme.pathname == '/' ? 'font-semibold text-gray-800' : pathneme.pathname == '/mba' || pathneme.pathname == '/innovation-metrics' ? 'text-white' : 'text-gray-500'
								}`}
								onClick={() => navigate('/')}>
								Home
							</div>
							<div
								className={`relative font-medium  cursor-pointer ${
									pathneme.pathname == '/pricing' ? 'font-semibold text-gray-800' : pathneme.pathname == '/mba' || pathneme.pathname == '/innovation-metrics' ? 'text-white' : 'text-gray-500'
								}`}
								onClick={() => navigate('/pricing')}>
								Pricing
							</div>
							<div
								className={`relative font-medium text-gray-500 cursor-pointer ${pathneme.pathname == '/mba' || pathneme.pathname == '/innovation-metrics' ? 'font-semibold text-white' : 'text-gray-500'}`}
								onClick={() => navigate('/mba')}>
								Derisky MBA
							</div>
						</div>
						<div className="flex flex-col lg:flex-row items-center justify-start gap-[16px] mt-4 lg:mt-0">
							<ButtonSecoundary
								className={`rounded-3xl  hover:bg-[#EAECF0] hover:text-white ${pathneme.pathname == '/mba' || pathneme.pathname == '/innovation-metrics' ? 'text-white hover:text-gray-800 ' : 'hover:text-gray-600 '}`}
								onClick={() => navigate('/signin')}
							/>
							<ButtonPrimary className="rounded-3xl bg-[#6145F7] hover:!bg-[#422db6] text-white" onClick={() => navigate('/signup')} />
						</div>
					</div>
				</nav>
			</header>
		</section>
	)
}

Header.propTypes = {
	className: PropTypes.string
}

export default Header

/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from 'react'
import { AuthContext, Onboarding, Form, Message } from 'components/lib'

export function OnboardingView(props) {
	const context = useContext(AuthContext)
	const views = [
		{
			name: 'MBA Enrolment',
			description: `Welcome, ${context.user.name}!`,
			component: <Welcome />
		},
		{
			name: 'Invite your team.',
			description: `${context.user.name}, MBA Learning is more fun when you invite your team and your friends.`,
			component: <InviteUsers />
		},
		{
			name: `Give feedback.`,
			description: `${context.user.name}, help us to grow.`,
			component: <Testimonial />

		}
	]

	if (context.user.duplicate_user) {
		views.unshift({
			name: 'Important!',
			description: '',
			component: <DuplicateUser />
		})
	}

	return <Onboarding save onFinish="/" views={views} />
}

function DuplicateUser() {
	return <Message type="warning" title={`You already have an account`} text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future." />
}

function Testimonial() {
	return(<div>
		If you'd like to share your experience, please don't hesitate to <a href='https://testimonial.to/derisky' target='_blank'> send us your testimonials</a>.

		Your feedback is highly appreciated and will help us improve.
	</div>);
}
function Welcome() {
	return (
		<Fragment>
			<p>Congratulations on joining the Derisky Micro MBA! </p>
			<br />
			<p>
				You will learn how to think like a venture capitalist and transform your vague business ideas into a portfolio of worth-building, fundable products.
			</p>
			<b>
				It's important to confirm your subscription via mail. Please check the spam folder.
			</b>
		</Fragment>
	)
}


function InviteUsers(props) {
	return (<div>
			My name is <a target='_blank' href='http://linkedin/in/laurenslang'>Laurens Lang M.Sc. MBA</a>. I've invested thousands of hours in developing the Derisky Micro MBA. If you find it valuable, please share it with others. Your support is greatly appreciated.

			My vision is to empower you to turn your vague business ideas into a portfolio of worth-building & investable products.

			<br/><br/>
			<strong>Invite now a colleague:</strong>
			<Form
				inputs={{
					email: {
						label: 'Email',
						type: 'email',
						required: true
					}
				}}
				buttonText="Send Invite"
				url="/api/invite"
				method="POST"
			/>
	</div>
	)
}

import Header from '../components/Header'
import Benefits from '../components/Benefits'
import Media from '../components/Media'
import Decisions from '../components/Decisions'
import FrameComponent1 from '../components/FrameComponent1'
import Testimonials from '../components/Testimonials'
import ButtonPrimary from '../components/ButtonPrimary'
import FrameComponent from '../components/FrameComponent'
import Navigation1 from '../components/Navigation1'
import {useNavigate} from 'react-router-dom'
import {Testimonialto} from 'components/testimonialto/testimonialto'
import {SocialRow} from "../../../components/socialrow/socialrow";

const WebVersion = () => {
	const navigate = useNavigate()
	return (
		<div className="w-full relative bg-base-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-0 lg:pb-4 box-border leading-[normal] tracking-[normal] text-left text-lg text-gray-900 font-plus-jakarta-sans mq1350:h-auto">
			<Header />
			<Benefits />
			<Media />
			<Decisions />
			<FrameComponent1 />
			<Testimonialto />
			<section className="self-stretch h-auto lg:h-[600px] bg-[#6145F7] overflow-hidden shrink-0 flex flex-row items-start justify-start py-[101px] px-[2rem] lg:px-[140px] box-border gap-[186px] max-w-full text-left text-37xl text-base-white font-plus-jakarta-sans mq450:gap-[23px] mq450:pt-5 mq450:pb-5 mq450:box-border mq800:gap-[46px] mq800:pl-[35px] mq800:pr-[35px] mq800:box-border mq1150:pt-7 mq1150:pb-7 mq1150:box-border mq1350:gap-[93px] mq1350:py-[43px] mq1350:px-[70px] mq1350:box-border">
				<div className="w-full lg:w-[631px] flex flex-col items-start justify-start gap-[32px] shrink-0  mq800:gap-[16px] mq1150:hidden mq1150:max-w-full">
					<h1 className="m-0 self-stretch relative text-inherit text-[2rem] lg:text-[56px] text-white leading-[38.2px] lg:leading-[67.2px] font-medium font-inherit mq450:text-15xl mq450:leading-[40px] mq800:text-26xl mq800:leading-[54px]">
						Learn to think like a venture capitalist.
					</h1>
					<div className="self-stretch relative text-[1rem] lg:text-[1.02rem] text-white leading-[20px] lg:leading-[27px] font-medium">{`Transform your vague business ideas into a portfolio of worth-building & investable products. Learn how to explore business concepts, validate your product, manage risks, and derisk through smart experiments. Build a diverse portfolio of ideas and make data-driven decisions. Save millions and decades by mastering effective derisking strategies.`}</div>
					<ButtonPrimary className="rounded-3xl hover:bg-[#6145F7] bg-[#422db6] text-white" onClick={() => navigate('/signup')} />
				</div>
				<div className="absolute right-[-10rem] flex ">
					<img className="mt-[-1307px] h-[3042.6px] w-[651.3px] relative shrink-0  mq1150:hidden mq1150:max-w-full" alt="" src="/union-2.svg" />
				</div>
			</section>
			<section className="self-stretch flex flex-col items-start justify-start pt-0 px-0 box-border gap-[81px] max-w-full shrink-0 mq450:gap-[20px] mq450:pb-[207px] mq450:box-border mq800:gap-[40px] mq800:pb-[319px] mq800:box-border mq1350:pb-[491px] mq1350:box-border">
				<FrameComponent />


				<Navigation1 />
			</section>
			{/* <div className="w-[920px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full shrink-0">
				<div className="h-[108px] w-[484px] relative leading-[42px] text-[28px] text-[#101828] font-medium inline-block shrink-0 max-w-full">
					My vision is to turn ideas into secure investments in order to conserve global resources and focus humanity's attention where real and pressing issues can be effectively addressed.
				</div>
			</div> */}
		</div>
	)
}

export default WebVersion

/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import { Animate, Content, Image, ClassHelper } from 'components/lib';
import Style from './brandrow.tailwind.js';
import { RowHeader } from '../../../../components/layout/row/header.js';

export function BrandRow(props) {

  const heroStyle = ClassHelper(Style, {
    hero: true,
    className: props.className
  });

  return (
    <section className={heroStyle}>
      <Content>
        <Animate type='pop'>
          <section className={Style.blurb}>
            <RowHeader color='brand' title={props.title} />

            {props.children && props.children}

          </section>
        </Animate>
      </Content>
    </section>
  )
}
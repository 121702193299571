import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	Button,
	DropdownMenu,
	Grid,
	Icon,
	Loader,
	SubNavbar,
	useLocation,
	ViewContext
} from 'components/lib'
import { LogoCard } from 'components/card/projectcard'
import Spacer from 'components/spacer/spacer'
import axios from 'axios'
import Wrapper from "../../components/MyStepwizard";
import FocusComponent from './megaWizard'



export function Cases(props) {
	const context = useContext(ViewContext)
	const [businessCase, setBusinessCase] = useState([])
	const [product, setProduct] = useState()
	const router = useLocation()
	const [loading, setLoading] = useState(false)
	let id = router.pathname.replace('/cases/', '')
	const user = JSON.parse(localStorage.getItem('user'))
	const [modal, setModal] = useState(false);
	const [openWizard, setOpenWizard] = useState(false)

	useEffect(() => {
		getBusinessCase()
		getProduct()
	}, [])

	const getProduct = async () => {
		if (id) {
			const res = await axios({
				method: 'get',
				url: `/api/product/${id}`
			})

			if (res.data) {
				setProduct(res.data.data)
			}
		}
	}

	const getBusinessCase = async () => {
		setLoading(true)
		await axios({
			method: 'get',
			url: `/api/all_business_case/${id}`
		})
			.then((res) => {
				setLoading(false)
				if (res.data) {
					setBusinessCase(res.data.data)
				}
			})
			.catch((err) => {
				setLoading(false)
				console.log(err)
			})
	}
	function toggleLoading() {
		setLoading(!loading);
	}

	function unLoading() {
		setLoading(false);
	}


	function caseModal(value) {
		const scopes = [{ value: 'Most promising case', label: 'Most promising case' }]
		context.modal.show(
			{
				title: value?.id ? 'Edit Business cases' : 'Add Business cases',
				form: {
					name: {
						label: 'Name',
						type: 'text',
						required: true,
						max: 256,
						value: value?.name
					},
					description: {
						label: 'Description',
						type: 'textarea',
						required: true,
						value: value?.description
					},
					coststructure: {
						label: 'cost structure',
						tooltip: 'test',
						type: 'number',
						required: true,
						tooltipText: 'this is a tooltip',
						value: value?.coststructure
					},
					date: {
						label: 'Date',
						type: 'date',
						required: true,
						min: new Date(),
						value: value?.date
					},
					potential: {
						label: 'Revenue Potential',
						type: 'number',
						required: false,
						value: value?.potential
					},
					ebitda: {
						label: 'ebitda',
						type: 'number',
						required: false,
						value: value?.ebitda
					},
					NPV: {
						label: 'expected NPV',
						type: 'number',
						required: false,
						tooltipText: 'test',
						value: value?.NPV
					},
					planned_cpex: {
						label: 'planned capex',
						type: 'number',
						required: false,
						tooltipText: 'test',
						value: value?.planned_cpex
					},
					promising_case: {
						type: 'checkbox',
						options: scopes?.map((value) => {
							return value.label
						}),
						default: value?.promising_case ? [value?.promising_case] : null
					}
				},
				buttonText: 'Save',
				url: value?.id ? `/api/business_case/${value?.id}` : `/api/business_case`,
				method: value?.id ? 'PUT' : 'POST',
				team_id: value?.id ? undefined : id,
			},
			(form, res) => {
				getBusinessCase()
			}
		)
	}

	const deleteBusinessCase = async (id) => {
		try {
			setLoading(true)
			const res = await axios({
				method: 'Delete',
				url: `/api/business_case/${id}`
			})
			if (res.data) {
				setLoading(false)
				if (res.data.message) context.notification.show(res.data.message, 'success', true)
				context.modal.hide(true)
				getBusinessCase()
			}
		} catch (error) {
			setLoading(false)
			context.handleError(error)
		}
	}

	function deleteBusinessCaseModal(id) {
		context.modal.show({
			title: 'Delete Business Case?',
			body: 'Are you really wants to do this.',
			delete: true,
			buttonText: 'Delete?',
			cancel: 'Cancel',
			submit: () => deleteBusinessCase(id),
			loading: loading,
			destructive: true
		})
	}

	async function mostPromisingCase(id, removePromise) {
		if (id) {
			await axios({
				method: 'PUT',
				url: `/api/business_case/${id}`,
				data: { promising_case: removePromise ? null : 'Most promising case' }
			})
				.then((res) => {
					if (res.data.message) {
						context.notification.show(removePromise ? 'This case is regular case now' : 'This case is promising now', 'success', true)
						getBusinessCase()
					}
				})
				.catch((e) => {
					if (e?.response?.data?.message) {
						context.notification.show(e?.response?.data?.message, 'error', true)
					}
				})
		}
	}


	function toggleModal() {
		setModal(!modal);
	}

	return (
		<Fragment>

			<Wrapper
				className='sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4'
				case_id={id}
				modal={modal} unLoading={unLoading} toggleLoading={toggleLoading} close={() => toggleModal()} product_id={product?.id} />

			{loading && <Loader fullScreen />}
			{product ? <LogoCard title={product?.name} subtitle={product?.description} logo={product?.logo} wizardClick={() => setOpenWizard(!openWizard)} /> : ''}
			<Spacer height="h-[1.188rem]" />
			<SubNavbar active="case" id={id} />
			<Spacer height="h-[1.125rem]" />
			{openWizard ? <FocusComponent /> : <>
				{product?.isTeamLead && (
					<div className='flex gap-2'>

						<div className="mt-2 border border-brand-400 text-brand-400 rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 text-xs self-center content-center"
							onClick={caseModal}
						>
							<Icon className="icon mr-2 ml-3 mt-[-.175em] " image={'briefcase'} size={18} color={'brand-400'} />
							Add Case
						</div>

						{/* <Button
						className={`inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] ${user?.plan === 'free' && 'opacity-50'}`}
						small
						text="Add Business Model"
						color="blue"
						rounded
						action={() => user?.plan != 'free' && businessModelModal()}
					/> */}
					</div>
				)}
				<Spacer height="h-[1.125rem]" />
				<Grid cols="6">
					<Grid className="bg-blue-600 text-white p-4 rounded-t-md">
						<p className="text-xl font-bold">Case</p>
						<p className="text-xl font-bold">Description</p>
						<p className="text-xl font-bold">Cost structure</p>
						<p className="text-xl font-bold">Date</p>
						<p className="text-xl font-bold">Revenue Potential</p>
						<p className="text-xl font-bold">Expected Return</p>
						<p className="text-xl font-bold">Ebitda</p>
						<p className="text-xl font-bold">Expected NPV</p>
						<p className="text-xl font-bold">Planned capex</p>
						<p className="text-xl font-bold"></p>
						<p className="text-xl font-bold"></p>
					</Grid>
					<div className="flex" style={{
						whiteSpace: 'nowrap',

					}}>
						{businessCase?.map((e, index) => {
							const isEven = index % 2 === 0;
							return (
								<div className={`flex rounded p-3 w-[17.5rem] mr-4 ${isEven ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-200`} key={e.id}>
									<Grid className="p-4">
										<h1 className="text-xl text-black font-bold" title={e?.name}>{e?.name}</h1>
										<div className="description-container">
											<p className="description text-black text-xs">
												{e?.description}
											</p>
										</div>
										<p className="text-md text-black font-normal">{parseFloat(e?.coststructure)?.toLocaleString()}</p>
										<p className="text-md text-black font-normal">{e?.date}</p>
										<p className="text-md text-black font-normal">{parseFloat(e?.potential)?.toLocaleString()}</p>
										<p className="text-md text-black font-normal">{parseFloat(e?.expectedReturn)?.toLocaleString()}</p>
										<p className="text-md text-black font-normal">{parseFloat(e?.ebitda)?.toLocaleString()}</p>
										<p className="text-md text-black font-normal">{parseFloat(e?.NPV)?.toLocaleString()}</p>
										<p className="text-md text-black font-normal">{parseFloat(e?.planned_cpex)?.toLocaleString()}</p>
										<p className="text-md text-black font-normal">{e?.risk ? parseFloat(e?.risk).toFixed(2) : 0} %</p>

										{e?.promising_case ? (
											<p className="bg-gray-400 py-1 text-center uppercase text-sm rounded-md text-white flex items-center justify-center">
												<div className='flex items-center'>
													<Icon image='award' />
													<span className="ml-2">Most promising</span>
												</div>
											</p>
										) : (
											<Button
												className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-600 focus:bg-primary-600 focus:shadow-md focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-md"
												text="Pick this one"
												small
												action={() => (e?.promising_case ? context.notification.show('This case is already promising', 'error', true) : mostPromisingCase(e?.id, false))}
												color="blue"
												rounded
											/>
										)}
										<Button
											className=" rounded bg-derisky-rose text-white px-6 pt-2.5 pb-2 text-xs font-medium uppercase  "
											text="🧠 AI Kickstart"
											small
											action={() => toggleModal()}
											color="blue"
											rounded
										/>
									</Grid>
									{product?.isTeamLead && <DropdownMenu onEdit={() => caseModal(e)} onDelete={() => deleteBusinessCaseModal(e.id)} editTitle={'Edit'} deleteTitle={'Delete'} parent={'cases'} />}

								</div>
							);
						})}
					</div>
				</Grid>
			</>}
			{/* <ChatGPT /> */}
		</Fragment>
	)
}

import {Analytics} from "analytics";
import googleTagManager from '@analytics/google-tag-manager'

export let tagManager = null;

export function initializeTagManager() {
    tagManager = Analytics({
        app: 'derisky.ai',
        plugins: [
            googleTagManager({
                containerId: 'GTM-K7C9WMDD'
            })
        ]
    });

    return tagManager;
}

export function getTagManager() {
    if (!tagManager) {
        throw new Error('Tag Manager is not initialized. Please call initializeTagManager() first.');
    }
    return tagManager;
}

export const logPageView = async (url) => {
    const manager = getTagManager();
    await manager.page({ url });
};

export default initializeTagManager;

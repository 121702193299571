import React, { useState } from 'react'
//import './styles.css'
import Nestable from 'react-nestable'
import { AiOutlineDrag, AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai'
import 'react-nestable/dist/styles/index.css'
import { TextInput, Button } from 'components/lib'
import axios from 'axios'

const Styles = {
	style: 'relative bg-[#f9fafa] flex',
	cssCenter: 'flex items-center justify-center w-[2rem] h-[100%] pointer relative',
	drag: 'absolute width-[100%]'
}

const items = [
	{
		id: 0,
		text: 'Item 1',

		children: [
			{
				id: 4,
				text: 'Item 3',

				children: [
					{
						id: 12,
						text: 'Une ressource',
						amount: 1
					},
					{
						id: 13,
						text: "La main d'œuvre",
						amount: 1
					}
				]
			}
		]
	},

	{
		id: 3,
		text: 'Item 2',

		children: [
			{
				id: 1,
				text: 'Super Ouvrage',

				children: [
					{
						id: '2-1',
						text: 'Ressource 1',
						amount: 1
					},
					{
						id: '2-2',
						text: 'Ouvrage',

						children: [
							{ id: 'toto', text: 'Ressource truc', amount: 1 },
							{ id: 'toto2', text: 'Ressource autre', amount: 1 }
						]
					}
				]
			}
		]
	}
]

const RenderItem = (props) => {
	const [learning, setLearning] = useState()
	const [isEdit, setIsEdit] = useState(false)
	let { item, index, collapseIcon, handler, depth, parent } = props
	item.text = learning ? learning : item.text

	const onUpdate = async () => {
		await axios({
			method: 'put',
			url: `/api/experiment_learning/${item?.id}`,
			data: { text: learning }
		}).then(res => {
			props?.edit(item, true)
		})
	}
	console.log('item', depth)
	return (
		<>
			<div className={Styles.style}>
				{handler}
				{collapseIcon}
				<div className="p-[1rem] flex-[1] justify-between flex">{item.text}
					{parent == 'view' ? '' : <div className="flex gap-[0.5rem]">
						<img src="/icons/pencil.svg" className=" w-[0.875] h-[1.125rem] cursor-pointer" alt='' onClick={() => { setLearning(item.text); setIsEdit(!isEdit); props?.edit(item) }} />
						<img src="/icons/basket.svg" className=" w-[0.875] h-[1.125rem] cursor-pointer" alt='' onClick={() => props.delete(item?.id)} />
					</div>}
				</div>

			</div>

			{isEdit && <div className='mt-2'>
				<TextInput
					// label='Add Learnings'
					type='text'
					value={learning}
					// className='w-96'
					required='true'
					onChange={(name, value, valid) => { setLearning(value) }}
				/>
				<div className='flex justify-end mt-2'>
					<Button
						className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] h-10"
						text={props?.isUpdate ? 'Update' : "Save"}
						color="blue"
						width='true'
						action={() => onUpdate()}
						rounded
					/>
				</div>
			</div>}
		</>
	)
}

export default function App(props) {
	const [collapseAll, setCollapseAll] = useState(false)
	const Handler = () => {
		return (
			<div className={Styles.cssCenter}>
				<div className={Styles.drag}></div>
			</div>
		)
	}
	const Collapser = ({ isCollapsed }) => {
		return <div className={Styles.cssCenter}>{isCollapsed ? <AiFillCaretRight /> : <AiFillCaretDown />}</div>
	}
	return (
		<div className="Apps">
			<Nestable maxDepth={2}
				items={props?.items}
				renderItem={(e) => <RenderItem handler={e.handler} item={e.item} collapseIcon={e.collapseIcon} edit={props.edit} delete={props.onDelete} depth={e.depth} parent={props?.parent} />}
				handler={<Handler />}
				renderCollapseIcon={({ isCollapsed }) => <Collapser isCollapsed={isCollapsed} />}
				collapsed={collapseAll}
				// confirmChange={(e)=> console.log('============', e.dragItem, e.destinationParent)}
				onChange={(items) => { props?.order(items); console.log('===========s', items.targetPath) }} />
		</div>
	)
}

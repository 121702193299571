/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment, useState } from 'react';
import { Row, Button, Grid, Badge, Testimonial } from 'components/lib';
import { Hero } from '../commit/components/hero/hero';
import { features } from '../../features';
import { Testimonialto } from "../../components/testimonialto/testimonialto";
import Spacer from "../../components/spacer/spacer";
import { Challenges } from "../commit/components/challenges/challenges";
import { BrandRow } from "../commit/components/brandrow/brandrow";
import { Funding } from "../../components/funding/funding";

export function InnovationMetrics(props) {
    const [selectedItem, selectItem] = useState(null)
    const onSelectItem = function (index) {
        selectItem(features[index]);
    }

    return (
        <>
            <Hero
                overtitle='Derisk your innovation portfolio.'
                title='📈 Increase your innovation performance. '
                tagline='Implement actionable metrics. Visualize the risk of your portfolio. Track your derisking activities over time. Compare your innovation investments. Make data-driven portolio decisions.
                 And gain back power by connecting your impact with financial goals.'
            />
            <Row color="brand">
                <h2>From ideas to data-driven portfolio decisions with derisking intelligence.</h2>

                <img className='w-full' src='/RAG.svg' alt='Retrieval Augmented Generation - Large Language Model' />
            </Row>
            <Row color="brand">
                <Grid cols='3'>
                    <div><h1 className='mt-2 mb-4'>Scrap your documentation</h1>
                        <p>Derisky's technology extracts text from various file formats, whether public or confidential. These extracted data are stored in our vector databases, which then supply our large language models (LLMs). By utilizing comprehensive R&D documentation, we generate highly sophisticated risk reports that include detailed source citations.
                        </p>
                    </div>
                    <div><h1 className='mt-2 mb-4'>Hypothesize!</h1>
                        <p>Derisky's technology extracts text from various file formats, whether public or confidential. These extracted data are stored in our vector databases, which then supply our large language models (LLMs). By utilizing comprehensive R&D documentation, we generate highly sophisticated risk reports that include detailed source citations.
                        </p>
                    </div>
                    <div><h1 className='mt-2 mb-4'>Data-driven portfolio decisions.</h1>
                        <p>Derisky's technology extracts text from various file formats, whether public or confidential. These extracted data are stored in our vector databases, which then supply our large language models (LLMs). By utilizing comprehensive R&D documentation, we generate highly sophisticated risk reports that include detailed source citations.
                        </p>
                        Based on these data, the technology kickstart your actionable derisking activities.
                        <div>
                        </div>
                    </div>
                </Grid>
            </Row>

            <Row className='bg-brand-400'>
                <Spacer height="h-[3rem]" />

                <h1 className="text-2xl my-10 text-brand-400">What innovators love about derisky.ai.</h1>
            </Row>

            <Testimonialto />

            <Spacer height="h-[3rem]" />


            <Spacer height='h-[3rem]' />
            <Fragment>

                <Row >

                    <Spacer height="h-[2rem]" />
                    <Challenges />
                </Row>
            </Fragment>
            <Spacer height="h-[4rem]" />
            <Fragment>
                <BrandRow title="How it works.">
                    <Grid cols='2'>
                        <div>
                            {
                                features && features.map((f, index) => {
                                    const isSelected = f.title === selectItem && selectedItem.title;
                                    let classNameDiv = "rounded-md px-5 text-left my-5 cursor-pointer ";
                                    classNameDiv += isSelected ? "pt-1 bg-opacity-30 shadow-lg shadow-inner bg-black hover:pt-1 hover:bg-opacity-30 hover:shadow-lg hover:shadow-inner hover:bg-black " : "pb-1 bg-opacity-30 shadow-lg bg-white";
                                    return (<div key={index} className={classNameDiv} onClick={() => onSelectItem(index)}>
                                        <h2 className="font-semibold text-lg pt-3 pb-1">
                                            {index + 1 + ". " + f.title}
                                        </h2>
                                        <p className="pb-3 text-s">{f.description}
                                            {f.badge && <Badge className="right-0 text-brand-400 uppercase bg-derisky-rose px-1 text-center mb-5 px-2" text={f.badge} />}
                                        </p>
                                    </div>);
                                })
                            }

                        </div>
                        <div>
                            {selectedItem && <>
                                <video autoPlay src={selectedItem.video} aria-description={selectedItem.title + ": " + selectedItem.description} />
                                <p>{selectedItem.description}</p>
                            </>


                            }
                        </div>
                    </Grid>
                    <Button goto='/signup' text='Start to derisk now.' className='inline-block' big />
                </BrandRow>
            </Fragment>
            <Spacer height="h-[1.125rem]" />
            <Row title='Derisk, save millions and invest like a venture capitalist.' align='center'>
                <Button goto='/signup' text='Derisk now' className='inline-block' big />
            </Row>


            <Row color="brand">
                <Testimonial className="font-size-xl"
                    text="My vision is to turn ideas into secure investments in order to conserve global resources and focus humanity's attention where real and pressing issues can be effectively addressed."
                    author="Laurens Lang"
                    image='/Add/1710842465321.jpeg'
                />
            </Row>
            <Funding />

        </>
    );
}

import { ExperimentCard } from 'components/card/projectcard'
import { Button, Card, DateInput, Grid, Loader, NumberInput, Select, Switch, TextInput, useLocation, ViewContext } from 'components/lib'
import Items from 'components/items/items'
import Spacer from 'components/spacer/spacer'
import { CSSTransition } from 'react-transition-group'
import { useContext, useEffect, useState } from 'react'
import '../../components/modal/modal.scss'
import axios from 'axios'
import ConfidenceListModal from './confidencelistmodal'
import ExperimentTypeModel from './ExperimentTypeModel'

export const UpdateExperiment = (props) => {
	const context = useContext(ViewContext)
	const router = useLocation()
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [cost, setCost] = useState('')
	const [experimentType, setExperimentType] = useState('')
	const [status, setStatus] = useState('Scheduled')
	const [learning, setLearning] = useState('')
	const [insight, setInsight] = useState(false)
	const [realEndDate, setRealEndDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [openModel, setOpenModel] = useState(false)
	const [validExperimentType, setValidExperimentType] = useState(true)
	const [validName, setValidName] = useState(true)
	const [validCost, setValidCost] = useState(true)
	const [validDescription, setValidDescription] = useState(true)
	const [validEndDate, setValidEndDate] = useState(true)
	const [itemsOrders, setItemOrder] = useState('')
	const [isDraged, setIsDraged] = useState(false)
	const [draggedItems, setDraggedItems] = useState([])
	const [isEdit, setIsEdit] = useState('')
	const [experimentTypeOptions, setExperimentTypeOptions] = useState([])
	const [experimentTypeModels, setExperimentTyeModels] = useState(false)
	const [experimetnObject, setExperimentObject] = useState()
	const [loading, setLoading] = useState(false)
	let id = router.pathname.replace('/hypothesis/', '')


	useEffect(() => {
		getAllExperimentTypes()
		if (props?.isUpdate) {
			getExperiment(props?.isUpdate?.id)
		}
	}, [props?.isUpdate])

	const getExperiment = async (id) => {
		setLoading(true)
		await axios({
			method: 'get',
			url: `/api/product_experiment/${id}`,
		}).then(res => {
			if (res?.data) {
				let _data = res?.data?.data
				console.log('=======res?.data', res?.data)
				setName(_data?.name)
				setDescription(_data?.description)
				setCost(_data?.cost)
				setExperimentType(_data?.experiment_type)
				setExperimentObject({ 'Experiment Type': _data?.experiment_type })
				setEndDate(_data?.end_date)
				setStatus(_data?.status)
				setRealEndDate(_data?.real_end_date)
				setInsight(_data?.insight == 1 ? true : false)

				let arr = []
				let Arr = []

				_data?.learning?.length &&
					_data?.learning?.map((e) => {
						// arr.push(e)
						if (e?.children?.length) {
							e?.children?.map((res) => {
								if (res?.children?.length) {
									res?.children?.map((child) => {
										if (child.children?.length) {
											arr.push(...child.children)
										}
									})
									res?.children?.length && arr.push(...res?.children)
								}
							})
							Arr.push(...e?.children)
						} else {
							arr.push(e)
						}
					})

				// console.log('arr=========', arr, Arr)
				let data = arr.concat(Arr)
				let newArr = arr.concat(Arr)

				for (let h = 0; h < data.length; h++) {
					var curItem = data[h].id
					var foundCount = 0
					// search array for item
					for (let i = 0; i < data.length; i++) {
						if (data[i].id == data[h].id) foundCount++
					}
					if (foundCount > 1) {
						// remove repeated item from new array
						for (let j = 0; j < newArr.length; j++) {
							if (newArr[j].id == curItem) {
								newArr.splice(j, 1)
								j--
							}
						}
					}
				}

				setItemOrder(newArr)
				setLoading(false)
			}
		}).catch(err => {
			setLoading(false)
		})
	}

	const now = new Date()

	const date = {
		year: now.getFullYear(),
		month: now.getMonth() + 1,
		day: now.getDate()
	}
	function formatDateString(d) {
		return `${d.year}-${d.month < 10 ? '0' + d.month : d.month}-${d.day < 10 ? '0' + d.day : d.day}`
	}

	const addExperiments = async (isFinished, value) => {
		if (status == 'Finished' && isFinished == false && handleValidation() && props?.isUpdate?.status != 'Finished') {
			setOpenModel(true)
		} else {
			if (handleValidation()) {
				await axios({
					method: props?.isUpdate ? 'put' : 'post',
					url: props?.isUpdate ? `/api/product_experiment/${props?.isUpdate?.id}` : '/api/product_experiment',
					data: {
						cost: cost,
						id: props?.isUpdate ? undefined : { product_id: id, hypothesis_id: props?.hypothesis?.id },
						name: name,
						description,
						status,
						end_date: endDate,
						start_date: formatDateString(date),
						experiment_type: experimentType,
						risk_reduction: props?.isUpdate?.risk_reduction ? props?.isUpdate?.risk_reduction : 0
						// draggedItems: draggedItems
					}
				})
					.then(async (res) => {
						if (res.data) {
							if (status == 'Finished' && isEdit == '' && isDraged == false && learning) {
								await axios({
									method: 'post',
									url: `/api/experiment_learning`,
									data: { product_id: id, experiment_id: props?.isUpdate ? props?.isUpdate?.id : res.data?.data?.id, text: learning, children: isDraged && itemsOrders }
								})
							}

							if (status == 'Finished' && (isEdit || isDraged)) {
								await axios({
									method: 'put',
									url: `/api/experiment_learning/${itemsOrders[0]?.id}`,
									data: { children: itemsOrders }
								})
							}

							context.notification.show(res.data.message, 'success', true)
							props?.callBack()
							context.modal.hide(true)
						}
					})
					.catch((e) => {
						if (e?.response?.data?.message) {
							setOpenModel(false)
							// context.modal.hide(true)
							// context.notification.show(e?.response?.data?.message, 'error', true)
						}
					})
			}
		}
	}

	const handleValidation = () => {
		let isValid = true
		if (experimentType?.trim().length === 0) {
			isValid = false
			setValidExperimentType(false)
		} else {
			setValidExperimentType(true)
		}

		if (description?.trim().length === 0) {
			isValid = false
			setValidDescription(false)
		} else {
			setValidDescription(true)
		}

		if (name?.trim().length === 0) {
			isValid = false
			setValidName(false)
		} else {
			setValidName(true)
		}

		if (endDate?.trim().length === 0) {
			isValid = false
			setValidEndDate(false)
		} else {
			setValidEndDate(true)
		}

		if (cost?.trim().length === 0) {
			isValid = false
			setValidCost(false)
		} else {
			setValidCost(true)
		}

		// if (status == 'Finished' && learning.trim().length === 0) {
		// 	isValid = false;
		// 	setValidLearning(false)
		// } else {
		// 	setValidLearning(true)
		// }

		return isValid
	}

	const deleteLearning = async (id) => {
		try {
			// setLoading(true)
			const res = await axios({
				method: 'Delete',
				url: `/api/experiment_learning/${id}`
			})
			if (res.data) {
				// setLoading(false)
				if (res.data.message) context.notification.show(res.data.message, 'success', true)
				props?.callBack()
				context.modal.hide(true)
			}
		} catch (error) {
			// setLoading(false)
			context.handleError(error)
		}
	}

	const getAllExperimentTypes = async (value) => {
		await axios({
			method: 'get',
			url: `/api/experiment_type`,
			data: { query: value }
		})
			.then((res) => {
				if (res.data) {
					let arr = []
					if (res.data.data?.length) {
						res.data.data?.map((data) => {
							arr.push({ label: data?.experiment_type, value: data?.id })
						})
					}
					setExperimentTypeOptions(arr)
				}
			})
			.catch((err) => { })
	}

	return (
		<>
			<CSSTransition in appear timeout={0} classNames="modal">

				<div className="modal" onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>
					{loading && <Loader />}
					<div className={`modal-content-experiment ${openModel == true ? '' : 'overflow-auto'}`}>
						<h1 className="text-[1.125rem]  leading-[1.375rem]">Experiment</h1>
						<Spacer height="h-[1.125rem]" />
						<h1 className="text-[0.875rem] leading-[1.125rem] uppercase ">HYPOTHESIS</h1>
						{/* <Spacer height="h-[1.125rem]" /> */}
						<ExperimentCard hypothesis={props?.hypothesis} />


						<Card>
							<Grid cols={1}>


								<TextInput
									onClick={() => setExperimentTyeModels(true)}
									label="Experiment Type"
									type="text"
									value={experimentType}
									valid={experimentType ? true : validExperimentType}
									required="true"
								// onChange={(name, value, valid) => {
								// 	setName(value)
								// }}
								/>

								<TextInput
									label="Name"
									type="text"
									value={name}
									max={256}
									valid={name ? true : validName}
									required="true"
									onChange={(name, value, valid) => {
										setName(value)
									}}
								/>

								<TextInput
									label=" Description"
									type="textarea"
									value={description}
									required="true"
									valid={description ? true : validDescription}
									onChange={(name, value, valid) => {
										setDescription(value)
									}}
								/>

								<NumberInput
									label=" Cost"
									type="number"
									value={cost}
									valid={cost ? true : validCost}
									required="true"
									min={0}
									onChange={(name, value, valid) => {
										setCost(value)
									}}
								/>

								<div className="flex justify-between gap-[1.375rem] ">
									<Button
										className={`${status == 'Scheduled' ? 'bg-slate-300' : 'bg-transparent'
											} w-64 inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  hover:text-white hover:border-slate-500 hover:bg-slate-500`}
										medium
										text="SCHEDULED  📅"
										rounded
										action={() => setStatus('Scheduled')}
									// outline
									/>

									<Button
										className={`${status == 'Ongoing' ? 'bg-slate-300' : 'bg-transparent'
											} w-64 inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  hover:text-white hover:border-slate-500 hover:bg-slate-500`}
										medium
										text="ONGOING   🚧"
										rounded
										action={() => setStatus('Ongoing')}
									// outline
									/>
									{/* <Button
									className={` ${
										status == 'Finished' ? 'bg-slate-300' : 'bg-transparent'
									} w-64 inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  hover:text-white hover:border-slate-500 hover:bg-slate-500 `}
									medium
									text="FINSIHED   🏁"
									rounded
									action={() => setStatus('Finished')}
									// outline
								/> */}
								</div>
							</Grid>
							<DateInput
								label=" Planned end date"
								type="date"
								value={endDate}
								required="true"
								min={new Date()}
								valid={endDate ? true : validEndDate}
								onChange={(name, value, valid) => {
									setEndDate(value)
								}}
							/>

							{/* {status == 'Finished' && (
							<DateInput
								label=" Real end date"
								type="date"
								value={realEndDate}
								required="true"
								valid={status == 'Finished' && realEndDate ? true : validRealEndDate}
								onChange={(name, value, valid) => {
									setRealEndDate(value)
								}}
							/>
						)}

						{status == 'Finished' ? (
							<>
								<div className="flex mb-3">
									<Switch label="Insight" required hypothesis={true} default={insight} onChange={(name, value, valid) => setInsight(value)} />
								</div>
								<TextInput
									label="Add Learnings"
									type="text"
									value={learning}
									required="true"
									valid={status == 'Finished' && learning ? true : validLearning}
									onChange={(name, value, valid) => {
										setLearning(value)
									}}
								/>
							</>
						) : (
							''
						)} */}
						</Card>

						{props?.isUpdate?.learning?.length && status == 'Finished' ? (
							<>
								<Spacer height="h-[1.125rem]" />
								<Card>
									<Items
										items={itemsOrders ? itemsOrders : props?.isUpdate?.learning}
										order={(e) => {
											setIsDraged(true)
											setItemOrder(e?.items)
											draggedItems.push(e?.dragItem)
										}}
										edit={(e, val) => {
											if (val == true) {
												props?.callBack()
												context.modal.hide(true)
												setIsEdit(val)
											} else {
											}
										}}
										onDelete={(e) => {
											deleteLearning(e)
										}}
									/>
								</Card>
							</>
						) : (
							''
						)}

						<Spacer height="h-[1.125rem]" />
						<div className="flex justify-between">
							<div></div>
							<div className="flex gap-[0.5rem]">
								<Button className="inline-block rounded " text={'Close'} color="red" width="true" action={() => context.modal.hide(true)} outline />
								<Button
									className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-[0.875]"
									text={props?.isUpdate ? 'Update' : 'Save'}
									color="blue"
									width="true"
									action={() => addExperiments(false)}
									rounded
								/>
							</div>
						</div>

						<Spacer height="h-[1.125rem]" />
						{openModel && (
							<ConfidenceListModal
								onClose={() => {
									setOpenModel(false)
								}}
								confidence={(e) => {
									addExperiments(true, e)
								}}
							/>
						)}
					</div>
				</div>
			</CSSTransition>
			{experimentTypeModels && (
				<ExperimentTypeModel
					onClose={() => setExperimentTyeModels(false)}
					setectedValue={experimetnObject}
					onSelect={(e) => {
						setExperimentObject(e)
						setExperimentType(e['Experiment Type'])
						setExperimentTyeModels(false)
					}}
				/>
			)}
		</>
	)
}

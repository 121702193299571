const Style = {
	wrapper: 'h-24',
	nav: 'absolute top-1/2 -translate-y-1/2 right-0 flex items-center',
	logo: 'absolute h-full p-3 top-1/2 -translate-y-1/2',
	transparent: 'bg-none bg-transparent',
	color: 'bg-brand-400',
	link: 'mr-5 last:mr-0 no-underline uppercase text-xs sm:text-sm md:text-md hidden sm:block',
	button: 'xs:p-2 xs:px-5 text-md bg-derisky-rose '
}

export default Style

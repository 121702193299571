/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import { Fragment, useContext } from 'react'
import { AuthContext, Logo, Link, Button, Content, ClassHelper } from 'components/lib'
import Style from './home.tailwind.js'
import Header from 'views/home/components/Header.jsx'

export function HomeNav(props) {
	// context
	const context = useContext(AuthContext)

	const css = ClassHelper(Style, {
		wrapper: true,
		color: !props.transparent,
		transparent: props.transparent
	})

	return (
		<section >
			<Header />
			{/* <Content>
				<Logo className={Style.logo} />
				<nav className={Style.nav}>
					<Link url="/pricing" text="Pricing" className={Style.link} color="white" />
					<Link url='/mba' text='Derisky MBA' className={Style.link} color="white" />
					{context.user?.token ? (
						<Button big goto="/" text="Dashboard" className={Style.button} />
					) : (
						<Fragment>
							<Link url="/signin" text="Sign In" className={Style.link} color="white" />
							<Button small goto="/signup" text="Sign Up" color='bg-derisky-rose' className="text-md uppercase inline-block bg-derisky-rose" />
						</Fragment>
					)}
				</nav>
			</Content> */}
		</section>
	)
}

/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment, useState } from 'react';
import {Row, Button, Testimonial, Loader, Logo, Grid, Badge, Modal, Icon} from 'components/lib';
import Spacer from '../../components/spacer/spacer';
import {BrandRow} from "../commit/components/brandrow/brandrow";
import {Hero} from "components/lib";
import {Testimonialto} from "../../components/testimonialto/testimonialto";
import Popup from "reactjs-popup";
import {Funding} from "../../components/funding/funding";
import {features} from "features";

const PopupModal = ({url}) => {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    React.useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 10000)
    }, [setOpen])
    if (!open) return null // also this line is useless, since you're using the `open` prop of the Popup component

    return (
        <Popup open={open}
               trigger={<button className="m-auto">Open Modal</button>}
               modal
        >
            {close => (
                <div className="modal text-center full-width">
                    <div className="m-auto text-center max-w-md mt-8 full-width">
                        <a className="inline" href="/mba">
                            <img src='/Add/popup.png'  className="inline rounded-md" alt='Derisky Innovation MBA - Derisking Intelligence.'/>
                        </a>
                        <button
                            className="text-brand-400 text-sm mr-2 p-0.5 rounded-b-md  bg-derisky-rose flex items-center pl-16 text-center gap-4"
                            onClick={() => {
                                console.log('modal closed ');
                                closeModal()
                            }}
                        >
                            <Icon image='x'/> Close
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
};



export function RAGBoilerplate(props) {
    const user = JSON.parse(localStorage.getItem('user'))


    const [selectedItem, selectItem] = useState(null);

    const onSelectItem = function (index) {
        selectItem(features[index]);
    }
    return (
        props.loading ? <Loader /> :
            user ? <Loader /> : <Fragment>
                <Hero heroStyle={true}
                      overtitle='Retrieval Augmented Generation.'
                      title='Build a RAG at warp speed with a RAG boilerplate.'
                      tagline='Build a Generative AI RAG at warp speed with the original RAG boilerplate.'
                      badge='🧠 Generative AI'
                      showLaurens={1}
                />
                <PopupModal />
                <Row color="brand">
                    <h1 className='mt-2 mb-4 text-brand-400'>
                        Make data-driven portofolio decisions.
                    </h1>
                    <img className='w-full p-16' src='/Add/RAG.svg' alt='Retrieval Augmented Generation - Large Language Model'/>


                    <div className='flex  text-justify gap-10 '>
                        <div className='flex-1 border-t-4 border-derisky-lila shadow-xl p-5 hover:shadow-inner hover:border-derisky-rose'>
                            <h4 className='my-3 flex gap-2'>🧠 Hypothesize with AI</h4>
                            Derisky's optimized corrective RAG system extracts text from your product documentation, stores it in our vector databases, and supplies it to our large language models.
                        </div>
                        <div className='flex-1 border-t-4 border-derisky-lila shadow-xl p-5 hover:shadow-inner hover:border-derisky-rose'>
                            <h4 className='my-3 flex gap-2'>📊 Innovation metrics</h4>

                            To improve, you must measure. Boost your innovation performance by continuously tracking and visualizing your derisking activities.                               </div>
                        <div className='flex-1 border-t-4 border-derisky-lila shadow-xl p-5 hover:shadow-inner hover:border-derisky-rose'>
                            <h4 className='my-3'>📈 Portfolio decision</h4>
                            With the data from each product, you can derive a portfolio view and enables to
                            make data-driven portfolio decisions like a VC.
                        </div>
                    </div>
                </Row>

                <Row className='bg-brand-400'>
                    <Spacer height="h-[3rem]" />
                    <h1 className="text-2xl my-10 text-brand-400">What innovators love about derisky.ai.</h1>
                </Row>

                <Testimonialto />

                <Spacer height="h-[3rem]" />


                <Spacer height='h-[3rem]' />
                <Fragment>

                    <Row >

                        <Spacer height="h-[2rem]" />
                    </Row>
                </Fragment>
                <Spacer height="h-[4rem]" />
                <Fragment>
                    <BrandRow title="How it works.">
                        <Grid cols='2'>
                            <div>
                                {
                                    features && features.map((f, index) => {
                                        const isSelected = f.title === selectItem && selectedItem.title;
                                        let classNameDiv = "rounded-md px-5 text-left my-5 cursor-pointer ";
                                        classNameDiv += isSelected ? "pt-1 bg-opacity-30 shadow-lg shadow-inner bg-black hover:pt-1 hover:bg-opacity-30 hover:shadow-lg hover:shadow-inner hover:bg-black " : "pb-1 bg-opacity-30 shadow-lg bg-white";
                                        return (<div key={index} className={classNameDiv} onClick={() => onSelectItem(index)}>
                                            <h2 className="font-semibold text-lg pt-3 pb-1">
                                                {index + 1 + ". " + f.title}
                                            </h2>
                                            <p className="pb-3 text-s">{f.description}
                                                {f.badge && <Badge className="right-0 text-brand-400 uppercase bg-derisky-rose px-1 text-center mb-5 px-2" text={f.badge} />}
                                            </p>
                                        </div>);
                                    })
                                }

                            </div>
                            <div>
                                { selectedItem && <>
                                    <video autoPlay src={selectedItem.video} aria-description={selectedItem.title + ": " + selectedItem.description} />
                                    <p>{selectedItem.description}</p>
                                </>


                                }
                            </div>
                        </Grid>
                        <Button goto='/signup' text='Start to derisk now.' className='inline-block' big />
                    </BrandRow>
                </Fragment>
                <Spacer height="h-[1.125rem]" />
                <Row title='Derisk, save millions and invest like a venture capitalist.' align='center'>
                    <Button goto='/signup' text='Derisk now' className='inline-block' big />
                </Row>


                <Row color="brand">
                    <Testimonial className="font-size-xl"
                                 text="My vision is to turn ideas into secure investments in order to conserve global resources and focus humanity's attention where real and pressing issues can be effectively addressed."
                                 author="Laurens Lang"
                                 image='/Add/1710842465321.jpeg'
                    />
                </Row>
                <Funding />
            </Fragment>
    );
}

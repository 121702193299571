import axios from 'axios'
import { LogoCard } from 'components/card/projectcard'
import { Loader, SubNavbar, Table, useLocation, ViewContext } from 'components/lib'
import Spacer from 'components/spacer/spacer'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import BusinessDetailsModel from './BusinessDetailsModel'
import FocusComponent from './megaWizard'

export const BusinessModels = () => {
	const context = useContext(ViewContext)
	const router = useLocation()
	const [product, setProduct] = useState()
	let id = router.pathname.replace('/business-model/', '')
	const [loading, setLoading] = useState(false)
	const [allModels, setAllModels] = useState([])
	const user = JSON.parse(localStorage.getItem('user'))
	const [models, setModels] = useState([])
	const [detailsModel, setDetailsModel] = useState(false)
	const [businessDetails, setBusienssDetails] = useState(false)
	const [openWizard, setOpenWizard] = useState(false)

	useEffect(() => {
		getProductHypothesis()

	}, [])

	const getProductHypothesis = async () => {
		await getProduct()
		await getBusinessModel(id)
	}

	const getProduct = async () => {
		if (id) {
			setLoading(true)
			await axios({
				method: 'get',
				url: `/api/product/${id}`
			})
				.then((res) => {
					setLoading(false)
					if (res.data) {
						setProduct(res.data.data)
					}
				})
				.catch((err) => {
					setLoading(false)
					console.log('err', err)
				})
		}
	}

	const getBusinessModel = async (id) => {
		try {
			setLoading(true)
			const res = await axios({
				method: 'get',
				url: `/api/all_business_model/${id}`
			})
			if (res.data) {
				setLoading(false)
				setAllModels(res.data?.data)
			}
		} catch (error) {
			setLoading(false)
			context.handleError(error)
		}
	}

	useEffect(() => {
		if (allModels) {
			let arr = []
			allModels.map(res => {
				arr.push({
					id: res?.id,
					['Core Business']: res?.core_business?.substr(0, 50),
					['Critical Problems']: res?.business_critical_problems?.substr(0, 50),
					['Customer Description']: res?.customer_description_and_buying_decisions?.substr(0, 50),
					['Trends']: res?.trends_and_trigger_events?.substr(0, 50),
					['Features']: res?.features_and_capabilities?.substr(0, 50),
					['Prospect Alternatives']: res?.prospect_alternative_options?.substr(0, 50),
					['Problem']: res?.problem_and_solution_category?.substr(0, 50),
					['Costs Of Sale']: res?.costs_of_sale_and_sources_of_revenue?.substr(0, 50),
					['Key Metrics']: res?.key_metrics_to_manage_business?.substr(0, 50),
					['Unique Value']: res?.unique_value_proposition?.substr(0, 50),
					['Market Strategy']: res?.go_to_market_strategy?.substr(0, 50),
					['Additional Notes']: res?.additional_notes?.substr(0, 50)
				});
			});

			setModels(arr)
		}
	}, [allModels])

	return (
		<Fragment>
			{loading && <Loader fullScreen />}
			{product ? <LogoCard title={product?.name} subtitle={product?.description} logo={product?.logo} wizardClick={() => setOpenWizard(!openWizard)} /> : ''}
			<Spacer height="h-[1.188rem]" />
			<SubNavbar active="business-model" id={id} />
			<Spacer height="h-[1.125rem]" />
			{openWizard ? <FocusComponent /> : <>

				<Spacer height="h-[1.125rem]" />
				<div className='overflow-hidden'>
					<Table
						className="restrict-width"
						data={models}
						loading={false}
						selectedValue={(e) => {
							console.log('=====eee', e)
							setBusienssDetails(allModels?.find(re => re?.id == e?.id))
							setDetailsModel(true)

						}}
					/>
				</div>
				{detailsModel && <BusinessDetailsModel businessDetails={businessDetails} onClose={() => { setDetailsModel(false); setBusienssDetails('') }} />}
			</>}
		</Fragment>
	)
}

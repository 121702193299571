/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React from 'react'
import { Card, Chart, Grid, Animate, Feedback, useAPI, Loader, useLocation } from 'components/lib'
import { DashboardCard } from 'components/card/projectcard'
import { useEffect } from 'react'
import { useState } from 'react'

export function Dashboard(props) {
	const router = useLocation()
	const [dashboardData, setDashboardData] = useState()
	const [riskReduction, setRiskReduction] = useState([])
	// const stats = useAPI('/api/demo/stats')
	// const progress = useAPI('/api/demo/progress')
	// const table = useAPI('/api/demo/users/list')
	// const userChart = useAPI('/api/demo/users/types')
	let id = router.pathname.replace('/dashboard/', '') || router.pathname.replace('/dashboard', '')
	const revenueChart = useAPI('/api/demo/revenue')
	const dasboardStats = useAPI(`/api/all_project_stats/${id}`, 'get')

	function roundNumber(labelValue) {
		// Nine Zeroes for Billions
		return Math.abs(Number(labelValue)) >= 1.0e9
			? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
			: // Six Zeroes for Millions
			Math.abs(Number(labelValue)) >= 1.0e6
				? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
				: // Three Zeroes for Thousands
				Math.abs(Number(labelValue)) >= 1.0e3
					? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
					: Math.abs(Number(labelValue))
	}
	useEffect(() => {
		if (dasboardStats?.data) {
			let statsData = []
			setDashboardData(dasboardStats?.data)
			for (let i = 0; i < dasboardStats?.data?.projects?.length; i++) {
				statsData.push(dasboardStats?.data?.projects[i]?.expectedReturn)
			}
			// dashboardData?.projects?.map((e) => statsData.push(roundNumber(e?.expectedReturn)))
			setRiskReduction(statsData)
		}
	}, [dasboardStats?.data])
	// console.log('dasboardStats', riskReduction, dasboardStats)

	const maxExpectedReturn = dashboardData?.projects?.length && Math.max(...dashboardData?.projects?.map((item) => item.expectedReturn))
	const maxCostStructure = dashboardData?.projects?.length && Math.max(...dashboardData?.projects?.map((item) => item.costStructure))
	const maxRiskStats = dashboardData?.projects?.length && Math.max(...dashboardData?.projects?.map((item) => item.riskStats))

	const bubbleChartData = dashboardData?.projects?.map((item) => ({
		x: item.riskStats,
		y: item.expectedReturn,
		r: (item.riskStats / maxRiskStats) * 10,
		id: item.id,
		label: item.name,
		riskStats: item.riskStats
	}))

	const options = {
		scales: {
			x: {
				min: 1, // Set the minimum value of the x-axis to 1
				max: 100 // Set the maximum value of the x-axis to 100
			}
		}
	}
	const data = {
		labels: dashboardData?.projects?.map((e) => e?.riskStats?.toFixed(2) + ' %'),
		datasets: [
			{
				data: bubbleChartData,
				label: 'Expected Return:',
				allData: dashboardData?.projects,
				fill: false,
				showLine: false,
				// backgroundColor: 'rgb(54, 162, 235)',
				// borderColor: 'rgb(54, 162, 235)'
				// backgroundColor: 'red',
				// borderColor: 'green',
				backgroundColor: 'rgba(75, 192, 192, 0.4)',
				borderColor: 'rgba(75, 192, 192, 1)',
				pointStyle: 'circle',
				pointRadius: 6,
				pointHoverRadius: 8
			}
		],
		tooltips: {
			displayColors: false,
			callbacks: {
				title: function (tooltipItem, data) {
					tooltipItem[0].label = tooltipItem[0]?.dataset?.allData[tooltipItem[0]?.dataIndex]?.name
					return tooltipItem[0].label
				},
				label: function (tooltipItem, data) {
					let expectedReturn = roundNumber(tooltipItem?.dataset?.allData[tooltipItem?.dataIndex].expectedReturn)
					console.log('expectedReturn======', expectedReturn, tooltipItem)
					return 'Expected Return: ' + expectedReturn
				},
				afterLabel: function (tooltipItem, data) {
					let costStructure = roundNumber(tooltipItem?.dataset?.allData[tooltipItem?.dataIndex]?.costStructure)
					let expCost = roundNumber(tooltipItem?.dataset?.allData[tooltipItem?.dataIndex]?.experimentCost)
					// eslint-disable-next-line no-useless-concat
					return 'Cost Structure: ' + costStructure + '\n' + 'Months since started: ' + '---' + '\n' + 'Exp. Cost: ' + expCost
				}
			}
		},
		options
	}
	return (
		<Animate type="pop">
			{dasboardStats?.loading && <Loader />}

			<>
				<div className="flex justify-between gap-[4rem] my-8">
					<div className='flex '>
						<img
							src={dashboardData?.data.teamImage ? process.env.REACT_APP_IMAGE_URL + dashboardData?.data : '/teamwork.jpg'}
							className='rounded-md h-20 border border-brand-400 shadow-md border-opacity-50'
						/>
						<div className=' h-full ml-4 items-center  w-full'>
							<h1>{dashboardData?.data?.teamName}</h1>
							<p className="text-xs self-center hidden xl:block">{dashboardData?.data?.description}</p>

						</div>
					</div>
				</div>
			</>
			<Card name="revenues" title="Revenue">
				{/* <Chart data={data} type="sparkline" /> */}
				{dasboardStats?.data && <Chart type="bubble" loading={revenueChart.loading} data={data} color={['red', 'blue']} />}
			</Card>

			<Grid cols={3}>
				<DashboardCard title={dashboardData?.data?.projectRisk ? dashboardData?.data?.projectRisk?.toFixed(2) + ' %' : 0 + ' %'} subtitle="Overall Risk Level" component="large" />
				<DashboardCard title={dashboardData?.data?.expected_Return ? roundNumber(dashboardData?.data?.expected_Return) : 0} subtitle="Overall Expected Return" component="large" />
				<DashboardCard title={dashboardData?.data?.experiment_costs ? roundNumber(dashboardData?.data?.experiment_costs) : 0} subtitle="Spent on experiments" component="large" />
				<DashboardCard title={dashboardData?.data?.riskReduction ? dashboardData?.data?.riskReduction?.toFixed(2) + ' %' : 0 + ' %'} subtitle="Risk Reduction" component="large" />

				<DashboardCard title={dashboardData?.data?.cost_structure ? roundNumber(dashboardData?.data?.cost_structure) : 0} subtitle="Cost Structure" component="large" />
				<DashboardCard title={dashboardData?.data?.activeProducts ? roundNumber(dashboardData?.data?.activeProducts) : 0} subtitle="Active Products" component="large" />
				<DashboardCard title={dashboardData?.data?.hypothesis ? roundNumber(dashboardData?.data?.hypothesis) : 0} subtitle="Open Hypothesis" component="large" />
				<DashboardCard title={dashboardData?.data?.killed_hypothesis ? dashboardData?.data?.killed_hypothesis : 0} subtitle="Killed Hypothesis" component="large" />
				<DashboardCard title={dashboardData?.data?.finished_hypothesis ? dashboardData?.data?.finished_hypothesis : 0} subtitle="Finished Experiments" component="large" />
				<DashboardCard title={dashboardData?.data?.insightful_hypothesis ? dashboardData?.data?.insightful_hypothesis : 0} subtitle="Insightful Experiments" component="large" />
				<DashboardCard title={dashboardData?.data?.unInsightful_hypothesis ? dashboardData?.data?.unInsightful_hypothesis : 0} subtitle="Fruitless Experiments" component="large" />
				<DashboardCard title={dashboardData?.data?.experiments ? roundNumber(dashboardData?.data?.experiments) : 0} subtitle="Experiments" component="large" />
				<DashboardCard title={dashboardData?.data?.savedMoney ? roundNumber(dashboardData?.data?.savedMoney) : 0} subtitle="Saved Money" component="large" />

				{/* <DashboardCard title={dashboardData?.data?.ebitida ? dashboardData?.data?.ebitida : 0} subtitle="EBITDA" component="large" /> */}

				{/* <DashboardCard title="65%" subtitle="Hello World" component="large" />
				<DashboardCard title="1 B" subtitle="Overall expected return" component="large" />
				<div>
					<Grid cols={2}>
						<DashboardCard title="5" component="medium" />
						<DashboardCard title="98" component="medium" />
					</Grid>
				</div>

				<div>
					<Grid cols={2}>
						<DashboardCard title="5" component="medium" />
						<DashboardCard title="98" component="medium" />
					</Grid>
				</div> */}
			</Grid>

			<Feedback />
		</Animate>
	)
}

import {Contact} from 'views/website/contact'
import {Pricing} from 'views/website/pricing'
import {Terms} from 'views/website/terms'
import {Privacy} from 'views/website/privacy'
import {MBA} from "../views/university/University";
import {Exam} from "../views/university/Exam";
import {Certificate} from "../views/university/Certificate";
import {Graduation} from "../views/university/Graduation";
import {InnovationMetrics} from "../views/InnovationMetrics/InnovationMetrics";
const user = JSON.parse(localStorage.getItem('user'))

const Routes = [
	{
		path: '/contact',
		view: Contact,
		layout: 'home',
		title: 'Contact'
	},
	{
		path: '/pricing',
		view: Pricing,
		layout: 'home',
		title: 'Pricing'
	},
	{
		path: '/privacy',
		view: Privacy,
		layout: 'home',
		title: 'Privacy Policy'
	},
	{
		path: '/terms',
		view: Terms,
		layout: 'home',
		title: 'Terms & Conditions'
	},
	{
		path: '/mba',
		view: MBA,
		layout: user ? 'metrix' : 'landing',
		title: 'Derisky.ai University'
	},
	{
		path: '/innovation-metrics',
		view: InnovationMetrics,
		layout: 'home',
		title: 'Innovation Metrics - Derisky.ai'
	},
	{
		path: '/exam',
		view: Exam,
		layout: 'home',
		title: 'Derisky.ai University'
	},
	{
		path: '/certificate',
		view: Certificate,
		layout: 'home',
		title: 'Derisky.ai University'
	},
	{
		path: '/Graduation',
		view: Graduation,
		layout: 'home',
		title: 'Derisky.ai University'
	}
]

export default Routes

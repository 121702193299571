import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const AssumptionMapping = ({ unknown, importance, setUnknown, setImportance }) => {
    const [data, setData] = useState([
        { id: 1, x: unknown, y: importance, label: 'Assumption' },
    ]);
    const svgRef = useRef();
    const width = 500;
    const height = 500;
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };

    // Update data state when unknown or importance props change
    useEffect(() => {
        setData([{ id: 1, x: unknown, y: importance, label: 'Assumption' }]);
    }, [unknown, importance]);

    useEffect(() => {
        const svg = d3.select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleLinear().domain([0, 100]).range([0, width]);
        const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

        const xAxis = d3.axisBottom(xScale).ticks(5);
        const yAxis = d3.axisLeft(yScale).ticks(5);

        svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)
            .append('text')
            .attr('x', width)
            .attr('y', -10)
            .style('text-anchor', 'end')
            .style('fill', 'grey')
            .style('font-size', '16px')
            .text('evidence');

        svg.append('g')
            .call(yAxis)
            .append('text')
            .attr('x', -10)
            .attr('y', -10)
            .style('text-anchor', 'end')
            .style('fill', 'grey')
            .style('font-size', '16px')
            .text('important');

        svg.append('text')
            .attr('x', width)
            .attr('y', height + 40)
            .style('text-anchor', 'end')
            .style('fill', 'black')
            .style('font-size', '16px')
            .text('no evidence');

        svg.append('text')
            .attr('x', 0)
            .attr('y', height + 40)
            .style('text-anchor', 'start')
            .style('fill', 'black')
            .style('font-size', '16px')
            .text('unimportant');

        const quadrants = [
            { x: width / 2, y: 0, width: width / 2, height: height / 2, color: 'red', label: 'Evaluate' },
            { x: width / 2, y: height / 2, width: width / 2, height: height / 2, color: 'orange', label: 'Generate' },
            { x: 0, y: 0, width: width / 2, height: height / 2, color: 'green', label: 'Plan' },
            { x: 0, y: height / 2, width: width / 2, height: height / 2, color: 'blue', label: 'Defer' },
        ];

        svg.selectAll('rect')
            .data(quadrants)
            .enter()
            .append('rect')
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .attr('width', d => d.width)
            .attr('height', d => d.height)
            .attr('fill', d => d.color)
            .attr('opacity', 0.2);

        svg.selectAll('.quad-label')
            .data(quadrants)
            .enter()
            .append('text')
            .attr('x', d => d.x + d.width / 2)
            .attr('y', d => d.y + d.height / 2)
            .attr('text-anchor', 'middle')
            .text(d => d.label)
            .style('fill', 'grey')
            .style('font-size', '16px')
            .attr('opacity', 0.2);

        const drag = d3.drag()
            .on('start', (event, d) => {
                d3.select(event.sourceEvent.target).raise().attr('stroke', 'black');
            })
            .on('drag', (event, d) => {
                const [xPos, yPos] = d3.pointer(event, svg.node());

                const updatedData = data.map(point => {
                    if (point.id === d.id) {
                        const x = xScale.invert(xPos);
                        const y = yScale.invert(yPos);
                        return { ...point, x: x, y: y };
                    }
                    return point;
                });

                setData(updatedData);
                setUnknown(updatedData[0].x); // Assuming there is only one point
                setImportance(updatedData[0].y); // Assuming there is only one point
            })
            .on('end', (event, d) => {
                d3.select(event.sourceEvent.target).attr('stroke', null);
            });

        const updateChart = () => {
            svg.selectAll('circle').remove();
            svg.selectAll('text.label').remove();

            svg.selectAll('circle')
                .data(data)
                .enter()
                .append('circle')
                .attr('cx', d => xScale(d.x))
                .attr('cy', d => yScale(d.y))
                .attr('r', 5)
                .attr('fill', 'black')
                .call(drag);

            svg.selectAll('text.label')
                .data(data)
                .enter()
                .append('text')
                .attr('class', 'label')
                .attr('x', d => xScale(d.x) + 15)
                .attr('y', d => yScale(d.y))
                .text(d => d.label);
        };

        updateChart();

        return () => {
            svg.selectAll('*').remove(); // Clean up old elements before re-rendering
        };
    }, [data]);

    return (
        <div>
            <svg ref={svgRef}></svg>
        </div>
    );
};

export default AssumptionMapping;

import IframeResizer from 'iframe-resizer-react'

export function Testimonialto(){
    return(
        <div className='w-full'>
            <section
                className={`self-stretch flex flex-row items-start relative z-[1] justify-center pt-0 px-5 pt-[30px] lg:pt-[140px] pb-[30px] lg:pb-[140px] box-border max-w-full shrink-0 text-left text-37xl text-gray1-100 font-plus-jakarta-sans mq800:pb-[59px] mq800:box-border mq1150:pb-[91px] mq1150:box-border `}>
                <div className="w-[1160px] flex flex-col items-start justify-start gap-[56px] max-w-full mq800:gap-[28px]">
                    <h1 className="m-0 w-[710px] relative text-inherit text-[32px] lg:text-[56px] leading-[38px] lg:leading-[67.2px] font-[500] font-inherit inline-block max-w-full mq450:text-15xl mq450:leading-[40px] mq800:text-26xl mq800:leading-[54px]">
                        What innovators love about derisky.ai
                    </h1>
                </div>
            </section>
            <IframeResizer src="https://embed-v2.testimonial.to/w/derisky-ai?theme=light&card=base&loadMore=on&initialCount=20&tag=all" className='m-2 w-full rounded-lg border-0' />
        </div>
    )
}

/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import {Animate, Row, Content, Link, Icon} from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props) {

  return (
    <Animate type='slideup'>
      <footer className={Style.footer}>
        <Row>
          <Content>

            <nav className={Style.nav}>
              <Link url='/' text='Home' className={Style.link} color='dark' />
              <Link url='/mba' text='Derisky MBA' className={Style.link} color='dark' />
              <Link url='/pricing' text='Pricing' className={Style.link} color='dark' />
              <Link url='/signin' text='Sign in' className={Style.link} color='dark' />
              <Link url='/signup' text='Sign up' className={Style.link} color='dark' />
              <Link url='/terms' text='Terms' className={Style.link} color='dark' />
              <Link url='/privacy' text='Privacy' className={Style.link} color='dark' />
              <Link url='/contact' text='Contact' className={Style.link} color='dark' />
            </nav>
            <div className='flex gap-2 inline justify-between flex-row-reverse opacity-70 items-center m-0'>
              <div className='text-sm '>{`Copyright © Derisky,ai ${new Date().getFullYear()}`}</div>
              <address className='text-sm flex gap-2 mb-2'><Icon image='home' />Derisky.ai, Badenerstrasse 571, Zürich, Switzerland 🇨🇭</address>
            </div>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
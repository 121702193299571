import { Icon } from 'components/lib';
import Spacer from 'components/spacer/spacer';
import React, { useState } from 'react';

const steps = [
    { id: 1, title: 'Business case', icon: 'briefcase' },
    { id: 2, title: 'Vectorize Documents', icon: 'file-text' },
    { id: 3, title: 'Questions', icon: 'help-circle' },
    { id: 4, title: 'Hypotheses', icon: 'align-justify' },
];

const video = {
    title: "Fearless derisking.",
    description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
    video: "/video/course/deriskingvsbuilding.mp4",
    poster: 'video/course/fearlessderisking.png',
    free: true
}

const FocusComponent = () => {
    const [currentStep, setCurrentStep] = useState(1);

    return (
        <div className="flex flex-col md:flex-row h-screen gap-6">
            {/* Sidebar */}
            <div className="bg-white md:w-1/4 p-4 relative border-2 border-gray-300">
                <h2 className="text-xl font-bold mb-6">Progress</h2>
                <ul className="space-y-8 relative">
                    {steps.map((step, index) => (
                        <li
                            key={step.id}
                            className="flex items-center cursor-pointer relative"
                            onClick={() => setCurrentStep(step.id)}
                        >
                            {index < steps.length - 1 && (
                                <div
                                    className={`absolute left-4 top-[32px] transform w-0.5 h-full ${currentStep >= steps[index + 1].id ? 'bg-brand-400' : 'bg-gray-300'
                                        }`}
                                ></div>
                            )}
                            <span
                                className={`h-8 w-8 flex items-center justify-center rounded-full mr-3 ${currentStep === step.id ? 'bg-brand-400 text-white' : 'bg-gray-300 text-gray-700'
                                    }`}
                            >
                                <Icon image={step.icon} color={'white'} className="h-[1rem] w-[1rem] m-1" alt='' />
                            </span>
                            <span className={currentStep === step.id ? 'text-gray-900 font-semibold' : 'text-gray-700'}>
                                {index + 1} . {step.title}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            {currentStep == 1 ?
                <div className="flex-1 flex flex-col gap-2">
                    <div className="px-6 py-2 items-center border-2 border-[#CFD4DA] flex flex-col md:flex-row gap-4 shadow-[0_4px_9px_-4px_#b7beca]">
                        <div className="mb-4">
                            <h1 className="text-2xl font-bold mb-4 text-brand-400">Unleash Data-driven portfolio decisions.</h1>
                            <p className="mb-8 text-brand-400 opacity-70">
                                Upload product documents & establish actionable innovation metrics. Measure & visualize your impact,
                                derisk with smart experiments, make data-driven portfolio decisions & save millions. Invest like a venture
                                capitalist.
                            </p>
                        </div>

                        <div className="bg-brand-400 text-white rounded ">
                            <div className="flex items-center justify-center h-[10rem]">
                                <video
                                    id={video.title}
                                    className="rounded-md h-full object-cover  w-[484px]"
                                    src={video.video}
                                    poster={video.poster}
                                    controlsList="nodownload"
                                    playsInline
                                    controls
                                />
                            </div>
                        </div>
                    </div>

                    <Spacer height="h-[0.5rem]" />

                    <div className="flex-1 p-8 border-2 bg-white h-[454px]">

                    </div>
                </div> : <div className="flex-1 flex flex-col gap-2">
                    <div className="flex-1 p-8 border-2 bg-white h-[454px]">

                    </div>


                </div>}
        </div>
    );
};

export default FocusComponent;

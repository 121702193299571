import { useState } from 'react';
import { Label, Error, Icon, ClassHelper, Tooltip } from 'components/lib';
import Style from './input.tailwind.js';

export function PasswordInput(props) {
  const [error, setError] = useState(props.errorMessage || 'Please enter a password');
  const [showPassword, setShowPassword] = useState(false);

  function validate(e) {
    let value = e ? e.target.value : '';
    let valid = undefined;

    // Input is required and value is blank
    if (props.required && value === '') valid = false;

    // Input isn't required and value is blank
    if (!props.required && value === '') valid = true;

    if (props.required && value !== '') valid = true;

    if (!props.required) valid = true;

    // Complexity rules
    if (props.complexPassword) {
      if (value.length < 8) {
        valid = false;
        setError('Password must be a minimum of 8 characters');
      } else if (!/[A-Z]/.test(value)) {
        valid = false;
        setError('Password must have at least one upper case letter');
      } else if (!/[a-z]/.test(value)) {
        valid = false;
        setError('Password must have at least one lower case letter');
      }
    }

    // Update the parent form
    props.onChange?.(props.name, value, valid);
  }

  const passwordStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  return (
    <div className={Style.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      <div className={Style.passwordContainer}>
        {props?.tooltipText ? <Tooltip text={props?.tooltipText}> <input
          type={showPassword ? 'text' : 'password'}
          id={props.for}
          name={props.name}
          className={passwordStyle}
          onChange={(e) => props.onChange?.(props.name, e.target.value, undefined)}
          onBlur={(e) => validate(e)}
        /></Tooltip> : <input
          type={showPassword ? 'text' : 'password'}
          id={props.for}
          name={props.name}
          className={passwordStyle}
          onChange={(e) => props.onChange?.(props.name, e.target.value, undefined)}
          onBlur={(e) => validate(e)}
        />}
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className={Style.passwordToggle}
        >
          {showPassword ? (
            <Icon image="eye-off" className={Style.successIcon} color="#666" size={16} />
          ) : (
            <Icon image="eye" className={Style.successIcon} color="#666" size={16} />
          )}
        </button>
      </div>

      {props.valid === false && <Error message={error} />}

      {/* {props.valid === true && (
        <Icon image="check" color="#8CC57D" className={Style.successIcon} size={16} />
      )} */}
    </div>
  );
}

import React from 'react'
import { Badge, DropdownMenu, useLocation, ViewContext } from 'components/lib'
import { useState } from 'react'
import ConfidenceListModal from 'views/metrix/confidencelistmodal'
import axios from 'axios'
import { useContext } from 'react'

const Style = {
	clonebadge: 'bg-[#79f2c0] border border-2-[#57d9a3] rounded-full box-border text-[10px] bottom-[4px] right-[-0.834rem] top-[-0.834rem] h-[2.5rem] w-[2.5rem] flex justify-center items-center',
	container: 'rounded-[7px] bg-[white] box-border p-2 m-h-[2.5rem] mb-4 user-none text-[#091e42] flex',
	avatar: 'w-[2rem] h-[2rem] rounded-[100%] mr-[8px] shrink-0 grow-0 object-cover',
	content: 'grow basis-full flex flex-col',
	blockquote: 'mt-2 mb-4',
	sidelist: 'flex items-center gap-[0.5rem]',
	sublist: 'flex items-center justify-between',
	innerlist: 'flex items-center gap-[1rem]',
	plusdiv: 'border border-[#f5f5f7] rounded-full h-[2rem] w-[2rem] flex justify-center items-center'
}

function getStyle(provided, style) {
	if (!style) {
		return provided.draggableProps.style
	}

	return {
		...provided.draggableProps.style,
		...style
	}
}

function QuoteItem(props) {
	const [confidenceModel, setConfidenceModel] = useState(false)
	const { quote, isDragging, isGroupedOver, provided, style, isClone, index } = props
	const router = useLocation()
	const context = useContext(ViewContext)
	const [loading, setLoading] = useState(false)
	let id = router.pathname.replace('/draganddrop/', '')

	const deleteExperiment = async (id) => {
		try {
			setLoading(true)
			const res = await axios({
				method: 'Delete',
				url: `/api/product_experiment/${id}`
			})
			if (res.data) {
				setLoading(false)
				if (res.data.message) context.notification.show(res.data.message, 'success', true)
				context.modal.hide(true)
				setTimeout(() => {
					window.location.reload()
				}, 200)
				props?.callBack()
			}
		} catch (error) {
			setLoading(false)
			context.handleError(error)
		}
	}

	function deleteExperimentModal(id) {
		context.modal.show({
			title: 'Delete Experiment?',
			body: 'Are you sure to delete the experiment?',
			delete: true,
			buttonText: 'Delete?',
			cancel: 'Cancel',
			submit: () => deleteExperiment(id),
			loading: loading,
			destructive: true
		})
	}

	function experienceModal(hypothesis, value) {
		console.log('---------====', value)
		context.modal.show(
			{
				isFullPage: true,
				parent: 'experiment',
				hypothesis: hypothesis,
				update: value ? value : undefined,
				callBack: () => {
					context.modal.hide(true)
					props?.callBack()
				}
			},
			() => { }
		)
	}

	const finishExperiment = async (isFinished, value) => {
		await axios({
			method: 'put',
			url: `/api/product_experiment/${quote?.author?.id}`,
			data: {
				status: 'Finished',
				risk_reduction: value ? (parseFloat(quote?.author?.hypothesis_id?.risk) * value?.confidence).toFixed(2) : 0,
				confidence: value ? value?.confidence : 0,
				real_end_date: value?.realEndDate ? value?.realEndDate : undefined,
				insight: value?.insight,
				support: value.support,
				refute: value.refuse,
				unclear: value.unClear
			}
		})
			.then(async (res) => {
				if (res.data) {
					context.notification.show(res.data.message, 'success', true)
					if (value?.learning) {
						await axios({
							method: 'post',
							url: `/api/experiment_learning`,
							data: { product_id: id, experiment_id: quote?.author?.id, text: value?.learning }
						})
					}


					setTimeout(() => {
						window.location.reload()
					}, 200)
					props?.callBack()
					context.modal.hide(true)
					setConfidenceModel(false)
				}
			})
			.catch((e) => {
				if (e?.response?.data?.message) {
					setConfidenceModel(false)
				}
			})
	}

	// console.log('isDragging', isDragging, isGroupedOver, provided)
	return (
		<div
			className={Style.container}
			isDragging={confidenceModel ? false : isDragging}
			isGroupedOver={confidenceModel ? false : isGroupedOver}
			isClone={isClone}
			colors={quote?.author?.colors}
			ref={provided.innerRef}
			{...{ ...(confidenceModel == false && provided.draggableProps) }}
			{...{ ...(confidenceModel == false && provided.dragHandleProps) }}
			style={getStyle(provided, style)}
			data-is-dragging={confidenceModel ? false : isDragging}
			data-testid={quote.id}
			data-index={index}
			aria-label={`${quote?.author?.name} quote ${quote?.content}`}>
			{isClone ? <div className={Style.clonebadge}>Clone</div> : null}
			<div quote={quote} className={Style.content}>
				{/* <div>
					<Badge color={quote.author.buttonText === 'low priority' ? 'blue' : quote.author.buttonText === 'high priority' ? 'red' : quote.author.buttonText === 'med priority' ? 'green' : ''} text={quote.author.buttonText} />
				</div> */}
				<h1 className="mb-1">{quote?.content}</h1>
				<h1 className="mb-1">{quote?.experiment_type}</h1>
				{quote?.risk && <h1 className="mb-1">{quote?.risk} %</h1>}
				{/* <div className={Style.sublist}>
					<div className={Style.innerlist}>
						<MdOutlineAttachFile />
						<TfiComment />
					</div>
					<div className={Style.sidelist}>
						<div className={Style.plusdiv}>
							<AiOutlinePlus />
						</div>
						<img src={quote.author.avatarUrl} className={Style.avatar} />
					</div>
				</div> */}
			</div>
			<DropdownMenu
				finishTitle={'Finish Experiment'}
				onFinish={() => setConfidenceModel(true)}
				onEdit={() => experienceModal(quote?.author?.hypothesis_id, quote?.author)}
				onDelete={() => deleteExperimentModal(quote?.author?.id)}
				editTitle={'Edit'}
				deleteTitle={'Delete'}
				parent={'cases'}
				last={index == props?.experiments?.length - 1 ? true : false}
			/>
			{confidenceModel && (
				<ConfidenceListModal
					onClose={() => {
						setConfidenceModel(false)
					}}
					data={quote?.author}
					confidence={(e) => {
						finishExperiment(true, e)
					}}
				/>
			)}
		</div>
	)
}

export default React.memo(QuoteItem)
